<template>
    <v-row>
        <v-col cols="12" md="9" class="mt-10 ml-lg-5">
            <h1 class="text-h5 font-weight-bold mb-10">Finalizar pagamento</h1>

            <v-card class="pa-4 mt-5">
                <h2 class="text-h6 mb-10">Pagamento via PIX</h2>

                <span class="caption d-block">Valor total</span>
                <span class="text-h6 mb-10">R$ 480,00</span>

                <span class="grey--text d-block caption mt-10 font-weight-medium">Escaneie o código para pagar</span>
                <ol class="caption">
                    <li>Acesse seu Internet Banking ou app de pagamentos:</li>
                    <li>Escolha pagar via Pix:</li>
                    <li>Escaneie o seguinte código:</li>
                </ol>

                <img src="@/assets/qrcode.png" alt="qrcode" class="d-block mx-auto mt-5 mb-5" />

                <span class="grey--text d-block caption mt-10 font-weight-medium">Ou copie este código QR para fazer o pagamento</span>
                <span class="caption grey--text">Escolha pagar via Pix pelo seu Internet Banking ou app de pagamentos. Depois, cole o seguinte código: </span>

                <div class="d-flex justify-space-between mt-10">
                    <span class="grey--text">000001222304005454540506857742374234932472347</span>
                    <span class="indigo--text">Copiar</span>
                </div>
                <v-divider class="mb-10"></v-divider>
            </v-card>

            <div class="text-right mt-8 mb-10">
                <span class="indigo--text">  Ir para minha página </span>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'PaymentView',
        props:{
            selected:{
                type:Number,
                default:0
            }
        },
        data()
        {
            return {

            }
        }
    }
</script>

<style scoped>
    .flag{
        position: absolute;
        right: 0;
        bottom: 30px;
    }
</style>