<template>
  <div>
    <main
      class="
        d-block
        mx-auto
        d-flex
        flex-column
        justify-center
        mt-5
        wm-800
        mb-5
        pa-3 pa-md-0
      "
      v-if="!dialog"
    >
      <div class="text-center" v-if="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <template v-else>
        <div>
          <h1 class="text-center">Simulador solar</h1>
          <gmap-autocomplete
            class="introInput"
            placeholder="Informe o endereço"
            @place_changed="setPlace"
            ref="autocomplete"
            :select-first-on-enter="true"
            :style="`${
              !selectedLocation && selectedLocation != null
                ? 'border-color: #ff5252;'
                : 'margin-bottom: 50px; border-color: rgba(0, 0, 0, 0.42);'
            }`"
            v-on:blur="
              if (place == null) {
                selectedLocation = false;
              }
            "
            v-on:focus="selectedLocation = true"
          >
            <template v-slot:input="slotProps">
              <v-text-field
                outlined
                prepend-inner-icon="place"
                placeholder="Informe o endereço"
                ref="input"
                v-on:listeners="slotProps.listeners"
                v-on:attrs="slotProps.attrs"
                types="(address)"
                country="br"
              >
              </v-text-field>
            </template>
          </gmap-autocomplete>
          <small
            v-if="!selectedLocation"
            style="color: #ff5252; font-size: 12px"
            >Para preencher corretamente este campo, clique em uma das opções
            que aparecem ao digitar o endereço. Tente outra vez</small
          >

          <div
            class="d-flex justify-center wrapper-map"
            :style="`${!selectedLocation ? 'margin-top: 27px;' : ''}`"
          >
            <div
              v-if="!enabled && !dialog"
              @click="enabled = true"
              class="
                text-center
                d-flex
                justify-center
                align-center
                flex-column
                wrapper-map-indicator
              "
            >
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16.5" cy="16.5" r="16.5" fill="white" />
              </svg>
              <span
                class="mt-3"
                style="
                  color: #ffffff;
                  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                  font-size: 18px;
                "
              >
                {{
                  $route.query.address
                    ? "Clique e arraste o marcador para indicar o seu telhado"
                    : "Clique para começar, selecione o seu telhado"
                }}</span
              >
            </div>

            <Gmap-Map
              :style="`width: 100%; height: 370px;${
                enabled ? '' : 'pointer-events:none;'
              }`"
              :zoom="zoom"
              :center="center"
              :options="options"
            >
              <Gmap-Marker
                v-if="this.place"
                :position="position"
                :clickable="true"
                :draggable="true"
                @dragend="changeDrag"
              ></Gmap-Marker>
            </Gmap-Map>
          </div>

          <div style="width: 100%; max-width: 800px" class="mr-3 mt-3">
            <v-btn
              :disabled="!place || !enabled"
              large
              block
              elevation="0"
              style="height: 72px"
              class="mt-5 mb-5 orange white--text"
              @click="dialog = true"
              >Próximo</v-btn
            >
          </div>
        </div>
      </template>
    </main>

    <div v-if="dialog">
      <SimulatorModal
        :place="place"
        ref="simualtorModal"
        :initSimulator="simulator"
        @scrollBottom="scrollBottom"
      />
    </div>
  </div>
</template>

<script>
import SimulatorModal from "../../Views/SimulatorModal.vue";
import { mapActions } from "vuex";

export default {
  name: "SimulatorPage",
  components: {
    SimulatorModal,
  },
  data() {
    return {
      dialog: false,
      model: null,
      address: "",
      simulator: null,
      center: { lat: -15, lng: -55 },
      place: null,
      zoom: 4,
      position: { lat: 0, lng: 0 },
      isLoading: false,
      enabled: false,
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        mapTypeId: "satellite",
      },
      selectedLocation: true,
      addressComponent: "",
    };
  },
  async mounted() {
    this.isLoading = true;
    if (this.$route.query.simulator && this.$route.query.simulator > 0) {
      const simulator = await this.showSimulator(this.$route.query.simulator);

      if (simulator) {
        const objPlace = {
          formatted_address: simulator.address,
        };
        this.place = objPlace;
        this.simulator = simulator;
        this.dialog = true;
      }
    } else if (this.$route.query.address) {
      this.isLoading = false;
      let address = this.$route.query.address;

      let r = await this.getAddres(address);

      if (r && r.status == "OK") {
        this.$refs.autocomplete.$el.value = address;
        r = r.results[0];
        const place = {};

        place.latitude = r.geometry.location.lat;
        place.longitude = r.geometry.location.lng;
        place.formatted_address = r.formatted_address;
        place.address_components = r.address_components;

        this.place = place;

        this.center = {
          lat: this.place.latitude,
          lng: this.place.longitude,
        };
        this.position = {
          lat: this.place.latitude,
          lng: this.place.longitude,
        };
        this.zoom = 20;
        //this.dialog = true
      }
      this.enabled = true;
    }

    this.isLoading = false;
    this.setStepSimulation(1);
  },
  methods: {
    ...mapActions(["showSimulator", "setStepSimulation", "getAddres"]),
    setPlace(place) {
      this.enabled = true;
      this.selectedLocation = true;

      place.latitude = place.geometry.location.lat();
      place.longitude = place.geometry.location.lng();

      this.place = place;

      this.center = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.position = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.zoom = 20;
    },
    changeDrag(event) {
      //console.log(event)
      this.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.place.latitude = event.latLng.lat();
      this.place.longitude = event.latLng.lng();
    },

    scrollBottom() {
      const el = document.getElementsByClassName(
        "v-dialog--persistent v-dialog--fullscreen"
      )[0];

      if (el) {
        setTimeout(() => {
          el.scrollTop = el.scrollHeight;
        }, 100);
      }
    },
  },
  watch: {
    "$route.query.simulator": {
      immediate: true,
      handler(newVal) {
        console.log(this.$route.query);
      },
    },
    $route(to, from) {
      console.log(to, from);
    },
  },
};
</script>


<style scoped>
.wm-800 {
  width: 100%;
  max-width: 800px;
}

.introInput {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border-radius: 4px;

  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-top: 50px;

  padding: 16px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #85c2ff;
  box-sizing: border-box;
  border-radius: 5px;
}
.wrapper-map {
  position: relative;
}
.wrapper-map-indicator {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
