<template>
    <v-row>
        <v-col cols="12">
            <v-row class="mt-3 mb-10 contentGrid">
                <v-col cols="12" md="6">
                    <v-card class="pa-5" style="height: 100%">
                        <div class="d-flex">
                            <svg
                                width="40"
                                height="52"
                                viewBox="0 0 46 58"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="d-none d-md-block"
                            >
                                <path
                                    d="M20.1519 55.7344L22.4045 56.8594C22.5897 56.9519 22.7938 57 23.0008 57C23.2078 57 23.412 56.9519 23.5972 56.8594L25.8498 55.7344C31.8188 52.774 36.8392 48.2007 40.3418 42.5329C43.8445 36.8651 45.6894 30.3294 45.6675 23.6667C45.6675 17.6551 43.2794 11.8897 39.0286 7.63891C34.7778 3.38809 29.0124 1 23.0008 1C16.9893 1 11.2239 3.38809 6.97309 7.63891C2.72227 11.8897 0.334178 17.6551 0.334178 23.6667C0.312245 30.3294 2.15717 36.8651 5.65985 42.5329C9.16254 48.2007 14.1829 52.774 20.1519 55.7344ZM23.0008 3.66667C28.3034 3.67245 33.3871 5.78145 37.1366 9.53092C40.8861 13.2804 42.9951 18.3641 43.0008 23.6667C43.0214 29.8339 41.3138 35.8835 38.0716 41.1298C34.8294 46.376 30.1823 50.609 24.6571 53.349L23.0008 54.1771L21.3446 53.349C15.8194 50.609 11.1723 46.376 7.93009 41.1298C4.68789 35.8835 2.98028 29.8339 3.00084 23.6667C3.00663 18.3641 5.11563 13.2804 8.8651 9.53092C12.6146 5.78145 17.6983 3.67245 23.0008 3.66667ZM23.0008 33C24.8468 33 26.6513 32.4526 28.1862 31.4271C29.721 30.4015 30.9173 28.9438 31.6237 27.2384C32.3301 25.5329 32.515 23.6563 32.1548 21.8458C31.7947 20.0353 30.9058 18.3723 29.6005 17.067C28.2952 15.7617 26.6322 14.8728 24.8217 14.5127C23.0112 14.1525 21.1346 14.3374 19.4291 15.0438C17.7237 15.7502 16.266 16.9465 15.2405 18.4813C14.2149 20.0162 13.6675 21.8207 13.6675 23.6667C13.6705 26.1411 14.6548 28.5133 16.4045 30.263C18.1542 32.0127 20.5264 32.997 23.0008 33ZM23.0008 17C24.3194 17 25.6083 17.391 26.7046 18.1235C27.801 18.8561 28.6555 19.8973 29.16 21.1154C29.6646 22.3336 29.7966 23.6741 29.5394 24.9673C29.2822 26.2605 28.6472 27.4484 27.7149 28.3807C26.7825 29.3131 25.5947 29.948 24.3014 30.2052C23.0082 30.4625 21.6678 30.3305 20.4496 29.8259C19.2314 29.3213 18.1903 28.4668 17.4577 27.3705C16.7252 26.2741 16.3342 24.9852 16.3342 23.6667C16.3358 21.8991 17.0387 20.2043 18.2886 18.9544C19.5385 17.7045 21.2332 17.0016 23.0008 17Z"
                                    fill="#BFBEC6"
                                    stroke="#BFBEC6"
                                    stroke-width="0.5"
                                />
                            </svg>
                            <div class="ml-5">
                                <h1 class="text-h6 font-weight-bold">
                                    Dados do cliente
                                </h1>

                                <span class="grey--text d-block caption mt-3">Nome</span>
                                <span>{{ order && order.budget && order.budget.client ? order.budget.client.name : '-' }}</span>
                                
                                
                                <span class="grey--text d-block caption mt-3"
                                    >Cidade</span
                                >
                                <span
                                    >{{ order && order.budget && order.budget.simulator && order && order.budget && order.budget.simulator.city ? order.budget.simulator.city.name : '-' }} -
                                    {{ order && order.budget && order.budget.simulator && order && order.budget && order.budget.simulator.state ? order.budget.simulator.state.uf : '-' }}</span
                                >

                                <span class="grey--text d-block caption mt-3"
                                    >Endereço</span
                                >
                                <span>{{
                                    order && order.budget && order.budget.simulator ? order.budget.simulator.address : '-'
                                }}</span>

                                <span class="grey--text d-block caption mt-3"
                                    >CPF</span
                                >
                                <span>{{ order && order.budget && order.budget && order.budget.client ? order.budget.client.cpf_cnpj : '-' }}</span>

                                <span class="grey--text d-block caption mt-3">Prioridades</span>
                                <span>{{ order && order.budget.client.Priorities ? '1 - ' + order.budget.client.Priorities.split(',')[0] : '-' }}</span>
                                <br>
                                <span>{{ order && order.budget.client.Priorities ? '2 - ' + order.budget.client.Priorities.split(',')[1] : '-' }}</span>
                                <br>
                                <span>{{ order && order.budget.client.Priorities ? '3 - ' + order.budget.client.Priorities.split(',')[2] : '-' }}</span>
                            </div>
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card class="pa-5" style="height: 100%">
                        <div class="d-flex">
                            <svg
                                class="d-none d-md-block"
                                width="64"
                                height="64"
                                viewBox="0 0 64 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M41.4886 57.6971L41.4887 57.6963L43.3986 44.8358H38.5798H38.5789C38.162 44.8355 37.7612 44.7168 37.4128 44.5037C37.066 44.2916 36.7862 43.9957 36.5851 43.6558C36.3842 43.3163 36.2632 42.9359 36.2235 42.5479C36.1838 42.1598 36.2254 41.7643 36.3479 41.3925L36.3482 41.3915L41.793 24.9189L41.794 24.9159C41.9815 24.3538 42.3193 23.844 42.7877 23.4704L43.4893 24.3498L42.7877 23.4704C43.259 23.0944 43.8391 22.8767 44.4525 22.875L44.4556 22.875L46.295 22.875H46.3036L46.3036 22.875C46.8223 22.879 47.3199 23.0376 47.7476 23.3191L47.129 24.2588L47.7476 23.3192C48.1735 23.5995 48.5119 23.989 48.7454 24.4353C48.9789 24.8813 49.1053 25.3787 49.1215 25.8812C49.1377 26.3837 49.0435 26.8882 48.8412 27.3489L48.8385 27.3551L48.8385 27.3551L43.9532 38.3062H49.172H49.1729V39.4312C49.4264 39.4306 49.6758 39.5051 49.8976 39.6477C50.1195 39.7902 50.3063 39.9961 50.4407 40.2458C50.575 40.4955 50.6523 40.7809 50.6653 41.075C50.6784 41.3691 50.6267 41.6622 50.5151 41.9267L41.4886 57.6971ZM41.4886 57.6971C41.4308 58.088 41.4942 58.498 41.6829 58.8582L42.6794 58.3361L41.6829 58.8582C41.8723 59.2197 42.1916 59.5331 42.6173 59.69C43.0503 59.8496 43.522 59.8191 43.9283 59.6142C44.326 59.4136 44.6074 59.0756 44.7606 58.7038C44.761 58.7028 44.7614 58.7018 44.7618 58.7008L51.5517 42.3639C51.5521 42.3629 51.5525 42.3619 51.553 42.3609L41.4886 57.6971Z"
                                    stroke="#BFBEC6"
                                    stroke-width="2.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M18.5431 44.2341L18.5432 44.2333L20.8647 29.2022H14.829H14.8281C14.3798 29.2019 13.9451 29.0794 13.5627 28.8545C13.1813 28.6302 12.8674 28.3135 12.639 27.9423C12.4109 27.5715 12.2715 27.1527 12.2258 26.7223C12.18 26.2919 12.2281 25.8539 12.3687 25.4437L12.369 25.4428L18.9027 6.43589L18.9037 6.43281C19.1209 5.80701 19.5088 5.24906 20.0356 4.84497C20.5644 4.43935 21.2062 4.21079 21.8769 4.20899L21.8799 4.20898L24.0872 4.20898H24.0955V4.20901C24.6631 4.21317 25.2123 4.37986 25.6897 4.68205L25.088 5.63263L25.6897 4.68205C26.1659 4.98348 26.5511 5.40677 26.8198 5.90031C27.0882 6.3935 27.2353 6.94696 27.2541 7.50875C27.2729 8.07054 27.1632 8.63271 26.93 9.14356L26.9271 9.14984L26.9271 9.14983L20.9586 22.0142L27.5384 22.0142C27.5387 22.0142 27.539 22.0142 27.5394 22.0142H27.5407V23.1392C27.8449 23.1386 28.1442 23.2245 28.4104 23.389C28.6765 23.5534 28.9008 23.791 29.062 24.0791C29.2232 24.3673 29.316 24.6966 29.3316 25.036L18.5431 44.2341ZM18.5431 44.2341C18.4776 44.6602 18.5502 45.1047 18.7606 45.4908C18.9718 45.8785 19.3195 46.1992 19.7651 46.3571C20.2151 46.5166 20.7042 46.4871 21.1301 46.2805L20.6393 45.2683M18.5431 44.2341L20.6393 45.2683M20.6393 45.2683L21.1302 46.2805C21.5502 46.0768 21.8598 45.7267 22.0317 45.3254C22.0322 45.3244 22.0326 45.3233 22.0331 45.3222L30.1816 26.4706C30.182 26.4696 30.1825 26.4685 30.1829 26.4675L20.6393 45.2683Z"
                                    stroke="#BFBEC6"
                                    stroke-width="2.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <div class="ml-5">
                                <h1 class="text-h6 font-weight-bold">
                                    Dados da obra
                                </h1>

                                <div class="d-flex">
                                    <div>
                                        <span
                                            class="grey--text d-block caption mt-3"
                                            >Capacidade de geração</span
                                        >
                                        <span
                                            >{{
                                                order && order.budget && order.budget && order.budget.simulator ? order.budget.simulator.monthly_generated_energy : '-'
                                            }}
                                            kWh</span
                                        >

                                        <span
                                            class="grey--text d-block caption mt-3"
                                            >Tipo de telhado</span
                                        >                                        
                                        <span v-if="order.budget.simulator.roof_type == '1'">Cerâmico ou colonial</span>
                                        <span v-else-if="order.budget.simulator.roof_type == '2'">Fibrocimento</span>
                                        <span v-else-if="order.budget.simulator.roof_type == '3'">Metálico ondulado</span>
                                        <span v-else-if="order.budget.simulator.roof_type == '4'">Laje</span>
                                        <span v-else-if="order.budget.simulator.roof_type == '5'">Solo (terreno, quintal)</span>
                                        <span v-else-if="order.budget.simulator.roof_type == '6'">Outro lugar</span>
                                        <span v-else-if="order.budget.simulator.roof_type == '7'">Metálico trapezoidal</span>
                                        <span v-else> - </span>

                                        <span
                                            class="grey--text d-block caption mt-3"
                                            >Tamanho da obra</span
                                        >
                                        <span
                                            >{{
                                                order && order.budget && order.budget ? order.budget.area_size : '-'
                                            }}
                                            m2</span
                                        >

                                        <span
                                            class="grey--text d-block caption mt-3 mb-3"
                                            >Potência instalada</span
                                        >
                                        <span
                                            >{{
                                                parseFloat(
                                                    order && order.budget && order.budget && order.budget.simulator ? order.budget.simulator.potency : ''
                                                ).toFixed(2)
                                            }}
                                            kWp</span
                                        >
                                    </div>

                                    <div class="ml-5">
                                        <span
                                            class="grey--text d-block caption mt-3"
                                            >Tipo de fornecimento</span
                                        >
                                        <span>{{
                                            _getPowerInput(
                                                order && order.budget && order.budget.simulator ? order.budget.simulator.power_input : '-'
                                            )
                                        }}</span>

                                        <span
                                            class="grey--text d-block caption mt-3"
                                            >Tipo de instalação</span
                                        >
                                        <span>{{
                                            _getResidenceType(
                                                order && order.budget && order.budget.simulator ?  order.budget.simulator
                                                    .residenceType : '-'
                                            )
                                        }}</span>
                                        <span
                                            class="grey--text d-block caption mt-3"
                                            >Tecnologia do inversor</span
                                        >
                                        <div v-for="item in order.inversor" :key="item.id">
                                            <span v-if="order.inversor.length > 1">- {{item.name}}</span>
                                            <span v-else>{{item.name}}</span>
                                        </div>
                                        <span v-if="order.inversor.length == 0">-</span>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="12">
                    <v-card class="pa-5">
                        <v-row class="pt-12">
                            <v-col offset-md="1" md="10">
                                <h1 class="text-h6 font-weight-bold mb-10">
                                    Detalhe do pedido
                                </h1>
                                <span v-if="order && order.budget && order.budget.documents" class="d-block font-weight-mediumn"
                                    >Anexos</span
                                >

                                <v-row no-gutters v-if="order && order.budget && order.budget.documents">
                                    <v-col
                                        cols="12"
                                        md="4"
                                        v-for="(n, index) in order.budget.documents"
                                        :key="index"
                                    >
                                        <CardComponent
                                            class="mb-10 mr-5"
                                        >
                                            <template v-slot:image>
                                                <div v-if="isLoadingDownloadFileBudget" style="background-color: #bdbdbd; height: 245px; width: 100% !important; display: flex; justify-content: center; align-items: center; cursor: pointer">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular>
                                                </div>

                                                <template v-else>
                                                    <div style="cursor: pointer; background-color: #bdbdbd; height: 245px; width: 100% !important;" v-if="allowedExtension.includes(n.file.split('.').pop())" @click="onDownloadFileBudget(n)">
                                                        <v-img height="245px" width="100%" :src="n.url"></v-img>
                                                    </div>
        
                                                    <div v-else @click="onDownloadFileBudget(n)" style="cursor: pointer; background-color: #bdbdbd; height: 245px; width: 100% !important; display: flex; justify-content: center; align-items: center">
                                                        <v-chip color="#FFF" disabled>{{ n.file.split('.').pop().toUpperCase() }}</v-chip>
                                                    </div>
                                                </template>
                                            </template>
                                            
                                            <template v-slot:content>
                                                <span
                                                    class="black--text text--darken-2 subtitle-1 d-block font-weight-bold"
                                                >
                                                    {{ n.title ? n.title : 'Sem título' }}
                                                </span>
                                            </template>
                                        </CardComponent>
                                    </v-col>
                                </v-row>

                                <span v-if="order && order.budget && order.budget.notes" class="d-block font-weight-mediumn mt-5">Comentário</span>
                                <v-card
                                    v-if="order && order.budget && order.budget.notes"
                                    class="pa-4 elevation-0 grey lighten-4 mt-3 mb-3"
                                >
                                    <span class="caption" v-html="order.budget.notes">
                                    </span>
                                </v-card>

                                <div
                                    v-if="bottomActions"
                                    class="d-flex justify-space-between mt-10 mb-10"
                                >
                                    <a
                                        class="blue--text text--darken-4"
                                        @click="
                                            $router.push(
                                                '/result-simulator/' +
                                                    order.budget.simulator.id
                                            )
                                        "
                                        >Ver simulação</a
                                    >
                                    <v-btn
                                        class="orange white--text elevation-0"
                                        @click="$emit('send')"
                                        v-if="checkSend() && !checkRecusado()"
                                    >
                                        Enviar orçamento
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </div>

                                <v-btn
                                    v-else
                                    @click="
                                        $router.push(
                                            '/result-simulator/' +
                                                order.budget.simulator.id
                                        )
                                    "
                                    class="blue darken-4 white--text elevation-0 mt-10"
                                >
                                    Ver simulação
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <a 
                        v-if="order && order.installer_budgets && order.installer_budgets.rejected_at == null && checkSend()" 
                        @click="dialog = true;" 
                        class="text--darken-4 d-block mt-5 text-center text-decoration-underline"
                    >Rejeitar orçamento</a>

                    <v-col
                        v-if="
                            showSelect &&
                            getUser.user_type == 3 &&
                            !order.installer_id
                        "
                        cols="12"
                        class="mt-5 text-center"
                    >
                        <v-btn
                            @click="$emit('onSelectInstaller')"
                            :loading="isLoadingSelect"
                            :disabled="isLoadingSelect"
                            class="btn_choose"
                            >Escolher esta empresa</v-btn
                        >
                    </v-col>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="auto">
                    <v-dialog
                        v-model="dialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                    >
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-card-text style="padding-top: 120px;">
                                    <div class="pa-12" style="display: flex; align-items: center; justify-items: center; flex-direction: column;">
                                        <img src="/images/icon-exclamation.svg">
                                        <p id="header-dialog">Tem certeza que deseja recusar esta oportunidade?</p>
                                        <p id="text-dialog">Esta ação não poderá ser desfeita.</p>

                                        <v-card-actions class="dialog-actions">
                                            <v-btn
                                                elevation="2"
                                                @click="dialog.value=false; dialog2=true;"
                                                id="btn_reject"
                                            >Sim, recusar</v-btn>
                                            <v-btn 
                                                @click="dialog.value=false"
                                                id="btn_close"
                                            >
                                                Cancelar
                                            </v-btn>
                                        </v-card-actions>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="auto">
                    <v-dialog
                        v-model="dialog2"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                    >
                        <template v-slot:default="dialog2">
                            <v-card>
                                <v-card-text style="padding-top: 120px;">
                                    <div class="pa-12" style="display: flex; align-items: center; justify-items: center; flex-direction: column;">
                                        <p id="header-dialog">Informe o motivo</p>
                                        <p id="text-dialog" style="width: 340px;">Escreva em poucas palavras a razão por você ter recusado a proposta...</p>

                                        <v-form style="width: 640px;margin-top: 48px;">
                                            <v-container>
                                                <v-text-field
                                                    counter="140"
                                                    label="Qual o motivo?"
                                                    hint="Opcional"
                                                    persistent-hint
                                                    v-model="msgReason"
                                                ></v-text-field>
                                            </v-container>
                                        </v-form>

                                        <v-card-actions class="dialog-actions">
                                            <v-btn
                                                elevation="2"
                                                id="btn_reject"
                                                @click="onReject"
                                                :loading="isLoadingReject"
                                                :disabled="isLoadingReject"
                                            >
                                                Enviar
                                            </v-btn>
                                            <v-btn
                                                @click="onReject"
                                                id="btn_close"
                                                :loading="isLoadingReject"
                                                :disabled="isLoadingReject"
                                            >
                                                Não informar
                                            </v-btn>
                                        </v-card-actions>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>

        
    </v-row>
</template>

<script>
import CardComponent from "@/components/CardComponent/Index";
import { getPowerInput, getResidenceType } from "./../utils/Helpers";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        CardComponent,
    },
    props: {
        bottomActions: {
            type: Boolean,
            default: false,
        },
        showSelect: {
            type: Boolean,
            default: false,
        },
        isLoadingSelect: {
            type: Boolean,
            default: false,
        },
        order: {
            default: null,
        },
    },
    data() {
        return {
            dialog: false,
            dialog2: false,
            msgReason: "",
            images: Array(3).fill(1),
            allowedExtension: ['jpeg', 'jpg', 'png','gif','bmp'],
            isLoadingDownloadFileBudget: false,
            isLoadingReject: false
        };
    },

    methods: {
        ...mapActions(['getDocumentBudget', 'sendRejectBudget']),


        checkRecusado () {
            const user = this.getUser

            

            if (user.installer) {
                if (this.order && this.order.installer_budgets && this.order.installer_budgets.rejected_at) {
                    return true
                }
            }
            
            return false



            
        },
        
        checkSend () {
            
            
            
            if (this.order) {
                // this.getPrazo(this.order) <= 0 ||
                if (this.order.budget_installer || this.checkEnviado(this.order)) {
                    return false
                }
            } else {
                return false
            }

            return true

        },

        checkEnviado (item) {
            const user = this.getUser

            if (item.installer_id) {
                return true
            } else {

                if (user.installer) {
                    for (let budget of item.budget_installers) {
                        if (budget.installer_id == user.installer.id) {
                            return true
                        }
                    }
                }



            }
        },
        
        _getPowerInput(tipo) {
            return getPowerInput(tipo);
        },

        _getResidenceType(tipo) {
            return getResidenceType(tipo);
        },

        async onDownloadFileBudget (item) {
            this.isLoadingDownloadFileBudget = true
            const r = await this.getDocumentBudget(item.id);
            if (r) {
                this.downloadBase64File('', r.file, r.name)
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao baixar arquivo'})
            }
            this.isLoadingDownloadFileBudget = false
        },

        downloadBase64File(contentType, base64Data, fileName) {
            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },

        async onReject(){
            this.isLoadingReject = true
            let post = {
                id: this.order.id,
                reason: this.msgReason
            };
            
            const r = this.sendRejectBudget(post);
            
            if (r) {
                this.$eventHub.$emit('snackBar', {color: 'success', message: 'Orçamento rejeitado com sucesso'})
                this.$router.push('/budgets')
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao rejeitar orçamento'})
            }
            this.msgReason = "";

            this.isLoadingReject = false
        }
    },

    computed: {
        ...mapGetters(["getUser"]),
    },
};
</script>

<style scoped>
    .contentGrid {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
    }

    #header-dialog{
        width: 340px;

        margin: 32px 0 0 0; 

        font-family: 'cerapro-medium';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0025em;
        color: #312F37;
    }

    #text-dialog{
        margin: 8px 0 0 0;

        font-family: 'cerapro-medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #6E6B7B;
    }

    .dialog-actions{
        display: flex;
        flex-direction: column;
    }

    #btn_reject{
        width: 336px;
        height: 64px;

        margin: 72px 0 0 0;
        padding: 13px 24px 14px;
        
        background: #FFA900;
        border-radius: 4px;

        font-family: 'cerapro-medium';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        text-transform: unset !important;
    }

    #btn_close{
        width: 336px;
        height: 64px;

        margin: 24px 0 0 0;
        padding: 13px 24px 14px;

        border: 1px solid #BFBEC6;
        box-sizing: border-box;
        border-radius: 4px;

        font-family: 'cerapro-medium';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #5E5873;
        text-transform: unset !important;

        background: none;
        box-shadow: none;
    }

    .btn_choose{
        width: 520px !important; 
        height: 50px !important;
        color: #fff !important; 
        background-color: #FFA900 !important;
    }
</style>
