import axios from 'axios'
import Cookies from "js-cookie";

const Http = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

Http.interceptors.request.use( config => {
    config.headers.common['Authorization'] = (() => localStorage.getItem('token'))()
    return config
}, Promise.reject)



Http.interceptors.response.use( async function(response) {
    return response
}, async err => {

    if (err && err.response && err.response.status === 401) {
        if (err.response.data.type && err.response.data.type == 'error_token') {
            localStorage.clear()
            Cookies.remove('iniciais_marketsolar', { path: '', domain: '.marketsolar.com.br' })
            Cookies.remove('token_marketsolar', { path: '', domain: '.marketsolar.com.br' })
            window.location.href = "/"
        }

    } else {
        return Promise.reject(err)
    }
})

export default Http