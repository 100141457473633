<template>
    <v-container class="px-10" fluid>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div v-else>
            <h2 class="mb-5">Orçamentos recebidos</h2>

            <v-alert
                border="left"
                color="pink darken-1"
                dark
                v-if="items.length == 0"
            >
                Nenhum orçamento inserido ainda
            </v-alert>
            
            <template v-else>
                
                <v-card class="mt-8" style="border-left:3px solid #0066CC">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <th style="border: 0px !important; color: #8A8797;">Empresa de energia solar</th>
                                <th style="border: 0px !important; color: #8A8797;">Cap. de geração</th>
                                <th style="border: 0px !important; color: #8A8797;">Potência</th>
                                <th style="border: 0px !important; color: #8A8797;">Valor por Wp</th>
                                <th style="border: 0px !important; color: #8A8797;">Investimento</th>
                                <th style="border: 0px !important; color: #8A8797;"></th>
                            </tr>
                                <tr v-for="(item, indexItem) in items" :key="indexItem" @click="showBudget(item)" class="mouse-pointer">
                                    <td style="border: 0px !important; color: #312F37" class="pt-2 pb-2">
                                        <p style="display: flex !important;" class="mb-0">
                                            <span style="width: 44px !important;">
                                                <v-img
                                                    v-if="item.installer"
                                                    height="44px"
                                                    width="44px"
                                                    :src="item.installer.url_image"
                                                />
                                            </span>
                                            
                                            <span class="ml-2 mt-3">{{ item.installer ? item.installer.trading_name : '-' }}</span>
                                        </p>
                                    </td>
    
                                    <td style="border: 0px !important;">
                                        <p style="color: #312F37">
                                            {{ item.kwh }} kWh
                                        </p>
                                    </td>

                                    <td style="border: 0px !important;">
                                        <p style="color: #312F37">
                                            {{ _getAndFormatPotency(item.products) }} kWp    
                                        </p>
                                    </td>    
                                    
                                    <td style="border: 0px !important;">
                                        <p style="color: #312F37">R$ {{ _formatMoney(_getValuePerWp(item)) }}</p>
                                    </td>

                                    <td style="border: 0px !important;">
                                        <p style="color: #312F37">R$ {{ _formatMoney(item.total) }}</p>
                                    </td>                                    
                                    
                                    <td style="border: 0px !important;"> 
                                        <img src="/images/icon-right.svg">
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>


                <v-card class="mt-8 pa-3" v-for="(item, indexItem) in items" :key="indexItem" style="border-left:3px solid #0066CC">
                    <div class="d-flex flex-no-wrap justify-space-between hideMobile">
                        
                        <div>
                           
                            <v-img width="192px" v-if="item.installer" :src="item.installer.url_image"></v-img>
                       

                            <div style="padding: 10px; box-sizing: border-box">
                                <v-btn
                                    block
                                    class="mb-3 no-uppercase"
                                    color="#002A53"
                                    @click="onShowFileBudgetInstaller(item)"
                                    :loading="isLoadingFile"
                                    :disabled="isLoadingFile"
                                    style="color: #FFF !important; display: flex; justify-content: space-between"
                                >
                                    Ver proposta



                                    <div
                                        :style="`
                                            width: 20px;
                                            height: 20px;
                                            background-color: #FFF;
                                            -webkit-mask: url(/images/icon-clip.svg) no-repeat center;
                                            mask: url(/images/icon-clip.svg) no-repeat center;
                                        `"
                                    ></div>


                                </v-btn>
                                <v-btn
                                    block
                                    outlined
                                    color="#002A53"
                                    class="mb-3 no-uppercase"
                                    @click="showBudget(item)"
                                    style="display: flex; justify-content: space-between"
                                >
                                    Ver detalhe

                                    <img src="/images/icon-arrow-right.svg">
                                </v-btn>

                                <v-btn 
                                    v-if="!order.installer_id"
                                    color="#FFA900" 
                                    class="no-uppercase"
                                    @click="onSelectInstaller(item)"
                                    style="color: #FFF !important; display: flex; justify-content: space-between"
                                >Escolher esta empresa</v-btn>
                            </div>

                        </div>
                       
                        <div style="flex: 1">
                            <v-card-title class="titleResume">{{ item.installer ? item.installer.trading_name : '-' }}</v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="6" md="3">
                                        <p class="subtitleResume">Potência</p>
                                        <p class="titleResume">{{ _getAndFormatPotency(item.products) }} kWp</p>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <p class="subtitleResume">Capacidade de geração</p>
                                        <p class="titleResume">{{ item.kwh }}kWh</p>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <p class="subtitleResume">Valor por Wp</p>
                                        <p class="titleResume">R$ {{ _formatMoney(_getValuePerWp(item)) }}</p>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <p class="subtitleResume">Investimento</p>
                                        <p class="titleResume">R${{ _formatMoney(item.total) }}</p>
                                    </v-col>
                                </v-row>
                                
                                <v-divider></v-divider>

                                <v-simple-table class="noBorder">
                                    <template v-slot:default>
                                        <thead class="noBorder">
                                            <tr class="noBorder">
                                                <th class="subtitleResume noBorder">Produtos</th>
                                                <th class="subtitleResume noBorder">Quantidade</th>
                                                <th class="subtitleResume noBorder">Garantia</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="noBorder" v-for="(p, ip) in item.products">
                                                <td class="noBorder textNameProduct"> 
                                                    <template v-if="p.product_id == 3">
                                                        {{ p.type ? p.type.name : '' }} 
                                                        <span 
                                                            v-if="p.brand || p.custom_brand"
                                                            style="text-decoration: underline"
                                                        >
                                                            {{ p.brand ? p.brand.trading_name : p.custom_brand ? p.custom_brand : '' }}
                                                        </span> 
                                                        {{ p.potency ? ' - ' + p.potency : '' }} Wp
                                                    </template>

                                                    <template v-else-if="p.product_id == 4">
                                                        {{ p.type ? p.type.name : '' }} 
                                                        <span 
                                                            v-if="p.brand || p.custom_brand"
                                                            style="text-decoration: underline"
                                                        >
                                                            {{ p.brand ? p.brand.trading_name : p.custom_brand ? p.custom_brand : '' }}
                                                        </span> 
                                                        {{ p.potency ? ' - ' + p.potency : '' }} kW
                                                    </template>
                                                    
                                                    <template v-else>
                                                        {{ p.type ? p.type.name : '' }}
                                                        <span
                                                            v-if="p.brand || p.custom_brand"
                                                            style="text-decoration: underline"
                                                        >
                                                            {{ p.brand ? p.brand.trading_name : p.custom_brand ? p.custom_brand : '' }}
                                                        </span>
                                                    </template>
                                                
                                                </td>
                                                <td class="noBorder normalText">{{ p.quantity }}</td>
                                                <td class="noBorder normalText">{{ p.assurance }} ano(s)</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <v-divider></v-divider>

                                <v-col cols="24" md="12">
                                    <p class="subtitleResume">Observações adicionais</p>
                                    <template v-if="item.DetailedInfo">
                                        <span v-html="item.DetailedInfo" class="detailedInfo"></span>
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                                </v-col>
                            </v-card-text>
                            
                        </div>
                    </div>



                    <div class="showMobile">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div v-if="item.installer">
                                <v-img width="73px" v-if="item.installer" :src="item.installer.url_image"></v-img>
                            </div>
                            <div style="flex: 1" class="pt-2 ml-2">
                                <p class="mobileMiniTitle">Empresa de energia solar</p>
                                
                                <p class="mobileInstallerNameDescription">{{ item.installer ? item.installer.trading_name : '' }}</p>
    
                            </div>
                        </div>
                        
                        <v-divider class="mb-4 ml-3 mr-3"></v-divider>
                        
                        <div class="ml-3 mr-3">

                            <v-row>

                                <v-col cols="12" md="6">
                                    <p class="mobileMiniTitle">Potência</p>
                                    <p class="mobileItemValue pt-2">{{ _getAndFormatPotency(item.products) }} kWp</p>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <p class="mobileMiniTitle">Capacidade de geração</p>
                                    <p class="mobileItemValue pt-2">{{ item.kwh }}kWh</p>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <p class="mobileMiniTitle">Valor por Wp</p>
                                    <p class="mobileItemValue pt-2">R$ {{ _formatMoney(_getValuePerWp(item)) }}</p>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <p class="mobileMiniTitle">Investimento</p>
                                    <p class="mobileItemValue pt-2">R${{ _formatMoney(item.total) }}</p>
                                </v-col>

                                
                            </v-row>

                            <v-divider class="mb-4 ml-3 mr-3"></v-divider>

                            <p class="mobileMiniTitle">Produtos</p>


                            <v-list
                                subheader
                                two-line
                            >

                                <v-list-item
                                    v-for="(p, ip) in item.products"
                                    :key="ip"
                                >
                                    <v-list-item-action>
                                        <v-chip>{{ p.quantity }}</v-chip>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-subtitle
                                            style="display: inline !important; overflow: auto !important; text-overflow: initial !important; white-space: initial; !important;"
                                        >

                                            <p class="textNameProduct mt-3">
                                                <template v-if="p.product_id == 3 || p.product_id == 4">
                                                    {{ p.type ? p.type.name : '' }}
                                                    <span
                                                        v-if="p.brand || p.custom_brand"
                                                    >
                                                                {{ p.brand ? p.brand.trading_name : p.custom_brand ? p.custom_brand : '' }}
                                                            </span>
                                                    {{ p.potency ? ' - ' + p.potency : '' }} Wp
                                                </template>
    
                                                <template v-else>
                                                    {{ p.type ? p.type.name : '' }}
                                                    <span
                                                        v-if="p.brand || p.custom_brand"
                                                        style="text-decoration: underline"
                                                    >
                                                                {{ p.brand ? p.brand.trading_name : p.custom_brand ? p.custom_brand : '' }}
                                                            </span>
                                                </template>
                                                
                                                . <span style="text-decoration: underline">{{ p.assurance }} ano(s) de garantia</span>
                                            </p>
                                        </v-list-item-subtitle>
                                                        
                                    </v-list-item-content>

                                  
                                </v-list-item>
                                
                                
                            </v-list>

                            <v-divider class="mb-4 ml-3 mr-3"></v-divider>

                            <v-col cols="12" md="6">
                                    <p class="mobileMiniTitle">Observações adicionais</p>
                                    <template v-if="item.DetailedInfo">
                                        <span v-html="item.DetailedInfo" class="mobileDetailedInfo pt-2"></span>
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                            </v-col>

                            <div style="padding: 10px; box-sizing: border-box">
                                <v-btn
                                    block
                                    class="mb-3 no-uppercase"
                                    color="#002A53"
                                    @click="onShowFileBudgetInstaller(item)"
                                    :loading="isLoadingFile"
                                    :disabled="isLoadingFile"
                                    style="color: #FFF !important; display: flex; justify-content: space-between"
                                >
                                    Ver proposta



                                    <div
                                        :style="`
                                            width: 20px;
                                            height: 20px;
                                            background-color: #FFF;
                                            -webkit-mask: url(/images/icon-clip.svg) no-repeat center;
                                            mask: url(/images/icon-clip.svg) no-repeat center;
                                        `"
                                    ></div>
                                    
                                    
                                </v-btn>
                                <v-btn 
                                    block 
                                    outlined 
                                    color="#002A53" 
                                    class="mb-3 no-uppercase" 
                                    @click="showBudget(item)"
                                    style="display: flex; justify-content: space-between"
                                >
                                    Ver detalhe
                                    
                                    <img src="/images/icon-arrow-right.svg">
                                </v-btn>

                                <v-btn 
                                    v-if="!order.installer_id"
                                    block
                                    color="#FFA900" 
                                    class="no-uppercase"
                                    @click="onSelectInstaller(item)"
                                    style="color: #FFF !important; display: flex; justify-content: space-between"
                                >Escolher esta empresa</v-btn>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </v-card>
            </template>
        </div>
        
        
        <h3 class="mt-12">Detalhes</h3>
        <OrderDetails :order="order"></OrderDetails>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    import dayjs from 'dayjs'

    import { getResidenceType, formatMoney } from './../../utils/Helpers'
    import OrderDetails from "../../Views/OrderDetails";


    export default {
        metaInfo: {
            title: 'Detalhes do orçamento - Market Solar',
        },
        components: {OrderDetails},
        data()
        {
            return {
                dialog:false,
                isLoading: false,
                items: [],
                totalPage: 1,
                order: null,
                isLoadingFile: false
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            ...mapActions(['listOrderBudgetInstalers', 'showFileBudgetInstaller', 'selectInstallerBudget']),

            async load (page = 1) {
                this.isLoading = true
                const filter = {}
                filter.page = page
                const resp = await this.listOrderBudgetInstalers(this.$route.params.id)
                console.log('*****', resp)
                if (resp) {
                    this.items = resp.items
                    this.totalPage = resp.items.last_page
                    this.order = resp.order
                }

                this.isLoading = false;
            },

            _dayJs (data) {
                return dayjs(data)
            },

            _getResidenceType (id) {
                return getResidenceType(id)
            },

            _formatMoney (value) {
                return formatMoney(value)
            },

            _formatPayback (value) {
                let years = Math.floor(value / 12);
                let months = Math.round(((value / 12) - years) * 12);
                return `${years} anos e ${months} meses`
            },

            _getAndFormatPotency (products) {
                let potencyInKwp = 0;

                for (let index = 0; index < products.length; index += 1) {
                    if (products[index].product.name == 'Módulo') {
                        potencyInKwp += products[index].potency * products[index].quantity / 1000;
                    }
                }

                return String(potencyInKwp.toFixed(2)).replace('.', ',')
            },

            _getValuePerWp(item) {
                const potencyInWp = this._getAndFormatPotency(item.products).replace(',', '.') * 1000;
                return item.total / potencyInWp;
            },
            
            showBudget (item) {
                this.$router.push('/budget/sent/' + item.id)
            },

            downloadBase64File(contentType, base64Data, fileName) {
                const linkSource = `data:${contentType};base64,${base64Data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            },
            
            async onShowFileBudgetInstaller (item) {
                this.isLoadingFile = true
                const r = await this.showFileBudgetInstaller(item.id);
                if (r) {
                    this.downloadBase64File('', r.file, r.name)
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao baixar arquivo'})
                }
        
                
                this.isLoadingFile = false
            },

            async onSelectInstaller (item) {
                this.isLoadingSelect = true

                const r = await this.selectInstallerBudget(item.id)
                
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Empresa selecionada com sucesso'})
                    this.$router.push('/orders')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao selecionar empresa'})
                }

                this.isLoadingSelect = false
            },
        },

        computed: {
            ...mapGetters(['getUser'])
        }
    }
</script>


<style scoped>
    
    .textNameProduct {
        font-family: "cerapro-medium";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #312F37;
    }
    
    .subtitleResume {
        font-family: "cerapro-medium";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #8A8797;
    }

    .detailedInfo {
        font-family: "cerapro-medium";
        font-size: 18px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
    }
    
    .titleResume {
        font-family: "cerapro-medium";
        font-size: 28px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0px;
        text-align: left;
    }
    
    .normalText {
        font-family: "cerapro-medium";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;

    }
    
    .noBorder{
        border: none !important; 
    }
    
    .showMobile {
        display: none !important;
    }
    
    
    .mobileMiniTitle {
        font-family: "cerapro-medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #8A8797;
        margin-bottom: 0px;

    }

    
    .mobileInstallerNameDescription {
        font-family: "cerapro-medium";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #312F37;
        padding-top: 0px !important;
        margin-top: 0px !important;

    }
    
    .mobileItemValue {
        font-family: "cerapro-medium";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: #312F37;
    }

    .mobileDetailedInfo {
        font-family: "cerapro-medium";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: #312F37;
    }
    
    .showMobile .v-list-item {
        padding: 0px !important;
    }
    
    .showMobile .v-list-item__action {
        padding: 0px !important;
        margin-right: 5px !important;
    }

    @media screen and (max-width: 960px) {
        .hideMobile {
            display: none !important;
        }

        .showMobile {
            display: flex !important;
            flex-direction: column;
        }
    }
</style>