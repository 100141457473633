<template>
    <div>

        <v-row>

            <v-col cols="12" md="10" offset-md="1">
            <div v-show="!editing">
                <svg
                    class="edit-icon"
                    @click="editing = true"
                    width="21"
                    height="17"
                    viewBox="0 0 26 25"
                    fill="none"
                >
                    <path
                        d="M1.5 18.75L0.96967 18.2197C0.829018 18.3603 0.75 18.5511 0.75 18.75H1.5ZM19.375 0.875L19.9053 0.34467C19.6124 0.0517767 19.1376 0.0517767 18.8447 0.34467L19.375 0.875ZM24.875 6.375L25.4053 6.90533C25.6982 6.61244 25.6982 6.13756 25.4053 5.84467L24.875 6.375ZM7 24.25V25C7.19891 25 7.38968 24.921 7.53033 24.7803L7 24.25ZM1.5 24.25H0.75C0.75 24.6642 1.08579 25 1.5 25L1.5 24.25ZM12.5 23.5C12.0858 23.5 11.75 23.8358 11.75 24.25C11.75 24.6642 12.0858 25 12.5 25V23.5ZM24.1875 25C24.6017 25 24.9375 24.6642 24.9375 24.25C24.9375 23.8358 24.6017 23.5 24.1875 23.5V25ZM2.03033 19.2803L19.9053 1.40533L18.8447 0.34467L0.96967 18.2197L2.03033 19.2803ZM18.8447 1.40533L24.3447 6.90533L25.4053 5.84467L19.9053 0.34467L18.8447 1.40533ZM24.3447 5.84467L6.46967 23.7197L7.53033 24.7803L25.4053 6.90533L24.3447 5.84467ZM7 23.5H1.5V25H7V23.5ZM2.25 24.25V18.75H0.75V24.25H2.25ZM14.7197 5.53033L20.2197 11.0303L21.2803 9.96967L15.7803 4.46967L14.7197 5.53033ZM12.5 25H24.1875V23.5H12.5V25Z"
                        fill="#312F37"
                    />
                </svg>
                
                <template v-if="installer.description">
                    <span v-html="installer.description" class="padrao"></span>
                </template>
                <p v-else>Sem descrição</p>
    
                <p class="titlePadrao mt-10">
                    Trabalhamos com estas marcas:
                </p>
                <!--<ul class="caption mt-4">
                    <li v-for="(b, i) in installer.brands_installer" :key="i" class="padrao">{{ b.trading_name }}</li>
                </ul>-->

                <v-sheet
                    class="mx-auto mt-4"
                    elevation="0"
                    max-width="100%"
                >
                    <v-slide-group
                    class="pa-1"
                    show-arrows
                    >
                    <v-slide-item
                        v-for="(b, i) in installer.brands_installer" 
                        :key="i"
                        class="fill-height"
                        align="center"
                        justify="center"
                    >
                        <v-img
                        class="ma-1"
                        max-height="168"
                        max-width="168"
                        :src="b.image"
                        >
                        </v-img>
                    </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </div>
    
            <div v-show="editing">
                <div class="text-right">
                    <v-btn
                        @click="onUpdate"
                        elevation="2"
                        color="orange"
                        class="white--text mb-5"
                        :disabled="isLoading"
                        :loading="isLoading"
                        >Salvar</v-btn
                    >
                </div>
                <vue-editor v-model="installer.description" />

                <p class="titlePadrao mt-10">
                    Trabalhamos com estas marcas:
                </p>
    
                <v-autocomplete
                    class="ma-2 padrao"
                    :items="brands"
                    label="Marcas"
                    v-model="installer.brands_ids"
                    multiple
                    item-text="trading_name"
                    item-value="id"
                    chips
                    small-chips
                ></v-autocomplete>
    
                
                
            </div>
    
            <p class="titlePadrao mt-10" v-if="installer.images.length > 0">Fotos</p>
    
            <DropzoneComponent
                @onOver="onOver"
                @onLeave="onLeaveImages"
                bgColor="transparent"
                :multiple="true"
                v-if="editing"
                class="mb-5"
            >
                <template v-slot:content>
                    <div class="text-center">
                        <v-icon large class="gray--text text--darken-2"
                            >mdi-camera-outline</v-icon
                        >
                    </div>
                    <h3
                        class="mt-3 mb-3 dropzone-custom-title font-weight-light text-center caption grey--text text--darken-1"
                    >
                        Arraste as fotos da sua empresa ou clique para selecionar os
                        arquivos que deseja<br />
                        adicionar. Você pode selecionar/arrastar vários arquivos de
                        uma vez.
                    </h3>
                    <div class="caption text-center">
                        <i
                            ><strong>Dica:</strong> use arquivos JPG, quadrado, com
                            1200x1200 pixels. Suba fotos da sua empresa: da
                            fachada,<br />
                            recepção, escritório, do seu time no escritório ou
                            trabalhando na instalação das obras.</i
                        >
                    </div>
                </template>
            </DropzoneComponent>
            
            <v-row>
                <v-col md="4" v-for="(image, index) in installer.images" :key="index">
                    <CardComponent class="mb-10" :image="image.url" v-if="editing">
                        <template v-slot:actionsfloat>
                            <v-btn
                                :disabled="false"
                                :loading="false"
                                class="white ml-3"
                                depressed
                                elevation="2"
                                icon
                                @click="removeImage(index)"
                            >
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </template>
                    </CardComponent>
    
                    <v-card v-else>
                        <v-img
                            :src="image.url"
                            height="200px"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DropzoneComponent from "@/components/DropzoneComponent/Index";
import CardComponent from "@/components/CardComponent/Index";

import { mapActions } from 'vuex'

export default {
    components: {
        DropzoneComponent,
        CardComponent,
    },
    props: {
        installer: {
            type: Object
        }
    },
    data() {
        return {
            images: [],
            brands: [],
            isLoading: false,
            editing: false
        };
    },
    
    async mounted() {
        this.brands = await this.loadBrands()
    },

    methods: {
        ...mapActions(['updateInstallers', 'loadBrands']),
        onOver() {},
        onLeave(file) {
            console.log(file);
        },
        removeImage(index) {
            const imagem = this.installer.photos[index]

            this.installer.imagens_apagadas.push(imagem)

            this.installer.photos.splice(index, 1)
            
        },

        async onUpdate () {
            this.isLoading = true
            const installer = {...this.installer}
            const r = await this.updateInstallers(installer)
            
            if (r) {
                this.editing = false
                this.$eventHub.$emit('snackBar', {color: 'success', message: 'Dados alterados com sucesso'})
                
                this.$emit('updateInstaller', true)
                
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao alterar dados'})
            }
            
            this.isLoading = false
        },

        async onLeaveImages(event) {
            for (let image of event) {
                this.installer.photos.push({ url: await this.toBase64(image) });
            }
        },

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
    }
};
</script>

<style scoped>
.edit-icon {
    position: absolute;
    top: 30px;
    right: 50px;
    cursor: pointer;
}
    
    
    .titlePadrao {
        font-family: "cerapro-regular";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: #312F37;;
    }
    
    
    .padrao {
        font-family: "cerapro-regular" !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #312F37;

    }
</style>
