<template>
    <div>
        <v-app-bar app color="white" class="elevation-1">
            <img
                src="@/assets/logo.png"
                alt="Logo"
                @click="goTo('https://marketsolar.com.br/')"
                class="mouse-pointer"
            />

            <div style="width: 100%" class="d-none d-lg-block d-xl-block">
                <StepsSimulator
                    v-if="
                        (isLoginPageWithSimulator() ||
                            simulationPages.includes($route.name) ||
                            checkCreateAccount()) &&
                        !this.$route.query.old
                    "
                />

                <div
                    v-else-if="
                        !authRoutes.includes($route.name) ||
                        this.$route.query.old
                    "
                    style="
                        width: 100%;
                        max-width: 580px;
                        color: #002a53;
                        font-size: 14px;
                        font-weight: bold;
                        cursor: pointer;
                    "
                    class="d-none d-lg-flex justify-space-between mx-auto"
                >
                    <span
                        v-for="(m, i) in mainMenu"
                        :key="i"
                        @click="goTo(m.path)"
                        >{{ m.title }}</span
                    >
                </div>
            </div>

            <v-spacer></v-spacer>

            <v-btn
                v-if="isLogged && getUser.user_type == 4"
                color="white"
                outlined
                class="elevation-0 mr-3 blue--text text--darken-4 d-none d-md-block"
                @click="$router.push('/company/profile')"
                style="position: absolute; right: 70px"
                >Minha página</v-btn
            >

            <v-menu v-if="isLogged" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="d-none d-md-flex"
                        size="40"
                        style="background: #002a53"
                        ><span class="white--text" style="font-size: 20px">{{
                            getIniciais(getUser.name)
                        }}</span>
                    </v-avatar>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-title class="title">{{
                            getUser.name
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="item-styl">{{
                            getUser.email
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item
                        @click="$router.push('/notifications')"
                        v-if="getUser && getUser.user_type != 5"
                    >
                        <v-list-item-title class="item-styl"
                            >Notificações</v-list-item-title
                        >
                    </v-list-item>


                    <!-- INSTALADOR -->
                    <v-list-item
                        @click="$router.push('/profile')"
                        v-if="getUser && getUser.user_type == 4"
                    >
                        <v-list-item-title class="item-styl"
                            >Configurações</v-list-item-title
                        >
                    </v-list-item>


                    <!-- CLIENTE -->
                    <v-list-item
                        @click="$router.push('/client/profile')"
                        v-if="getUser && getUser.user_type == 3"
                    >
                        <v-list-item-title class="item-styl"
                        >Configurações</v-list-item-title
                        >
                    </v-list-item>
                    
                    
                    <v-list-item @click="$emit('onLogout')">
                        <v-list-item-title
                            class="item-styl font-weight-bold"
                            style="text-decoration: underline"
                            >Sair</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-app-bar-nav-icon
                v-if="
                    !authRoutes.includes($route.name) || this.$route.query.old
                "
                @click="$emit('onDrawer')"
                class="d-md-none"
            ></v-app-bar-nav-icon>
        </v-app-bar>

        <v-main>
            <div class="faixa d-none">
                <span
                    >Se interessou?
                    <span
                        style="text-decoration: underline"
                        @click="
                            $router.push({
                                path: '/talktoconsultant',
                                query: { simulator: $route.params.id },
                            })
                        "
                        >Fale com o consultor</span
                    >
                </span>
            </div>

            <v-tabs
                background-color="rgba(0, 42, 83, 0.6)"
                centered
                dark
                icons-and-text
                height="62px"
                :hide-slider="true"
                class="tabs-component"
                v-if="
                    ((!authRoutes.includes($route.name) &&
                        $route.name != 'talktoconsultant') ||
                        this.$route.query.old) &&
                    getUser && getUser.user_type != 5
                "
            >
                <v-tab
                    v-for="(n, i) in menuItens"
                    :key="i"
                    @click="$router.push(n.href)"
                    v-if="checkProfile(n)"
                    class="pt-2"
                >
                    <span class="font-menu no-uppercase">{{ n.label }}</span>

                    <img :src="`/images/menu/${n.icon}`" />
                </v-tab>
            </v-tabs>

            <v-container
                fluid
                :class="{ tabsshowing:true }"
            >
                <div
                    class="steps-mobile"
                    :style="
                        $route.name == 'result-simulator' ? `margin-top:150px;` : ''
                    "
                    v-if="
                            (isLoginPageWithSimulator() ||
                                simulationPages.includes($route.name) ||
                                checkCreateAccount()) &&
                            !this.$route.query.old
                        "
                >
                    <StepsSimulator
                        
                    />
                </div>
                <router-view></router-view>
            </v-container>
        </v-main>


    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StepsSimulator from "./StepsSimulator";

export default {
    components: {
        StepsSimulator,
    },
    name: "FooterView",
    computed: {
        ...mapGetters(["isLogged", "getUser", "getActiveStep"]),
        active() {
            return this.getActiveStep;
        },
    },
    props: ["authRoutes", "drawer", "mainMenu", "menuItens"],
    data() {
        return {
            simulationPages: [
                "simulator",
                "result-simulator",
                "talktoconsultant",
            ],
        };
    },
    watch: {
        active(v) {
            this.handleMobileStepsLabels();
        },
        $route(to, from) {
            this.handleMobileStepsLabels();
        },
    },
    mounted() {
        this.handleMobileStepsLabels();
    },
    methods: {
        ...mapActions(["setStepSimulation"]),
        checkProfile(menu) {
            if (menu.profiles) {
                if (
                    this.getUser &&
                    menu.profiles.includes(this.getUser.user_type)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        handleMobileStepsLabels() {
            setTimeout(() =>
                document
                    .querySelectorAll(".steps-mobile .v-stepper__label")
                    .forEach((_, i) =>
                        _.classList[i == this.active - 1 ? "remove" : "add"](
                            "d-none"
                        )
                    )
            );
        },

        isLoginPageWithSimulator() {
            return (
                this.$route.query.simulator > 0 && this.$route.name === "login"
            );
        },
        getIniciais(nameString) {
            if(!nameString){
                return ""
            }
            try {
                const fullName = nameString.split(" ");
                const initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                return initials.toUpperCase();
            } catch (e) {
                return nameString.substring(0, 2).toUpperCase();
            }
        },
        goToBlank(url) {
            window.open(url);
        },

        goTo(url) {
            window.location.href = url;
        },

        checkCreateAccount() {
            this.set;
            if (
                this.$route.name == "createaccount" &&
                this.$route.query.simulator
            ) {
                this.setStepSimulation(4);
                return true;
            }

            return false;
        },
    },
};
</script>

<style scoped>
[role="separator"] {
    margin-top: 25px !important;
}

.title {
    color: #312f37;
    font-weight: bold;
    font-size: 16px;
}
.item-styl {
    font-weight: normal;
    font-size: 14px;
    color: #312f37;
}

.v-list.v-sheet.theme--light {
    background-color: #f4f4f6 !important;
    border-radius: 3px;
}

.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    box-shadow: none !important;
}

.v-list-item,
.v-list-item.theme--light {
    min-height: 30px !important;
}

.steps-mobile {
    margin-top: 74px;
    background: #fefefe !important;
}

@media (min-width: 960px) {
    .steps-mobile {
        display: none;
    }
}
.faixa:not([class*="d-none"]) + .tabs-component{
    margin-top:50px;
}

@media (min-width: 960px) {
    .faixa:not([class*="d-none"]) + .tabs-component{
        margin-top:45px;
    }
}
</style>
