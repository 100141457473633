<template>
    <div>
        <div id="wrapper">
            <div
                id="sliderContainer"
                :style="{ width: width + 'px', height: height + 'px' }"
            >
                <input
                    id="bar"
                    type="range"
                    :min="min"
                    :max="max"
                    step="16"
                    v-model="value"
                    @input="updateValueBoxPosition($event)"
                    :disabled="disabled"
                    v-if="!this.$route.query.shared"
                />
                <input
                    id="bar"
                    type="range"
                    :min="min"
                    :max="max"
                    step="16"
                    v-model="value"
                    disabled
                    v-if="this.$route.query.shared"
                />
                <div
                    id="valueBox"
                    ref="valueBox"
                    :style="{ left: boxLeft + 'px' }"
                >
                    <div
                        id="valueWrapper"
                        style="background: #fff"
                        v-if="isMoving"
                    ></div>

                    <div
                        id="valueWrapper"
                        class="user-selected-none"
                        v-if="!isMoving"
                    >
                        <div id="value">Consumo atual</div>
                    </div>

                    <svg
                        id="valueBoxArrow"
                        width="68"
                        height="68"
                        viewBox="0 0 68 68"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="34"
                            cy="34"
                            r="30"
                            fill="white"
                            stroke="#FFA900"
                            stroke-width="8"
                        />
                        <text
                            x="50%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            fill="#5E5873"
                            v-if="value != 55"
                            class="user-selected-none"
                        >
                            {{ getFriendlyLabel(value) }}
                        </text>

                        <svg
                            x="26%"
                            y="38%"
                            width="33"
                            height="16"
                            viewBox="0 0 33 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            v-else
                        >
                            <path
                                d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM32.7071 8.70711C33.0976 8.31658 33.0976 7.68342 32.7071 7.29289L26.3431 0.928932C25.9526 0.538408 25.3195 0.538408 24.9289 0.928932C24.5384 1.31946 24.5384 1.95262 24.9289 2.34315L30.5858 8L24.9289 13.6569C24.5384 14.0474 24.5384 14.6805 24.9289 15.0711C25.3195 15.4616 25.9526 15.4616 26.3431 15.0711L32.7071 8.70711ZM1 9L32 9V7L1 7L1 9Z"
                                fill="#5E5873"
                            />
                        </svg>
                    </svg>
                </div>
            </div>
        </div>

        <div class="slider-text text-justify">
            <p
                :class="{ visible: +value === 16 }"
                :style="{ 'max-width': `${width}px` }"
            >
                Parabéns, você irá gerar 50% do seu consumo atual.
                Essa escolha já é o início para sua independência energética.
            </p>
            <p
                :class="{ visible: +value === 35 }"
                :style="{ 'max-width': `${width}px` }"
            >
                Parabéns, você irá gerar 80% do seu consumo atual. Falta pouco
                para conquistar sua independência energética. Assim que possível
                aumente sua produção.
            </p>
            <p
                :class="{ visible: +value === 55 }"
                :style="{ 'max-width': `${width}px` }"
            >
                Parabéns, você irá gerar 100% do seu consumo atual. A régua
                representa quanto a mais ou a menos você pode produzir de
                energia. Mova a régua para simular uma geração a mais ou apenas
                uma parte do seu consumo atual.
            </p>
            <p
                :class="{ visible: +value === 64 }"
                :style="{ 'max-width': `${width}px` }"
            >
                Parabéns, você irá gerar 20% a mais do seu consumo atual.
                Tenha mais comodidade no uso do ar condicionado ou outros equipamentos que utiliza.
            </p>
            <p
                :class="{ visible: +value === 80 }"
                :style="{ 'max-width': `${width}px` }"
            >
                Parabéns, você irá gerar 50% a mais do seu consumo atual.
                Se planeja adicionar mais um ar condicionado ou até mesmo
                aumentar a família, recomendamos aumentar a sua produção de
                energia solar.
            </p>
            <p
                :class="{ visible: +value === 96 }"
                :style="{ 'max-width': `${width}px` }"
            >
                Parabéns, você irá gerar 100% a mais do seu consumo atual.
                Se deseja olhar para o futuro e ter um carro elétrico, dobrar
                sua produção te garantirá aumentar a sua independência
                energética.
            </p>
        </div>
    </div>
</template>

<script>
let debounce = null;

export default {
    name: "SliderBar",
    props: {
        width: {
            type: Number,
            default: 400,
        },
        initValue: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            height: 9,
            min: 16,
            max: 96,
            value: 55,
            handleWidth: 16,
            handleHeight: 9,
            boxLeft: 0,
            isMoving: false,
            isInited: false
        };
    },
    watch: {
        value(v) {
            if (+v === 48) {
                this.value = 55;
            } else if (+v === 32) {
                this.value = 35;
            }
            
            if (this.isInited) {
                this.$emit("onLabel", this.getFriendlyLabel(v));
                this.$emit("onValue", this.value);
                this.$emit("onRealValue", this.getFriendValue(this.value));   
            }
        },
    },
    computed: {
        percent() {
            const range = this.max - this.min;
            const absValue = this.value - this.min;

            return absValue / range;
        },
    },
    methods: {
        getFriendlyLabel(v) {
            switch (parseInt(v)) {
                case 16:
                    return "-50%";

                case 35:
                    return "-20%";

                case 55:
                    return "100%";

                case 64:
                    return "+20%";

                case 80:
                    return "+50%";

                case 96:
                    return "+100%";

                default:
                    return v + "%";
            }
        },

        getFriendValue(v) {
            switch (parseInt(v)) {
                case 16:
                    return -50;

                case 35:
                    return -20;

                case 55:
                    return 0;

                case 64:
                    return 20;

                case 80:
                    return 50;

                case 96:
                    return 100;

                default:
                    return "";
            }
        },


        getFriendRealValue(v) {
            switch (parseInt(v)) {
                case -50:
                    return 16;

                case -20:
                    return 35;

                case 0:
                    return 55;

                case 20:
                    return 64;

                case 50:
                    return 80;

                case 100:
                    return 96;

                default:
                    return null;
            }
        },
        
        calcBoxLeft() {
            const boxRect = this.$refs.valueBox.getBoundingClientRect();

            return (
                this.percent * (this.width - this.handleWidth) +
                this.handleWidth / 2 -
                boxRect.width / 2
            );
        },
        updateValueBoxPosition() {
            this.isMoving = true;
            this.$nextTick(() => {
                this.boxLeft = this.calcBoxLeft();
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                    this.isMoving = false;
                }, 500);
            });
        },
    },
    mounted() {
        this.isInited = false
        if (this.initValue) {
            this.value = this.getFriendRealValue(this.initValue) != null ? this.getFriendRealValue(this.initValue) : this.initValue
        }
        this.updateValueBoxPosition();
        
        this.isInited = true
    },
};
</script>

<style scoped>
#wrapper {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
}

#sliderContainer {
    position: relative;
}

#gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    clip-path: url(#handleClip);
}

#handle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#valueBox {
    position: absolute;
    top: -52px;
    min-width: 68px;
    padding: 3px;
    border-radius: 4px;
}

#valueBoxArrow {
    position: absolute;
    bottom: -75px;
    left: calc(50% - 34px);
    -webkit-transform: scale(1);
    transform: scale(1);
    pointer-events: none;
}

#valueWrapper {
    width: 101px;
    height: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    background: #0a85ff;
    border-radius: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

#value {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #ffffff;
    text-align: center;
}

#value > span {
    vertical-align: top;
    font-size: 16px;
}

/*

    REMOVE SLIDER STYLE DEFAULTS

*/
input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    margin: 0;
    z-index: 1;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-moz-focus-outer {
    border: 0;
}

/*
    HANDLE
*/
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 32px;
    background: transparent;
    cursor: pointer;
    margin-top: -16px;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 32px;
    background: transparent;
    cursor: pointer;
    border: none;
    margin-top: -16px;
}

/*
    TRACK
*/
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: linear-gradient(90deg, #0a85ff 0%, #12cb61 85.42%);
    border-radius: 300px;
}

input[type="range"]:focus::-webkit-slider-runnable-track,
input[type="range"]:active::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, #0a85ff 0%, #12cb61 85.42%);
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: linear-gradient(90deg, #0a85ff 0%, #12cb61 85.42%);
    border-radius: 300px;
}

.slider-text {
    height: 220px;
    margin-left: 15px;
}

.slider-text p {
    position: absolute;
    opacity: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #5e5873;
}

.visible {
    position: static;
    transition: 400ms ease-in-out;
    opacity: 1 !important;
}

.user-selected-none {
    user-select: none;
}
</style>
