import Http from '@/plugins/Http'

export default {


    async getProfileClient ({}) {
        try {
            const {data} = await Http.get('/profile-client')

            return data
        } catch (e) {
            return false
        }
    },


    async updateClient ({}, client) {
        try {
            await Http.put('/client/' + client.id, client)
            return true
        } catch (e) {
            return false
        }
    },
    
    
    async changeMyPasswordClient ({}, client) {
        try {
            await Http.put('/client-my-password/' + client.id, client)
            return { status: true }
        } catch (e) {
            return { status: false, error: e.response && e.response.data && e.response.data.error ? e.response.data.error : 'Erro ao criar conta' }
        }
    }
    
}
