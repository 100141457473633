import Http from '@/plugins/Http'

export default {
    async getProfileInstaller ({}) {
        try {
            const {data} = await Http.get('/profile-installer')
            
            return data
        } catch (e) {
            return false            
        }
    },


    async updateInstallers ({}, installer) {
        try {
            await Http.put('/installer/' + installer.id, installer)
            return true
        } catch (e) {
            return false
        }
    },


    async updateObras ({}, installer) {
        try {
            await Http.put('/installer/' + installer.id + '/obras', installer)
            return true
        } catch (e) {
            return false
        }
    },
    
    
    async checkCNPJ ({}, params) {
        try {
            const {data} = await Http.get('/checkCNPJ', { params })

            return data
        } catch (e) {
            return false
        }
    }
}
