import Http from '@/plugins/Http'

export default {
    async getConsultant ({}, consultant_id) {
        const { data } = await Http.get(`/consultant/${consultant_id}`)
        return data
    },

    async loadCompanySize({commit}) 
    {
        const {data} =  await Http.get(`/company_size`)
        commit('SET_COMPANY_SIZE', data)
    },
    async loadConsumerType({commit}) 
    {
        const {data} = await Http.get(`/consumer_type`)
        commit('SET_CONSUMER_TYPE', data)
    },
}
