export default {
    'SET_COMPANY_SIZE'(state, companySize)
    {
        state.companySize = companySize
    },
    'SET_CONSUMER_TYPE'(state, consumerType)
    {
        state.consumerType = consumerType
    },
}
