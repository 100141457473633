<template>
    <v-stepper alt-labels v-model="getActiveStep" class="stepper elevation-0 mx-auto bg-transprent">
        <v-stepper-header>
            <v-stepper-step :step="1" :complete="getActiveStep > 1" color="orange" class="pa-0 pl-3 pt-2 pr-3 mt-1 pb-2">
                <span style="font-size: 14px;color: #5E5873;">Simulação</span>
            </v-stepper-step>
            <v-divider :style="`${ getActiveStep > 1 ? 'border-color:orange' : null }`"></v-divider>

            <v-stepper-step :step="2" :complete="getActiveStep > 2" color="orange" class="pa-0 pl-3 pt-2 pr-3 mt-1">
                <span style="font-size: 14px;color: #5E5873;">Resultado</span>
            </v-stepper-step>
            <v-divider :style="`${ getActiveStep > 2 ? 'border-color:orange' : null }`"></v-divider>

            <v-stepper-step :step="3" :complete="getActiveStep > 3" color="orange" class="pa-0 pl-3 pt-2 pr-3 mt-1">
                <span style="font-size: 14px;color: #5E5873;">Resultado completo</span>
            </v-stepper-step>
            <v-divider :style="`${ getActiveStep > 3 ? 'border-color:orange' : null }`"></v-divider>

            <v-stepper-step :step="4" :complete="getActiveStep > 4" color="orange" class="pa-0 pl-3 pt-2 pr-3 mt-1">
                <span style="font-size: 14px;color: #5E5873;">Falar com consultor</span>
            </v-stepper-step>
            <v-divider :style="`${ getActiveStep > 4 ? 'border-color:orange' : null }`"></v-divider>

            <v-stepper-step :step="5" :complete="getActiveStep > 5" color="orange" class="pa-0 pl-3 pt-2 pr-3 mt-1">
                <span style="font-size: 14px;color: #5E5873;">Pedir orçamento</span>
            </v-stepper-step>
        </v-stepper-header>
    </v-stepper>
</template>


<script>
    import { mapGetters } from 'vuex'

    import StepsSimulator from './StepsSimulator'

    export default {
        components: {
            StepsSimulator
        },
        name: 'FooterView',
        computed: {
            ...mapGetters(['getActiveStep'])
        },

    }
</script>

<style>
    .stepper__step, .v-stepper {
        background-color: transparent !important;
    }
    @media only screen and (max-width: 959px) {
        .v-stepper__label {
            display: flex !important;
        }
    }
</style>

<style scoped>
    .v-stepper__header {
        box-shadow: none !important;
    }
    .stepper{
        height:70px;
        margin-top:-6px;
        max-width:1000px
    }
</style>