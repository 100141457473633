var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"elevation-1",attrs:{"app":"","color":"white"}},[_c('img',{staticClass:"mouse-pointer",attrs:{"src":require("@/assets/logo.png"),"alt":"Logo"},on:{"click":function($event){return _vm.goTo('https://marketsolar.com.br/')}}}),_c('div',{staticClass:"d-none d-lg-block d-xl-block",staticStyle:{"width":"100%"}},[(
                    (_vm.isLoginPageWithSimulator() ||
                        _vm.simulationPages.includes(_vm.$route.name) ||
                        _vm.checkCreateAccount()) &&
                    !this.$route.query.old
                )?_c('StepsSimulator'):(
                    !_vm.authRoutes.includes(_vm.$route.name) ||
                    this.$route.query.old
                )?_c('div',{staticClass:"d-none d-lg-flex justify-space-between mx-auto",staticStyle:{"width":"100%","max-width":"580px","color":"#002a53","font-size":"14px","font-weight":"bold","cursor":"pointer"}},_vm._l((_vm.mainMenu),function(m,i){return _c('span',{key:i,on:{"click":function($event){return _vm.goTo(m.path)}}},[_vm._v(_vm._s(m.title))])}),0):_vm._e()],1),_c('v-spacer'),(_vm.isLogged && _vm.getUser.user_type == 4)?_c('v-btn',{staticClass:"elevation-0 mr-3 blue--text text--darken-4 d-none d-md-block",staticStyle:{"position":"absolute","right":"70px"},attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$router.push('/company/profile')}}},[_vm._v("Minha página")]):_vm._e(),(_vm.isLogged)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticStyle:{"background":"#002a53"},attrs:{"color":"d-none d-md-flex","size":"40"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.getIniciais(_vm.getUser.name)))])])]}}],null,false,1703089007)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.getUser.name))])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"item-styl"},[_vm._v(_vm._s(_vm.getUser.email))])],1),_c('v-divider'),(_vm.getUser && _vm.getUser.user_type != 5)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/notifications')}}},[_c('v-list-item-title',{staticClass:"item-styl"},[_vm._v("Notificações")])],1):_vm._e(),(_vm.getUser && _vm.getUser.user_type == 4)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/profile')}}},[_c('v-list-item-title',{staticClass:"item-styl"},[_vm._v("Configurações")])],1):_vm._e(),(_vm.getUser && _vm.getUser.user_type == 3)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/client/profile')}}},[_c('v-list-item-title',{staticClass:"item-styl"},[_vm._v("Configurações")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('onLogout')}}},[_c('v-list-item-title',{staticClass:"item-styl font-weight-bold",staticStyle:{"text-decoration":"underline"}},[_vm._v("Sair")])],1)],1)],1):_vm._e(),(
                !_vm.authRoutes.includes(_vm.$route.name) || this.$route.query.old
            )?_c('v-app-bar-nav-icon',{staticClass:"d-md-none",on:{"click":function($event){return _vm.$emit('onDrawer')}}}):_vm._e()],1),_c('v-main',[_c('div',{staticClass:"faixa d-none"},[_c('span',[_vm._v("Se interessou? "),_c('span',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.$router.push({
                            path: '/talktoconsultant',
                            query: { simulator: _vm.$route.params.id },
                        })}}},[_vm._v("Fale com o consultor")])])]),(
                ((!_vm.authRoutes.includes(_vm.$route.name) &&
                    _vm.$route.name != 'talktoconsultant') ||
                    this.$route.query.old) &&
                _vm.getUser && _vm.getUser.user_type != 5
            )?_c('v-tabs',{staticClass:"tabs-component",attrs:{"background-color":"rgba(0, 42, 83, 0.6)","centered":"","dark":"","icons-and-text":"","height":"62px","hide-slider":true}},_vm._l((_vm.menuItens),function(n,i){return (_vm.checkProfile(n))?_c('v-tab',{key:i,staticClass:"pt-2",on:{"click":function($event){return _vm.$router.push(n.href)}}},[_c('span',{staticClass:"font-menu no-uppercase"},[_vm._v(_vm._s(n.label))]),_c('img',{attrs:{"src":("/images/menu/" + (n.icon))}})]):_vm._e()}),1):_vm._e(),_c('v-container',{class:{ tabsshowing:true },attrs:{"fluid":""}},[(
                        (_vm.isLoginPageWithSimulator() ||
                            _vm.simulationPages.includes(_vm.$route.name) ||
                            _vm.checkCreateAccount()) &&
                        !this.$route.query.old
                    )?_c('div',{staticClass:"steps-mobile",style:(_vm.$route.name == 'result-simulator' ? "margin-top:150px;" : '')},[_c('StepsSimulator')],1):_vm._e(),_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }