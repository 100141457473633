<template>
    <form
        @keydown.enter="createAccount"
        class="d-block mx-auto d-flex flex-column justify-center mt-5 wm-488 pa-5"
    >
        <h1 class="title-custom">Criar conta</h1>

        <span class="mt-5 mb-2">Selecione seu perfil</span>

        <div
            class="badge-element mt-3 mb-5 d-flex justify-space-between"
            style="width: 100% !important"
        >
            <v-btn
                width="139"
                rounded
                elevation="0"
                v-for="(type, i) in userType"
                :key="i"
                :class="{
                    'no-uppercase': true,
                    orange: active.name === type.name,
                    transparent: !(active.name === type.name),
                    'white--text': active.name === type.name,
                    'grey--text text--darken-1': !(active.name === type.name),
                }"
                @click="active = type"
            >
                {{ type.name }}
            </v-btn>
        </div>

        <template v-if="active.id == 3">
            <v-text-field
                class="mt-4 mb-4"
                label="Nome completo"
                v-model="user.name"
                :rules='rules.nameRules'
                @blur="validate" 
                @focus="clearRules"
            ></v-text-field>
            <v-text-field
                class="mt-4 mb-4"
                label="E-mail"
                v-model="user.email"
                :rules='rules.emailRules'
                @blur="validate" 
                @focus="clearRules"
            ></v-text-field>
            <v-text-field
                class="mt-4 mb-4"
                label="DDD celular"
                v-model="user.phone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :rules='rules.phoneRules'
                @blur="validate" 
                @focus="clearRules"
            ></v-text-field>

            <!--<v-text-field
                class="mt-4 mb-4"
                :label="active.id == 3 ? 'CPF/CNPJ' : 'CNPJ'"
                :placeholder="active.id == 3 ? 'CPF/CNPJ' : 'CNPJ'"
                hint="Apenas números"
                persistent-hint
                v-mask="active.id == 3? ['###.###.###-##', '##.###.###/####-##'] : ['##.###.###/####-##']"
                v-model="user.cpf_cnpj"
            ></v-text-field>-->

            <!--<v-text-field
                class="mt-4 mb-4"
                v-if="(user.cpf_cnpj.length >= 15 && active.id == 3) || active.id != 3"
                label="Razão Social"
                placeholder="Razão Social"
                v-model="user.corporate_name"
            ></v-text-field>-->

            <v-text-field
                class="mt-4 mb-4"
                label="Senha"
                :type="`${showPass ? 'text' : 'password'}`"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                persistent-hint
                hint="Use 8 ou mais caracteres com maiúsculas, minúsculas e números"
                v-model="user.password"
                @click:append="showPass = !showPass"
                :rules='rules.passwordRules'
                @blur="validate" 
                @focus="clearRules"
            ></v-text-field>

            <gmap-autocomplete
                class="introInput"
                :style="`${!selectedLocation && selectedLocation != null ? 'border-bottom-color: #ff5252;': 'margin-bottom: 50px; border-bottom-color: rgba(0, 0, 0, 0.42);'}`"
                placeholder="Digite seu endereço"
                @place_changed="setPlace"
                v-on:blur="checkLocation()"
                v-on:focus="selectedLocation = true"
            >
                <template v-slot:input="slotProps">
                    <v-text-field
                        outlined
                        prepend-inner-icon="place"
                        placeholder="Location Of Event"
                        ref="input"
                        v-on:listeners="slotProps.listeners"
                        v-on:attrs="slotProps.attrs"
                        types="(address)"
                        country="br"
                    >
                    </v-text-field>
                </template>
            </gmap-autocomplete>
            <small v-if="!selectedLocation && selectedLocation != null" style="color: #ff5252;font-size: 12px;">Para preencher corretamente este campo, clique em uma das opções que aparecem ao digitar o endereço. Tente outra vez</small>

            <span class="mt-5 mb-2">Para sermos mais assertivos em suas propostas de acordo com as suas características de busca, nos informe quais as suas prioridades para estes tipos de projeto</span>

            <v-select
                :items="priorityItems"
                :item-disabled="disableItem"
                item-text="name"
                label="Prioridade 1"
                v-model="user.priorities[0]"
                return-object
                style="font-size: 18px;"
                class="input_defualt"
                :rules="rules.select"
                @blur="validate" 
                @focus="clearRules"
            ></v-select>

            <v-select
                :items="priorityItems"
                :item-disabled="disableItem"
                item-text="name"
                label="Prioridade 2"
                v-model="user.priorities[1]"
                return-object
                style="font-size: 18px;"
                class="input_defualt"
                :rules="rules.select"
                @blur="validate" 
                @focus="clearRules"
            ></v-select>

            <v-select
                :items="priorityItems"
                :item-disabled="disableItem"
                item-text="name"
                label="Prioridade 3"
                v-model="user.priorities[2]"
                return-object
                style="font-size: 18px;"
                class="input_defualt"
                :rules="rules.select"
                @blur="validate" 
                @focus="clearRules"
            ></v-select>

            <span class="mt-5 mb-2">Disponibilidade para receber chamadas</span>

            <div class="d-flex align-center" style="margin-top: 27px;">
                <v-checkbox v-model="user.availabilityToBeCalled.morning"></v-checkbox>
                    <span class="span-check" style="margin-right: 10px;"
                        >Manhã</span
                    >
                <v-checkbox v-model="user.availabilityToBeCalled.afternoon"></v-checkbox>
                    <span class="span-check" style="margin-right: 10px;"
                        >Tarde</span
                    >
                <v-checkbox v-model="user.availabilityToBeCalled.night"></v-checkbox>
                    <span class="span-check" style="margin-right: 10px;"
                        >Noite</span
                    >
                <v-checkbox v-model="user.availabilityToBeCalled.weekend"></v-checkbox>
                    <span class="span-check"
                        >Finais de semana</span
                    >
            </div>

            <br>

            <v-select
                    :items="installationPlaces"
                    item-text="name"
                    label="O projeto será realizado no telhado ou solo?"
                    v-model="user.installationPlace"
                    return-object
                    style="font-size: 18px;"
                    class="input_defualt"
                    :rules="rules.select"
                    @blur="validate"
                    @focus="clearRules"
            ></v-select>

            <div v-if="user.installationPlace == 'Telhado'">
                <span class="mt-5 mb-2">O telhado tem mais de 20 anos?</span>

                <div class="d-flex align-center" style="margin-top: 27px;">
                    <v-radio-group row v-model="user.isRoofAgeGreaterThan20">
                        <v-radio value="true" id="true" label="Sim"></v-radio>
                        <v-radio value="false" id="false" label="Não"></v-radio>
                    </v-radio-group>
                </div>
            </div>

            <span class="mt-5 mb-2">Para esses tipos de projetos, qual forma de pagamento você utilizaria?</span>

            <div class="d-flex align-center" style="margin-top: 27px;">
                <v-radio-group row v-model="user.paymentMethod">
                    <v-radio value="incash" id="incash" label="À vista"></v-radio>
                    <v-radio value="financing" id="financing" label="Financiado"></v-radio>
                </v-radio-group>
            </div>

            <br>

        </template>

        <template v-else>
            <v-text-field
                class="mt-4 mb-4"
                label="E-mail"
                v-model="user.email"
                :rules="rules.emailRules"
                @blur="validate" 
                @focus="clearRules"
            ></v-text-field>

            <v-text-field
                class="mt-4 mb-4"
                label="DDD celular"
                v-model="user.phone"
                v-mask="['(##) #####-####']"
                :rules="rules.phoneRules"
                @blur="validate" 
                @focus="clearRules"
            ></v-text-field>
        </template>

        <div class="d-flex align-center" :style="`${!selectedLocation ? 'margin-top: 27px;': ''}`">
            <v-checkbox v-model="user.opt_in"></v-checkbox
            ><span class="span-check"
                >Aceito receber e-mails e mensagens da Market Solar.</span
            >
        </div>

        <div class="d-flex align-center">
            <v-checkbox v-model="terms"></v-checkbox
            ><span class="span-check"
                >Li e aceito os
                <a
                    class="span-check"
                    href="https://marketsolar.com.br/termos-e-condicoes-de-uso/"
                    target="_blank"
                    >Termos e condições de uso.</a
                ></span
            >
        </div>

        <v-btn
            @click="createAccount"
            large
            block
            elevation="0"
            class="mt-5 orange white--text mb-5 no-uppercase"
            :disabled="!(active.id == 3 ? isValidClient : isValid) || isLoading"
            :loading="isLoading"
            style="font-size: 16px"
            >Criar conta</v-btn
        >

        <div class="d-flex justify-space-between">
            <span class="grey--text mb-5">Já possui uma conta? </span>
            <a
                class="blue--text text--darken-4"
                @click="$router.push('/login')"
                style="text-decoration: underline"
                >Entrar</a
            >
        </div>
    </form>
</template>

<script>
import { mapActions } from "vuex";
export default {
    metaInfo: {
        title: "Criar conta - Market Solar",
    },
    name: "CreateAccount",
    data() {
        return {
            rules: [],
            active: { name: "Cliente final", id: 3 },
            userType: [
                { name: "Cliente final", id: 3 },
                { name: "Empresa solar", id: 4 },
                { name: "Distribuidor", id: 5 },
            ],
            user: {
                name: "",
                email: "",
                phone: "",
                password: "",
                cpf_cnpj: "",
                corporate_name: "",
                opt_in: false,
                priorities: [{
                    name: "",
                },
                {
                    name: "",
                },
                {
                    name: ""
                }],
                availabilityToBeCalled: {
                    morning: false,
                    afternoon: false,
                    night: false,
                    weekend: false
                },
                paymentMethod: "incash",
                installationPlace: "",
                isRoofAgeGreaterThan20: "true",
            },
            isLoading: false,
            place: null,
            selectedLocation: null,
            showPass: false,
            terms: false,
            priorityItems: [{
                name: "Menor preço (menor R$/Wp)",
            },
            {
                name: "Melhor tecnologia de materiais",
            },
            {
                name: "Placa mais potente do mercado (Wp)",
            },
            {
                name: "Parcela menor possível do banco",
            },
            {
                name: "Marcas de maior garantia",
            },
            {
                name: "Marcas mais sólidas no mercado nacional",
            }],
            installationPlaces: ['Telhado', 'Solo']
        };
    },

    methods: {
        ...mapActions(["sendCreateAccount"]),

        test() {
            console.log(this.user);
        },

        disableItem(item) {

            if (item.name == this.user.priorities[0].name || item.name == this.user.priorities[1].name || item.name == this.user.priorities[2].name) return true;

            return false;
        },

        async sendClientWelcomeEmail(name, email, password) {

            let token = localStorage.getItem('token');

            const form = new FormData();

            form.append('name', name);
            form.append('email', email);
            form.append('id', password);

            const options = {
                method: "POST",
                body: form,
                headers: {
                    "Authorization": token
                },
            };

            let sendMail = await fetch('https://netapi1.marketsolar.com.br/messages/mail/template/welcomeClients', options);
        },

        async createAccount() {
            this.isLoading = true;
            const user = { ...this.user };

            if (this.active.id == 3) {
                if (this.user.password.length <= 7) {
                    this.isLoading = false;
                    return this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message:
                            "A senha precisa conter pelo menos 8 caracteres",
                    });
                }

                user.place = this.place;
                user.type = this.active.id;
                user.priorities = `${user.priorities[0].name},${user.priorities[1].name},${user.priorities[2].name}`;
                user.availabilityToBeCalled = `${user.availabilityToBeCalled.morning},${user.availabilityToBeCalled.afternoon},${user.availabilityToBeCalled.night},${user.availabilityToBeCalled.weekend}`;

                if (user.installationPlace == 'Telhado') {
                    user.installationPlace = 'roof';

                    if (user.isRoofAgeGreaterThan20 == "true") {
                        user.isRoofAgeGreaterThan20 = true;
                    } else {
                        user.isRoofAgeGreaterThan20 = false;
                    }
                } else {
                    user.installationPlace = 'ground';
                    user.isRoofAgeGreaterThan20 = false;
                }

                if (
                    this.$route.query.simulator &&
                    this.$route.query.simulator > 0
                ) {
                    user.simulator_id = this.$route.query.simulator;
                }

                const r = await this.sendCreateAccount(user);

                if (r.status) {

                    let sendMail = await this.sendClientWelcomeEmail(r.data.client.name, r.data.user.email, user.password);

                    if (
                        this.$route.query.simulator &&
                        this.$route.query.simulator > 0
                    ) {
                        this.$eventHub.$emit("snackBar", {
                            color: "success",
                            message: "Conta criada com sucesso",
                        });
                        this.$router.push(
                            "/result-simulator/" + this.$route.query.simulator
                        );
                    } else {
                        this.$router.push({ path: "/notifications" });
                    }

                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: r.error,
                    });
                }

            } else {
                this.$router.push({
                    path: "/register-steps",
                    query: {
                        email: this.user.email,
                        phone: this.user.phone,
                        type: this.active.id,
                        optin: true,
                    },
                });
            }

            this.isLoading = false;
        },

        setPlace(place) {
            place.latitude = place.geometry.location.lat();
            place.longitude = place.geometry.location.lng();

            this.place = place;

            this.center = {
                lat: this.place.geometry.location.lat(),
                lng: this.place.geometry.location.lng(),
            };
            this.position = {
                lat: this.place.geometry.location.lat(),
                lng: this.place.geometry.location.lng(),
            };
            this.zoom = 20;
            //console.log(this.place)
        },

        checkLocation() {
            if(this.place == null){this.selectedLocation = false}
        },

        validate() {
            this.rules = {
                "nameRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /.+@.+/.test(v) || "Insira um e-mail válido"
                ],
                "phoneRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um telefone válido"
                ],
                "passwordRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 8 || "Sua senha deve ter ao menos 8 caracteres"
                ],
                "stateRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => this.place != null || "Para preencher corretamente este campo, clique em uma das opções que aparecem ao digitar o endereço. Tente outra vez"
                ],
                "select": [
                    v => !!v || "O preenchimento desse campo é obrigatório"
                ]
            }
        },

        clearRules(){
            this.rules = []
        }
    },

    computed: {
        isValidClient() {
            return (
                this.terms &&
                !!(
                    (
                        this.user.name &&
                        /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                            this.user.email
                        ) &&
                        this.user.phone &&
                        this.user.password.length > 7 &&
                        this.place &&
                        this.user.priorities[0].name &&
                        this.user.priorities[1].name &&
                        this.user.priorities[2].name &&
                        this.user.installationPlace
                    ) /*&& this.consultant.image*/
                )
            );
        },

        isValid() {
            return (
                this.terms &&
                !!(
                    /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                        this.user.email
                    ) && this.user.phone
                )
            );
        },
    },
};
</script>

<style scoped>
.wm-488 {
    width: 100%;
    max-width: 488px;
}

.badge-element {
    width: 100%;
    padding: 4px 8px 6px 8px;
    height: 43px;
    background: rgba(255, 169, 0, 0.15);
    border-radius: 100px;
    align-self: flex-start;
}

.introInput {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    border-collapse: collapse;
    border-color: currentColor;
    border-style: solid;
    border-bottom-width: 1px;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    transition-duration: 0.15s;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-bottom: 0px;
    margin-top: 50px;
}

.introInput:focus {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    outline: none !important;
}

.v-input--selection-controls {
    margin-top: -13px !important;
}

.span-check {
    margin-top: -30px;
    font-size: 14px;
    color: #6e6b7b;
}

.title-custom {
    font-family: "cerapro-medium" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
    color: #312f37;
}
</style>
