<template>
    <v-container class="fill-height px-10" fluid>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div style="width: 100%; height: 100%" v-else>
            <template v-if="notifications.length > 0">
                <h2 class="mb-10 mt-10" style="font-size: 40px">
                    Notificações
                </h2>
                <v-row>
                    <v-col cols="9" class="mx-auto pt-10">
                        <v-card
                            v-for="(n, i) in notifications"
                            :key="i"
                            outlined
                            class="mt-10 mb-10"
                            style="background-color: #ffffff"
                        >
                            <v-card-text>
                                <div class="d-flex">
                                    <v-avatar
                                        size="64"
                                        color="rgb(110 107 123)"
                                    >
                                        <img
                                            src="/images/no-image.svg"
                                            class="align-self-center"
                                        />
                                    </v-avatar>

                                    <div
                                        class="ml-2 mt-2"
                                        style="
                                            display: flex;
                                            justify-content: space-between;
                                            width: 100%;
                                        "
                                    >
                                        <div
                                            style="
                                                display: flex;
                                                flex-direction: column;
                                            "
                                        >
                                            <span>{{
                                                getTitleNotification(n.type)
                                            }}</span>
                                            <span
                                                class="caption"
                                                v-html="n.text"
                                            ></span>
                                        </div>

                                        <small class="d-block">{{
                                            __dayjs(
                                                n.created_at,
                                                "DD/MM/YYYY H:mm:ss"
                                            )
                                        }}</small>
                                    </div>
                                </div>

                                <div style="width: 100%"></div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <div
                v-else
                class="d-flex justify-center align-center align-content-center flex-column"
                style="width: 100%; height: 100%"
            >
                <img src="/images/inbox-2.svg" />
                <p class="text-center mt-3 textNoData">
                    Ops, não há <b>Notificações</b> <br />
                    para apresentar aqui...
                </p>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";

import dayjs from "dayjs";

export default {
    metaInfo: {
        title: "Notificações - Market Solar",
    },
    data() {
        return {
            isLoading: false,
            notifications: [
                /*{
                        img: require("@/assets/user.png"),
                        text:"<strong>Flavio Swartz</strong> enviou uma mensagem para o seu pedido N.0001: <strong>Alexandre, você pode enviar a planta da casa? Estamos na finalização do projeto e precisamos analizar para enviar para a Safe Energy.</strong> ",
                        date:"hoje"
                    },
                    {
                        img: require("@/assets/user.png"),
                        text:"<strong>Flavio Swartz</strong> atualizou o orçamento N.0002",
                        date:"há 1 dia"
                    },*/
            ],
        };
    },

    async mounted() {
        this.isLoading = true;
        const r = await this.myNotifications();

        this.notifications = r.data;

        this.isLoading = false;
    },

    methods: {
        ...mapActions(["myNotifications"]),

        __dayjs(date, format) {
            return dayjs(date).format(format);
        },

        getTitleNotification(t) {
            let options = [
                { id: 1, value: "Nova simulação" },
                { id: 2, value: "Falar com o consultor" },
                { id: 3, value: "Aguardando orçamentos" },
                { id: 4, value: "Aprovar proposta" },
                { id: 5, value: "Pedido aprovado" },
                { id: 6, value: "Status do pedido" },
                { id: 7, value: "Nova mensagem" },
                { id: 8, value: "Novo cliente final" },
                { id: 9, value: "Nova empresa solar" },
                { id: 10, value: "Nova empresa solar" },
                { id: 11, value: "Nova assinatura" },
            ];

            for (let option of options) {
                if (option.id == t) {
                    return option.value;
                }
            }

            return "Tipo inválido";
        },
    },
};
</script>

<style>
main {
    background-color: #fafafa !important;
}
</style>
