<template>
    <main>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        
        <div v-else>
            <div v-if="!error" class="d-block mx-auto d-flex flex-column justify-center wm-800 mb-5 contentConsultant">
                <v-row>
                    <v-col cols="12" md="" offset-md="3">
                        <div class="contentConsultantMobile flex-no-wrap">
                            <v-avatar
                                class="ma-3"
                                size="104"
                                style="border: 1px solid rgba(255, 169, 0, 0.2); margin-top: 50px !important"
                                color="rgb(0, 42, 83)"
                            >
                                <img 
                                    :src="consultant.image"
                                    v-if="consultant.image"
                                />

                                <span v-else class="white--text text-h4">{{ getIniciais(consultant.name) }}</span>
                            </v-avatar>
                            
                            <img src="/images/chat-falar-consultor.svg" style="height: 188px; margin-top: 10px" class="imageSeparator"/>
                            
                            <div class="contentTextConsultant">
                                <div v-if="step==1">
                                    <p class="textCardConsultant">{{ getUser.name }}, tudo bem?</p>

                                    <p class="textCardConsultant">
                                        Me chamo {{ consultant.name }} e serei seu consultor da Market<br>
                                        Solar. Se precisar falar comigo, você pode enviar mensagens <br>
                                        para meu WhatsApp no número <a target="_blank" :href="`https://api.whatsapp.com/send?phone=+55${consultant.phone}`">+55 {{ consultant.phone }}</a>
                                    </p>

                                    <p class="textCardConsultant">
                                        Estou aqui para te ajudar, pode contar comigo! Agora vamos<br>
                                        iniciar seu atendimento, ok?
                                    </p>
                                </div>

                                
                                
                                <div v-if="step==2">
                                    <p class="textCardConsultant">{{ getUser.name }}, preciso que você anexe sua última conta de luz.</p>

                                    <p class="textCardConsultant">Com sua conta de luz, consigo confirmar mais informações<br>
                                        sobre sua instalação, e isso vai me ajudar a encontrar as <br>
                                        melhores empresas de energia solar para o seu projeto.
                                    </p>
                                    
                                    <div class="mt-10" style="max-width: 501px">
                                        <DropzoneComponent v-if="!documents.length" @onOver="onOver" @onLeave="onLeaveImages" :multiple="true">
                                            <template v-slot:content>
                                                <div class="text-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M22.5 22C22.5 22.0656 22.4871 22.1307 22.462 22.1914C22.4369 22.252 22.4001 22.3072 22.3536 22.3536C22.3072 22.4 22.2521 22.4369 22.1914 22.462C22.1307 22.4871 22.0657 22.5 22 22.5H2C1.86739 22.5 1.74021 22.4473 1.64645 22.3535C1.55268 22.2598 1.5 22.1326 1.5 22C1.5 21.8674 1.55268 21.7402 1.64645 21.6464C1.74021 21.5527 1.86739 21.5 2 21.5H22C22.0657 21.4999 22.1307 21.5128 22.1914 21.538C22.2521 21.5631 22.3072 21.5999 22.3536 21.6463C22.4001 21.6928 22.4369 21.7479 22.462 21.8086C22.4871 21.8693 22.5 21.9343 22.5 22ZM12 16.5C12.0657 16.5 12.1307 16.4871 12.1914 16.462C12.2521 16.4369 12.3072 16.4 12.3536 16.3536C12.4001 16.3072 12.4369 16.252 12.462 16.1914C12.4871 16.1307 12.5 16.0656 12.5 16V3.20697L16.6465 7.35346C16.7408 7.44454 16.8671 7.49494 16.9982 7.4938C17.1293 7.49266 17.2547 7.44008 17.3474 7.34737C17.4401 7.25467 17.4927 7.12926 17.4938 6.99816C17.495 6.86707 17.4446 6.74077 17.3535 6.64646L12.3535 1.64646C12.3071 1.60003 12.252 1.5632 12.1913 1.53807C12.1306 1.51293 12.0656 1.5 12 1.5C11.9343 1.5 11.8693 1.51293 11.8087 1.53807C11.748 1.5632 11.6929 1.60003 11.6465 1.64646L6.64648 6.64646C6.5554 6.74077 6.505 6.86707 6.50614 6.99816C6.50728 7.12926 6.55987 7.25467 6.65257 7.34737C6.74527 7.44008 6.87068 7.49266 7.00178 7.4938C7.13288 7.49494 7.25918 7.44454 7.35348 7.35346L11.5 3.20697V16C11.5 16.0656 11.5129 16.1307 11.538 16.1914C11.5631 16.252 11.5999 16.3072 11.6464 16.3536C11.6928 16.4 11.7479 16.4369 11.8086 16.462C11.8693 16.4871 11.9343 16.5 12 16.5Z" fill="#6E6B7B" stroke="#6E6B7B" stroke-width="0.5"/> </svg></div>
                                                <h3 class="mt-3 mb-3 dropzone-custom-title font-weight-light text-center grey--text text--darken-1">Clique ou arraste para anexar</h3>
                                                <div class="caption text-center grey--text text--darken-1"><strong>Obs:</strong> Use arquivos de imagem (.png e .jpg) e documentos .pdf</div>
                                            </template>
                                        </DropzoneComponent>
    
                                        <span class="d-block" v-else>
                                            {{documents.length}} arquivos selecionados
                                            <v-icon @click="documents=[]">close</v-icon>
                                        </span>
                                    </div>
                                </div>


                                <div v-if="step==3">
                                    <p class="textCardConsultant">
                                        Em breve entrarei em contato para conversarmos mais sobre<br>
                                        seu projeto, vamos juntos entender os requisitos técnicos e<br>
                                        assim consigo encontrar as melhores empresas de energia<br>
                                        solar para a sua obra. Aguarde meu contato, ok? Um abraço.
                                    </p>
                                </div>

                                <div v-if="step == 2" class="d-flex justify-space-between" style="max-width: 501px !important;">
                                    <a
                                        class="blue--text text--darken-4 mt-14 mb-10"
                                        @click="handleActions"
                                        style="text-decoration:underline; color: #002A53 !important;"
                                    >Fazer depois</a>


                                    <v-btn
                                        height="64"
                                        rounded
                                        class="white--text mt-10 mb-10 fontButtonConsultant"
                                        color="#002A53"
                                        @click="handleActions"
                                        :loading="isLoadingInsert"
                                        :disabled="isLoadingInsert"
                                    >Próximo</v-btn>
                                </div>
                               
                                <div v-else>
                                    <v-btn
                                        width="341"
                                        height="64"
                                        rounded
                                        class="white--text mt-10 mb-10 fontButtonConsultant"
                                        color="#002A53"
                                        v-text=" step == 1 ? `Ok, vamos lá!` : step == 3 ? `Ok, obrigado` : `Próximo` "
                                        @click="handleActions"
                                        :loading="isLoadingInsert"
                                        :disabled="isLoadingInsert"
                                    ></v-btn>
                                </div>

                               
                            </div>
                        </div>
                    </v-col>
                </v-row>
               
                
                
                
                <!--
                <div v-if="step==2">
                    <h2 class="font-weight-regular text-center mt-4">Qual é o <strong>tipo de telhado</strong> da sua instalação?</h2>
                    <v-select style="width:341px" class="mx-auto mt-5" v-model="simulator.roof_type" :items="types" item-text="label" item-value="id" label="Tipo de telhado"></v-select>
                </div>

                <div v-if="step==3">
                    <h2 class="font-weight-regular text-center mt-4">Quer gerar <strong>mais energia</strong> do que consome atualmente? </h2>
                    <span class="d-block text-center caption grey--text">Se você projeta um aumento de consumo de energia, recomendamos <br/> que seja superior a 20% do seu consumo atual.</span>
                    <v-slider style="width:341px" class="mx-auto mt-4" color="orange" persistent-hint hint="Recomendado" dense track-color="grey" v-model="simulator.more_energy" max="100" min="0" ></v-slider>
                </div>
                
                -->

                

                
            </div>

            <v-alert v-else border="left" color="red" type="error">Não foi possível carregar a página. Verifique se o usuário possui um consultor.</v-alert>
        </div>
        
    </main>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import DropzoneComponent from '../../components/DropzoneComponent/Index.vue'
    import StepsSimulator from "../../Views/StepsSimulator";

    export default {
        name: 'TalkToConsultantPage',
        components:{
            DropzoneComponent,
            StepsSimulator
            
        },
        
        data()
        {
            return {
                types: [
                    { id:1, label:"Telhado cerâmico ou colonial" }, 
                    { id:2, label:"Telhado de fibrocimento" }, 
                    { id:3, label:"Telhado metálico ondulado" }, 
                    { id:4, label:"Na minha laje"}, 
                    { id:5, label:"No solo (terreno, quintal)" }, 
                    { id:6, label:"Outro lugar / não tenho certeza" }
                ],
                step:1,
                error:false,
                consultant:{
                    id:null,
                    image:'',
                    name:''
                },
                documents:[],
                simulator:null,
                isLoadingInsert: false,
                isLoading: true
            }
        },
        async mounted()
        {
            this.isLoading = true
            this.simulator = await this.showSimulatorConsultant( {id: this.$route.query.simulator, init_consultant: true })
            if(!this.simulator)
            {
                this.isLoading = false
                return (this.error = true)
            }

            try {
                this.consultant = await this.getConsultant( this.simulator.consultant_id )
            } catch (error) {
                this.error = true
            }
            this.isLoading = false
            this.setStepSimulation(4)
        },
        computed:{
            ...mapGetters(['getUser'])
        },
        methods:{
            ...mapActions(['showSimulator', 'showSimulatorConsultant', 'getConsultant', 'insertBudget', 'setStepSimulation']),
            onOver(){},
            async onLeaveImages (event) 
            {
                for( let doc of event )
                {
                    this.documents.push( await this.toBase64(doc) )
                }
            },
            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            },
            async handleActions()
            {
                if( this.step == 3 )
                {
                    return this.$router.push('/simulations')
                }
                else if( this.step == 2 )
                {
                    this.isLoadingInsert = true
                    try {
                        await this.insertBudget({ simulator: this.simulator, documents: this.documents })
                        this.$eventHub.$emit('snackBar', {color: 'success', message: 'Orçamento criado com sucesso'})
                        this.isLoadingInsert = false
                        this.setStepSimulation(5)
                    } catch (error) {
                        console.log(error)
                        this.isLoadingInsert = false
                        return this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao realizar operação'})
                    }
                    
                }
                this.step = ++this.step
            },

            getIniciais (nameString) {
                if(!nameString){
                    return ""
                }
                try {
                    const fullName = nameString.split(' ');
                    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                    return initials.toUpperCase();
                } catch (e) {
                    return nameString.substring(0,2).toUpperCase()
                }
            },
        }
    }
</script>


<style>
    .contentTextConsultant {
        flex: 2;
        padding: 10px;
        margin-left: 20px;
    }
    .textCardConsultant {
        font-family: 'cerapro-regular' !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
    }
    
    .fontButtonConsultant {
        font-family: 'cerapro-regular' !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.02em;
        text-align: center;
        text-transform: initial !important;

    }
    
    .contentConsultant {
        margin-top: 50px;
    }

    .contentConsultantMobile {
        display: flex;
        justify-content: space-between;
    }

    .showMobile {
        display: none
    }
    
    @media screen and (max-width: 960px) {
        .contentConsultant {
            margin-top: 0px;
        }
        
        .contentConsultantMobile {
            justify-content: center !important;
            align-items: center;
            flex-direction: column;
        }
        
        .imageSeparator {
            margin-top: -70px !important;
            transform: rotate(-90deg);
        }
        
        .contentTextConsultant {
            margin-top: -40px;
            margin-left: 0px !important;
        }
        
        .paddingRightMobile {
            padding-right: 10px;
        }

        .showMobile {
            display: block;
        }
    }
</style>


