import system from './modules/system/index.js'
import simulator from './modules/simulator/index.js'
import consultant from './modules/consultant/index.js'
import budget from './modules/budget/index.js'
import order from './modules/order/index.js'
import brand from './modules/brand/index.js'
import installer from './modules/installer/index.js'
import plans from './modules/plans/index.js'
import distributor from './modules/distributor/index.js'
import client from './modules/client/index.js'

export default { 
	modules: {
		system,
        simulator,
		consultant,
        budget,
        order,
        brand,
        installer,
        plans,
        distributor,
        client
	}
}
