import Http from '@/plugins/Http'

export default {
    async getAllWithProducts ({}) {
        const { data } = await Http.get('/brand/list/getAllWithProducts')
        return data
    },
    
    async loadBrands ({}) {
        const { data } = await Http.get('/brand')
        return data
    }
}
