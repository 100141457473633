<template>
    <v-row>
        <v-col cols="12" v-if="isLoading">
            <div class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
        </v-col>
        
        <v-col v-else cols="12" md="10" class="mx-auto"> 
            <h1 class="text-h4 mt-5 font-weight-bold">Detalhe do orçamento</h1>
            <span class="d-block">N.{{ order.custom_id }}</span>

            <div class="text-right mt-10" v-if="getUser.user_type == 4">

                <v-btn class="orange white--text elevation-0" v-if="checkEnviado(order) && !order.installer_id" @click="$router.push('/budget-installer/edit/' + getEnviado(order))">
                    Editar orçamento {{ order.installer_id }}
                </v-btn>
                
                
                <template v-else>
                    <v-btn class="orange white--text elevation-0" v-if="checkSend() && !checkRecusado()" @click="$router.push('/budget/send/' + $route.params.id)">
                        Enviar orçamento
                        <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                </template>
                


               
            </div>

            <OrderDetails 
                :order="order" 
                :bottomActions="true" 
                @send="$router.push('/budget/send/' + $route.params.id)"
            />
        </v-col>
    </v-row>
</template>



<script>
    
    import { mapActions, mapGetters } from 'vuex'
    
    import OrderDetails from "@/Views/OrderDetails.vue"
    import dayjs from "dayjs";
    
    export default {
        components:{
            OrderDetails
        },
        data()
        {
            return {
                isLoading: true,
                images: Array(3).fill(1),
                order: null
            }
        },
        
        methods: {
            ...mapActions(['showOrder']),

            checkRecusado () {
                const user = this.getUser

                if (user.installer) {
                    if (this.order && this.order.installer_budgets && this.order.installer_budgets.rejected_at) {
                        return true
                    }
                }
                return false

            },
            

            checkSend () {
                if (this.order) {
                    // this.getPrazo(this.order) <= 0 ||
                    if (this.order.budget_installer || this.checkEnviado(this.order)) {
                        return false
                    }
                } else {
                    return false
                }
                
                return true
                
            },


            getEnviado (item) {
                const user = this.getUser

                if (item.installer_id) {
                    return true
                } else {

                    if (user.installer) {
                        for (let budget of item.budget_installers) {
                            if (budget.installer_id == user.installer.id) {
                                return budget.id
                            }
                        }
                    }



                }
                
                return false
            },


            checkEnviado (item) {
                const user = this.getUser

                if (item.installer_id) {
                    return true
                } else {

                    if (user.installer) {
                        for (let budget of item.budget_installers) {
                            if (budget.installer_id == user.installer.id) {
                                return true
                            }
                        }
                    }


                    return false
                }
            },

            getPrazo (item) {
                let created_at = item.created_at
                let vencimento = dayjs(created_at).add(3, 'days')


                const days = vencimento.diff(dayjs(), 'days') // 7

                return days
            },
        },
        
        async mounted () {
            this.isLoading = true
            const r = await this.showOrder(this.$route.params.id)

            console.log('order', r)
            
            if (r) {
                this.order = r
            }
            this.isLoading = false
        },
        
        computed: {
            ...mapGetters(['getUser'])
        }
        
    }
</script>
