import Http from '@/plugins/Http'

import axios from 'axios'

import Cookies from 'js-cookie'

export default {
    async login({ commit }, credentials) 
    {
        try
        {
            let { data } = await Http.post('/login', credentials)

            if (data.status)
            {

                const dataUser = {
                    name: data.name,
                    email: data.email,
                    consultant_id: data.consultant_id,
                    user_type: data.user_type ? parseInt(data.user_type) : data.user_type,
                    installer: data.installer ? data.installer : null
                }

                commit('SET_USER', dataUser)
                commit('SET_TOKEN', `${data.type} ${data.token}`)


                const api = Cookies.withAttributes({ path: '/', domain: '.marketsolar.com.br' })
                let cookieProperty = {
                    domain: '.marketsolar.com.br', // .  while my project is on localhost:3000 or def.com,
                    secure: true,
                    path: '/'
                }

                Cookies.set('token_marketsolar', `${data.type} ${data.token}`, { domain: ".marketsolar.com.br" })

                Cookies.set('data_user', JSON.stringify(dataUser), { domain: ".marketsolar.com.br" })


                let iniciais = '';

                try
                {
                    const fullName = data.name.split(' ');
                    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                    iniciais = initials.toUpperCase();
                } catch (e)
                {
                    iniciais = data.name.substring(0, 2).toUpperCase()
                }
                Cookies.set('iniciais_marketsolar', iniciais, { domain: ".marketsolar.com.br" })

                return true
            }
        } catch (e)
        {
            return false
        }
    },

    logout({ commit })
    {
        localStorage.clear()

        Cookies.remove('iniciais_marketsolar', { path: '', domain: '.marketsolar.com.br' })
        Cookies.remove('token_marketsolar', { path: '', domain: '.marketsolar.com.br' })


        commit('CLEAR_USER', {
            name: '',
            email: '',
            consultant_id: null
        })
        commit('CLEAR_TOKEN', null)
    },

    async sendCreateAccount({ commit }, user)
    {
        try
        {
            let { data } = await Http.post('/register', user)
            if (data)
            {
                commit('SET_USER', { name: data.user.name, email: data.user.email, user_type: data.user.type ? parseInt(data.user.type) : data.user.type, installer: data.installer ? data.installer : null })
                commit('SET_TOKEN', `${data.token.type} ${data.token.token}`)

                return { status: true, data: data }
            }
        } catch (e)
        {
            return { status: false, error: e.response && e.response.data && e.response.data.error ? e.response.data.error : 'Erro ao criar conta' }
        }

    },

    async sendRestorePassword({ commit }, credentials)
    {
        try
        {
            await Http.post('/sendRestorePassword', credentials)
            return true
        } catch (e)
        {
            return false
        }
    },

    async checkUserLogged({ commit })
    {
        try
        {
            await Http.post('/checkUserLogged')
            return true
        } catch (e)
        {
            return false
        }
    },

    async setStepSimulation({ commit }, step)
    {
        commit('SET_STEP_SIMULATION', step)
    },

    async checkEmailRegister({ }, params)
    {
        try
        {
            const { data } = await Http.get('/checkEmailRegister', { params })
            return data.exists
        } catch (e)
        {
            return false
        }
    },

    async cityOrState({ commit }, val)
    {

        try
        {
            const { data } = await Http.get('/cityOrState', { params: { query: val } })

            return data
        } catch (e)
        {
            return { count: 0, entries: [] }
        }
    },

    async checkTokenReset({ commit }, token)
    {
        try
        {
            await Http.get('/checkTokenReset/' + token)
            return true
        } catch (e)
        {
            return false

        }
    },

    async restorePassword({ commit }, credentials)
    {
        try
        {
            await Http.post('/restorePassword', credentials)
            return true
        } catch (e)
        {
            return false
        }
    },



    async getAddres({ }, address)
    {
        try
        {
            const { data } = await axios.get("https://maps.google.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=" + process.env.VUE_APP_GOOGLE_MAPS_API_KEY)

            return data
        }
        catch (e)
        {
            console.log('ERRO', e)
            return false
        }


    },


    async getCities({ commit }, val)
    {
        try
        {
            const { data } = await Http.get('/city', { params: { query: val } })
            return data
        } catch (e)
        {
            return false

        }
    },

    async myNotifications()
    {
        try
        {
            const { data } = await Http.get('/my-notifications')
            return data
        } catch (e)
        {
            return []
        }
    },

    async getDocumentBudget({ }, id)
    {
        try
        {
            const { data } = await Http.get(`/documentBudget/${id}/showFile`);
            return data;
        } catch (e)
        {
            return false;
        }
    },


}