<template>
    <v-container class="px-10" fluid>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div style="width: 100%; height: 100%" v-else>

            <template v-if="items.length > 0">
                <h2 class="mb-10 mt-10" style="font-size: 40px;">Orçamentos</h2>
    
                <v-card 
                    class="mt-8 elevation-1 hovercard" 
                    v-for="(item, indexItem) in items" 
                    :key="indexItem" 
                    @click="showBudget(item)" 
                    :style="`border-left:3px solid ${getStatusOrders(item).color}`"
                >
                    <v-simple-table class="cardDesktop">
                        <template v-slot:default>
                            <tbody>
                                <tr class="hovercard">
                                    <td>
                                        <p>Status</p>
                                        <p 
                                            class="text--darken-4" 
                                            :style="`max-width:100px; color: ${getStatusOrders(item).color} !important`"
                                        >
                                            {{ getStatusOrders(item).title }}
                                        
                                        </p>
                                    </td>
    
                                    <td>
                                        <p>Consultor</p>
                                        <p>
                                            <v-avatar v-if="item.budget && item.budget.consultant && item.budget.consultant.url"  size="32" style="background: #002A53;">
                                                <img
                                                    :src="item.budget.consultant.url" alt="avatar"
                                                >
                                            </v-avatar>
                                            <v-avatar v-else size="32" style="background: #002A53;"><span class="white--text" style="font-size: 20px;">{{ getIniciais(item.budget.consultant.name) }}</span></v-avatar>
                                            {{ item.budget && item.budget.consultant ? item.budget.consultant.name : '-' }}
                                            <a
                                                :href="formatPhoneNumber(item.budget.consultant.phone)"
                                                targe="_blank"
                                            >
                                                <img
                                                    src="https://www.jungnapratica.com.br/wp-content/uploads/2019/05/icon-whatsApp.png"
                                                    alt="whatsapp icon"
                                                    style="height: 40px; width: 40px;"
                                                >
                                            </a>
                                        </p>
                                    </td>
                                    
                                    <td>
                                        <p>Endereço</p>
                                        <p class="truncate-text">{{ item.budget && item.budget.simulator ? item.budget.simulator.address : '-'}}</p>
                                    </td>
    
                                    <td>
                                        <p>Valor estimado</p>
                                        <p>R$ {{ _formatMoney(item.budget && item.budget.simulator ? item.budget.simulator.budget_total : '-') }}</p>
                                    </td>
    
                                    <td>
                                        <p>Capacidade de geração</p>
                                        <p>{{ item.budget && item.budget.simulator ? item.budget.simulator.monthly_generated_energy : '-' }} kWh</p>
                                    </td>
    
                                    <td v-if="getUser.user_type == 3 && item.budget_installers">
                                        <p>Orçamentos enviados</p>
                                        <v-avatar
                                            size="31"
                                            v-for="(b,i) in item.budget_installers"
                                            :key="i"
                                            class="mr-1"
                                        >
                                            <img
                                                :src="b.installer.url"
                                                alt="John"
                                            >
                                        </v-avatar>
    
                                        <template v-if="item.budget_installers.length < 5">
                                            <v-avatar
                                                v-if="item.budget_installers"
                                                size="31"
                                                v-for="(b,i) in 5 - item.budget_installers.length"
                                                :key="i"
                                                color="#BFBEC6"
                                                class="mr-1"
                                            >

                                            </v-avatar>
                                        </template>
                                        
                                    </td>
                                    
                                    <td v-if="getUser.user_type == 4">
                                        
                                        <template>
                                            <template v-if="!checkEnviado(item)">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <p v-bind="attrs" v-on="on">Prazo para envio <v-icon small>mdi-help-circle-outline</v-icon></p>
                                                        <p v-bind="attrs" v-on="on" :style="`${getPrazo(item) > 0 ? 'color: #0066CC' : 'color: #F50058'}`">Até {{getPrazoDate(item)}}</p>
                                                    </template>
                                                    <span>Propostas enviadas dentro do prazo aumentam a possibilidade de conversão junto ao cliente final.</span>
                                                </v-tooltip>
                                            </template>
                                        </template>
                                       
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="cardMobile pa-3">
                        <p
                            class="text--darken-4"
                            :style="`max-width:100%; color: ${getStatusOrders(item).color} !important`"
                        >
                            {{ getStatusOrders(item).title }}

                        </p>

                        <template v-if="getUser.user_type == 3 && item.budget_installers">
                            <v-avatar
                                size="31"
                                v-for="(b,i) in item.budget_installers"
                                :key="i"
                                class="mr-1"
                            >
                                <img
                                    :src="b.installer.url"
                                    alt="John"
                                >
                            </v-avatar>


                            <template v-if="item.budget_installers.length < 5">
                                <v-avatar
                                    v-if="item.budget_installers"
                                    size="31"
                                    v-for="(b,i) in 5 - item.budget_installers.length"
                                    :key="i"
                                    color="#BFBEC6"
                                    class="mr-1"
                                >

                                </v-avatar>
                            </template>
                        </template>
                        <v-divider class="mt-5"></v-divider>


                        <p class="mt-5">
                            <p style="color: #8A8797;">Consultor</p>
                            <v-avatar v-if="item.budget && item.budget.consultant && item.budget.consultant.url"  size="32" style="background: #002A53;">
                                <img
                                    :src="item.budget.consultant.url" alt="avatar"
                                >
                            </v-avatar>
                            <v-avatar v-else size="32" style="background: #002A53;"><span class="white--text" style="font-size: 20px;">{{ getIniciais(item.budget && item.budget.consultant ? item.budget.consultant.name : '-') }}</span></v-avatar>
                            {{ item.budget && item.budget.consultant ? item.budget.consultant.name : '-' }}
                        </p>
                        
                        <p class="mb-0 mt-5" style="color: #8A8797;">Endereço</p>
                        <p>{{ item.budget && item.budget.simulator ? item.budget.simulator.address : '-'}}</p>


                        <p class="mb-0 mt-5" style="color: #8A8797;">Capacidade de geração</p>
                        <p>{{ item.budget && item.budget.simulator ? item.budget.simulator.monthly_generated_energy : '-' }} kWh</p>

                        
                        <p class="mb-0 mt-5" style="color: #8A8797;">Valor estimado</p>
                        <p>R$ {{ _formatMoney(item.budget && item.budget.simulator ? item.budget.simulator.budget_total : '0') }}</p>
                        
                    </div>
                </v-card>
            </template>

            <div v-else class="d-flex justify-center align-center align-content-center flex-column" style="width: 100%; height: 100%">
                <img src="/images/inbox-2.svg">
                <p class="text-center mt-3 textNoData">Ops, não há <b>Orçamentos</b> <br> para apresentar aqui...</p>
            </div>
            
        </div>

        <v-row class="mt-5" v-if="items.length > 0">
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    import dayjs from 'dayjs'

    import { getResidenceType, formatMoney } from './../../utils/Helpers'


    export default {
        metaInfo: {
            title: 'Orçamentos - Market Solar',
        },
        data()
        {
            return {
                dialog:false,
                isLoading: false,
                items: [],
                page: 1,
                totalPage: 1,
                sendBudgetExpire: null
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            ...mapActions(['listOrders']),

            getSrc( src )
            {
                return `${process.env.VUE_APP_API_URL}${src}`
            },

            formatPhoneNumber(phoneNumber) {

                const formatedPhoneNumber = phoneNumber.replace('(','').replace(')','').replace(' ','').replace('-','');
                const whatsAppApiLink = `https://api.whatsapp.com/send?phone=55${formatedPhoneNumber}`;

                return whatsAppApiLink;
            },

            async load (page = 1) {
                this.isLoading = true

                const filter = {}
                filter.page = page

                const resp = await this.listOrders(filter)

                if (resp) {

                    this.items = resp.data
                    this.totalPage = resp.last_page

                }

                console.log('items', this.items)

                this.isLoading = false;
            },

            _dayJs (data) {
                return dayjs(data)
            },

            _getResidenceType (id) {
                return getResidenceType(id)
            },

            _formatMoney (value) {
                return formatMoney(value)
            },
            
            showBudget (item) {
                if (this.getUser.user_type == 4) {
                    this.$router.push('/budget/details/' + item.id)
                } else if (this.getUser.user_type == 3) {
                    this.$router.push('/budget/details/' + item.id + '/budget-installers')
                }
                
            },

            getPrazo (item) {
                let created_at = item.created_at
                let vencimento = dayjs(created_at).add(3, 'days')
                this.sendBudgetExpire = vencimento.format('DD/MM/YYYY')

                const days = vencimento.diff(dayjs(), 'days') // 7
                
                return days
            },


            getPrazoDate (item) {
                let created_at = item.created_at
                let vencimento = dayjs(created_at).add(3, 'days')
                return vencimento.format('DD/MM/YYYY')
            },


            checkEnviado (item) {
                const user = this.getUser
                
                if (item.installer_id) {
                    return true
                } else {
                    
                    if (user.installer) {
                        for (let budget of item.budget_installers) {
                            if (budget.installer_id == user.installer.id) {
                                return true
                            }
                        }
                    }
                    
                    
                   
                }
            },
            
            
            getStatusOrders (item) {
                const user = this.getUser

                if (user.installer) {
                    if (item.installer_id && item.installer_id != user.installer.id) {
                        return {title: 'Declinado pelo cliente', 'color': '#F50058' }
                    }



                    if (user.installer) {
                        if (item.installers_selected) {
                            for (let ins of item.installers_selected) {
                                if (ins.installer_id == user.installer.id) {
                                    if (ins.rejected_at) {
                                        return { title: 'Oportunidade recusada', color: '#f50058' }
                                    }

                                    if(this.checkEnviado(item) && user.user_type == 4){
                                        return {title: 'Aprovação pendente' , 'color': '#0066CC' }
                                    }
                                }
                            }
                        }
                    }
                    
                    
                }
                
                
                
                
                
                
                if (item.installer_id) {
                    return { title: 'Orçamento aprovado', 'color': '#219653' }
                } else if (item.budget_installers && item.budget_installers.length == 5) {
                    return { title: 'Aprovar orçamento', 'color': '#0066CC' }
                } else if (!this.checkEnviado(item) && this.getPrazo(item) <= 0) {
                    return {title: 'Aguardando orçamentos' , 'color': '#0066CC' }
                } else 
                    
                    if (item.budget_installers && item.budget_installers.length < 5 || this.getPrazo(item) > 0) {
                    return { title: 'Aguardando orçamentos' , 'color': '#0066CC', prazo: true}
                } else {
                    return {title: '-', 'color': '#F50058' }
                }
            },

            getIniciais (nameString) {
                if(!nameString){
                    return ""
                }
                try {
                    const fullName = nameString.split(' ');
                    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                    return initials.toUpperCase();
                } catch (e) {
                    return nameString.substring(0,2).toUpperCase()
                }
            },
        },

        computed: {
            ...mapGetters(['getUser'])
        }
    }
</script>

<style scoped>
    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
        border: none !important;
    }
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
        border: none !important;
    }
    main{
        background-color: #fafafa !important;
    }

    .hovercard:hover {
        background: #F5F9FE !important;
    }
    .cardMobile {
        display: none;
    }

    .cardDesktop {
        display: block;
    }
    
    .hovercard td{
        width:200px;
        padding:20px !important;
    }

    .v-tooltip__content{
        width: 360px !important;
        padding: 12px 20px !important;
        background-color: #002A53 !important;        
    }
    @media screen and (max-width: 960px) {

        .cardMobile {
            display: block !important;
        }

        .cardDesktop {
            display: none !important;
        }
    }
</style>