<template>
    <v-row>
        <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600" persistent>
            <v-card>
                <v-card-text class="pt-4">
                    <svg class="d-block mx-auto" width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M73.5 137.813C86.2198 137.813 98.654 134.041 109.23 126.974C119.806 119.907 128.049 109.863 132.917 98.1113C137.785 86.3598 139.058 73.4287 136.577 60.9533C134.095 48.4779 127.97 37.0185 118.976 28.0242C109.982 19.03 98.5221 12.9048 86.0467 10.4233C73.5713 7.94176 60.6402 9.21537 48.8887 14.083C37.1371 18.9507 27.0929 27.1938 20.0261 37.7699C12.9594 48.3461 9.1875 60.7802 9.1875 73.5C9.20663 90.5509 15.9885 106.898 28.0453 118.955C40.1021 131.011 56.4491 137.793 73.5 137.813ZM73.5 15.3125C85.0084 15.3125 96.2584 18.7252 105.827 25.1189C115.396 31.5126 122.854 40.6003 127.258 51.2326C131.662 61.865 132.815 73.5646 130.569 84.8518C128.324 96.1391 122.782 106.507 114.645 114.645C106.507 122.782 96.1391 128.324 84.8518 130.569C73.5646 132.815 61.865 131.662 51.2326 127.258C40.6002 122.854 31.5126 115.396 25.1189 105.827C18.7251 96.2584 15.3125 85.0084 15.3125 73.5C15.33 58.0731 21.4661 43.2831 32.3746 32.3746C43.2831 21.4661 58.0731 15.3301 73.5 15.3125ZM52.9597 75.6653C52.4018 75.0877 52.0931 74.3141 52.1001 73.5112C52.1071 72.7082 52.4292 71.9401 52.997 71.3723C53.5648 70.8045 54.3329 70.4824 55.1359 70.4754C55.9389 70.4684 56.7125 70.7771 57.2901 71.335L67.375 81.4196L89.7097 59.0849C90.2873 58.527 91.0609 58.2184 91.8639 58.2253C92.6668 58.2323 93.4349 58.5544 94.0028 59.1222C94.5706 59.69 94.8927 60.4581 94.8996 61.2611C94.9066 62.0641 94.5979 62.8377 94.0401 63.4153L69.5401 87.9153C69.2558 88.1997 68.9182 88.4253 68.5467 88.5792C68.1752 88.7331 67.777 88.8123 67.3749 88.8123C66.9727 88.8123 66.5746 88.7331 66.203 88.5792C65.8315 88.4253 65.494 88.1997 65.2097 87.9153L52.9597 75.6653Z" fill="#FFA900" stroke="#FFA900" stroke-width="0.5"/> </svg>
                    <h1 class="text-center mt-5 text-h6" >Parabéns, sua empresa irá crescer muito mais com a Market Solar! </h1>
                    <span style="width:330px" class="d-block mx-auto text-center mb-5 caption">Aproveite ao máximo nossas ferramentas e consultores!</span>
                    <v-btn style="width:330px" @click="handleModalClick" small class="orange white--text d-block mx-auto mt-10">Ok</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-col cols="12">
            <div class="text-center" v-if="isLoadingInit">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
            
            
            <v-col :cols=" (plan === null) ? 12 : 10" class="mx-auto mx-lg-0" v-else>
                <PlansCards v-if="plan === null" :plans="plans" @setPlan="plan=$event" />

                <v-col cols="11" class="pa-0" v-else>
                    <v-navigation-drawer width="296" absolute permanent fixed right class="orange lighten-3 d-none d-lg-block pa-6" v-if="true">
                        <div class="text-right ma-3">
                            <span @click="plan=null" class="font-weight-bold" style="color: #004D99;text-decoration-line: underline;">Alterar</span>
                        </div>

                        <div style="font-size: 18px;">
                            <span class="caption d-none">Seu plano</span>
                            <div class="d-flex justify-space-between">
                                <span class="mb-3 mt-10">{{plans[plan].name}}</span>
                                <span class="caption d-none" >{{plans[plan].price}}</span>
                            </div>
                            <v-divider class="mt-3 mb-3 d-none"></v-divider>
                            <div class="d-flex justify-space-between">
                                <span style="font-size: 20px;">R$ {{__formatMoney(plans[plan].price)}}</span>
                            </div>

                            <v-alert v-if="type!==1" class="mt-5 white--text darken-2 caption" color="blue">* O plano terá duração de {{plans[plan].days}} <strong>{{ plans[plan].days / 30 }} mes(es)</strong>. Para continuar desfrutando dos benefícios da Market Solar será  preciso realizar a renovação do plano no dia <strong>{{ getPrazo( plans[plan].days ) }}.</strong></v-alert>
                        </div>
                    </v-navigation-drawer>

                    <div class="pa-3 orange lighten-3 d-lg-none mdi-rounded-lg">
                        <div class="text-right ma-3">
                            <span @click="plan=null">Alterar</span>
                        </div>

                        <div class="ma-3">
                            <span class="caption">Seu plano</span>
                            <div class="d-flex justify-space-between">
                                <span class="font-weight-regular">{{plans[plan].name}}</span>
                                <span class="caption">{{plans[plan].price}}</span>
                            </div>
                            <v-divider class="mt-3 mb-3"></v-divider>
                            <div class="d-flex justify-space-between">
                                <span>Valor Total</span>
                                <span>{{__formatMoney(plans[plan].price)}}</span>
                            </div>

                            <v-alert v-if="type!==1" class="mt-5 white--text darken-2 caption" color="blue">* O plano terá duração de <strong>{{ plans[plan].days / 30 }} mes(es)</strong>. Para continuar desfrutando dos benefícios da Market Solar será  preciso realizar a renovação do plano no dia <strong>{{ getPrazo( plans[plan].days ) }}.</strong></v-alert>
                        </div>
                    </div>

                    <Payment v-if="form===null" :selected="type" @setType="type=$event" />


                    <v-row v-if="form===null">
                        <v-col cols="12" class="mt-10 mb-10 mx-auto d-flex justify-space-between">

                            <v-btn @click="plan = null"><v-icon>mdi-arrow-left</v-icon> Voltar</v-btn>

                            <v-btn @click="onCreatePayment" color="#002A53" class="white--text no-uppercase" :loading="isLoadingPayment" :disabled="isLoadingPayment">Continuar <v-icon small>mdi-arrow-right</v-icon> </v-btn>
                        </v-col>
                    </v-row>

                    <PaymentCardForm v-if="form==='card'" @success="dialog=true" :plan="plans[plan]" />
                    <PaymentBilletForm v-if="form==='billet'" :billet="billet" @success="dialog=true" />
                    <PaymentPixForm v-if="form==='pix'" @success="dialog=true" />
                </v-col>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
    
    import { mapActions } from 'vuex'
    import dayjs from 'dayjs'
    import PlansCards from "@/Views/PlansCards"
    import Payment from "@/Views/Payment"
    import PaymentCardForm from "@/Views/PaymentCardForm"
    import PaymentPixForm from "@/Views/PaymentPixForm"
    import PaymentBilletForm from "@/Views/PaymentBilletForm"
    import {formatMoney} from "../../utils/Helpers";


    export default {
        name: 'Plans',
        components:{
            PlansCards, Payment, PaymentCardForm, PaymentPixForm, PaymentBilletForm
        },
        data(){
            return {
                isLoadingInit: true,
                type:0,
                plan:null,
                form:null,
                dialog:false,
                plans:[
                    { 
                        name:"Gratuito", 
                        price: 0,
                        legend:"-",
                        type:"-", 
                        details:"Acesso livre", 
                        items:[ "Seu perfil no site", "Acesso direto à distribuidores ", "Receba pedidos de orçamento", "Consultoria completa", "Acompanhamento on-line e presencial" ] 
                    }
                ],

                isLoadingPayment: false,
                billet: null
            }
        },
        
        async mounted () {
            this.dialog = this.$route.query.init || false

            this.isLoadingInit = true
            const plans = await this.getPlans()
            if (plans) {
                for (let plan of plans) {
                    plan.price = plan.price / 100
                    plan.items = [ "Todos os benefícios do Plano Standard e mais...", "Sua empresa no topo da busca", "Prioridade para receber pedidos de orçamento" ]
                    this.plans.push(plan)
                }
            }

            if (this.$route.query.type && this.$route.query.plan_id) {
                
                if (this.$route.query.type == 'billet') {
                    const transation = await this.getTransaction({id: this.$route.query.billet_id, type: 'billet'})
                    if (transation) {
                        this.billet = transation
                    }
                }
                
                
                this.form = this.$route.query.type;
                for (let i in this.plans) {
                    if (this.plans[i].id == this.$route.query.plan_id) {
                        this.plan = i
                    }
                }
            }

            this.isLoadingInit = false
        },
        methods: {
            ...mapActions(['getPlans', 'createBillet', 'getTransaction', 'createTransactionPix']),
            __formatMoney (value) {
                return formatMoney(value)
            },
            handleModalClick()
            {
                if(this.plan)
                {
                    this.$router.push('/notifications')
                }
                else this.dialog = false
            },
            async onCreatePayment () {
                this.isLoadingPayment = true
                if (this.type === 0) {
                    // card
                    const query = Object.assign({}, this.$route.query)
                    query.type = 'card'
                    query.plan_id = this.plans[this.plan].id
                    await this.$router.push({ query })
                    this.form = 'card'
                    
                } else if (this.type === 1) {
                    const r = await this.createTransactionPix({plan_id: this.plans[this.plan].id})
                    
                    
                    return
                    if (r) {
                        const query = Object.assign({}, this.$route.query)
                        query.type = 'billet'
                        query.plan_id = this.plans[this.plan].id
                        query.billet_id = r.id
                        this.billet = r
                        await this.$router.push({ query })
                        this.form = 'billet'
                    }
                    
                    
                    
                } else {
                    const r = await this.createBillet({plan_id: this.plans[this.plan].id})
                    
                    if (r) {
                        const query = Object.assign({}, this.$route.query)
                        query.type = 'billet'
                        query.plan_id = this.plans[this.plan].id
                        query.billet_id = r.id
                        this.billet = r
                        await this.$router.push({ query })
                        this.form = 'billet'
                    } else {
                        this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao gerar boleto'})
                    }
                    
                }
                this.isLoadingPayment = false
                //form = type === 0 ? 'card' : type === 1 ? 'pix' : 'billet'
            },
            getPrazo( days )
            {
                return dayjs().add(days, 'days').format("DD/MM/YYYY")
            }
        }
    }
</script>

<style scoped>
    body{
        background-color: #fafafa !important;
    }
</style>