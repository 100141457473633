var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center mt-10"},[_vm._v("Escolha o seu plano")]),_c('div',{staticClass:"d-flex flex-wrap mt-10 mb-10 justify-center"},_vm._l((_vm.plans),function(p,i){return _c('v-card',{key:i,class:{
                'elevation-0 pa-5 pt-1 mr-2 pb-10 mt-3 card-view': true,
                active: i === 3,
            },attrs:{"max-width":"275"}},[_c('div',{staticClass:"pa-5 mr-2 pb-10 mt-3"},[_c('h1',{staticClass:"d-block text-h6 mb-3"},[_vm._v(_vm._s(p.name))]),_c('span',{staticClass:"d-block text-h5 font-weight-bold"},[_vm._v("R$ "+_vm._s(_vm.__formatMoney(p.price)))]),_c('small',{class:{
                        'd-block mb-5 caption': true,
                        'white--text': !!!p.legend || p.legend === '-',
                    }},[_vm._v(_vm._s(p.legend || "-"))]),_c('span',{class:{
                        'mt-10 d-block caption font-weight-medium': true,
                        'white--text': !!!p.type || p.type === '-',
                    }},[_vm._v(_vm._s(p.type || "-"))]),(parseInt(p.price) > 0)?_c('span',[_vm._v("Pagamento único")]):_vm._e(),_c('span',{class:{
                        'd-block caption': true,
                        'white--text': !!!p.details || p.details === '-',
                    }},[_vm._v(_vm._s(p.details || "-"))]),_c('hr',{staticClass:"mt-5 mb-5"}),_vm._l((p.items),function(item,ii){return _c('span',{key:ii,staticClass:"d-block caption grey--text text--darken-2"},[_c('svg',{attrs:{"width":"4","height":"4","viewBox":"0 0 4 4","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"2","cy":"2","r":"2","fill":"#FFA900"}})]),_vm._v(" "+_vm._s(item))])}),_c('v-spacer',{staticClass:"mt-10"}),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"no-uppercase white--text mt-5 pl-5 pr-5",staticStyle:{"position":"absolute","bottom":"20px","font-size":"16px"},attrs:{"color":"#002A53","rounded":"","bottom":""},on:{"click":function($event){return _vm.setPlan(i)}}},[_vm._v("Quero este plano")])],1)],2)])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }