import { ValidatorPrivateRoute, ValidatorRouteLogin } from "../utils/Auth";

import DashboardPage from "@/pages/Dashboard.vue";
import SimulatorPage from "@/pages/Simulator/Index.vue";
import CreateAccountPage from "@/pages/CreateAccount/Index.vue";

import RegisterStepsPage from "@/pages/CreateAccount/Steps.vue";

import LoginPage from "@/pages/Login/Index.vue";
import ResetPage from "@/pages/Login/Reset.vue";
import FormRestorePassword from '@/pages/Login/FormRestorePassword'


import ResultPage from "@/pages/Result/Index.vue";
import TalkToConsultantPage from "@/pages/TalkToConsultant/Index.vue";
import NotFound from "@/pages/NotFound.vue";

import BudgetsPage from "@/pages/Budgets/Index";
import BudgetDetails from "@/pages/Budgets/Details";
import BudgetInstallers from "@/pages/Budgets/IndexInstallers";
import BudgetEditInstallers from "@/pages/Budgets/EditInstaller";

import SendBudget from "@/pages/Budgets/Send";
import SentBudget from "@/pages/Budgets/Sent";

import OrdersPage from "@/pages/Orders/Index";
import OrderDetails from "@/pages/Orders/Details";
import ProfilePage from "@/pages/Profile/Index";
import ProfileClientPage from "@/pages/Profile/Client";

import SimulationsPage from "@/pages/Simulations/Index";
import DistributorsPage from "@/pages/Distributors/Index";
import NotificationsPage from "@/pages/Notifications/Index";
import CompanyPage from "@/pages/Company/Index";

import  DistributorPage from "@/pages/Distributor/Index"

import PlansIndexPage from "@/pages/Plans/Index";


import Logout from '@/pages/Login/Logout'

export default {
    base: __dirname,
    //mode: 'history',
    routes: [
        {
            path: "/logout",
            name: "logout",
            component: Logout,
            //beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: DashboardPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/",
            name: "simulator",
            component: SimulatorPage,
            //beforeEnter: ValidatorPrivateRoute
        },
        {
            path: "/login",
            name: "login",
            component: LoginPage,
            beforeEnter: ValidatorRouteLogin,
        },
        {
            path: "/reset",
            name: "reset",
            component: ResetPage,
            //beforeEnter: ValidatorRouteLogin,
        },
        {
            path: "/createaccount",
            name: "createaccount",
            component: CreateAccountPage,
        },
        {
            path: "/register-steps",
            name: "register-steps",
            component: RegisterStepsPage,
        },
        {
            path: "/result-simulator/:id",
            name: "result-simulator",
            component: ResultPage,
            // beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/talktoconsultant",
            name: "talktoconsultant",
            component: TalkToConsultantPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/budgets",
            name: "budgets",
            component: BudgetsPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/budget/sent/:id",
            name: "budget-sent",
            component: SentBudget,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/budget/send/:id",
            name: "budget-send",
            component: SendBudget,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/budget/details/:id",
            name: "budget-details",
            component: BudgetDetails,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/budget/details/:id/budget-installers",
            name: "budget-details-installers",
            component: BudgetInstallers,
            beforeEnter: ValidatorPrivateRoute,
        },

        {
            path: "/budget-installer/edit/:id",
            name: "budget-details-installers",
            component: BudgetEditInstallers,
            beforeEnter: ValidatorPrivateRoute,
        },
        
        {
            path: "/orders",
            name: "orders",
            component: OrdersPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/orderdetails/:id",
            name: "orderdetails",
            component: OrderDetails,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/profile",
            name: "profile",
            component: ProfilePage,
            beforeEnter: ValidatorPrivateRoute,
        },

        {
            path: "/client/profile",
            name: "profile-client",
            component: ProfileClientPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        
        {
            path: "/simulations",
            name: "simulations",
            component: SimulationsPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/distributors",
            name: "distributors",
            component: DistributorsPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/notifications",
            name: "notifications",
            component: NotificationsPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/company/profile",
            name: "company-profile",
            component: CompanyPage,
            beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: "/distributor/profile",
            name: "distributor-profile",
            component: DistributorPage,
            beforeEnter: ValidatorPrivateRoute,
        },    
        {
            path: "/plans",
            name: "plans",
            component: PlansIndexPage,
            // beforeEnter: ValidatorPrivateRoute,
        },
        {
            path: '/reset-password/:token',
            name:'reset-password',
            component: FormRestorePassword
        },
        
        { path: "*", name: "404", component: NotFound },
    ],
};
