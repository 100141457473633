<template>
    
</template>


<script>
    import {mapActions} from "vuex";

    export default {
        metaInfo: {
            title: "Saindo - Market Solar",
        },
        name: "Logout",

        methods: {
            ...mapActions(["logout"]),
        },
        
        async mounted() {
            await this.logout()
            this.$router.push("/login");
        }
    }
</script>