import Http from '@/plugins/Http'

export default {
    async getPlans ({}) {
        try {
            const { data } = await Http.get('/plans')
            
            return data
        } catch (e) {
            return false
        }
    },
    
    
    
    
    
    
    // Payments
    
    async createBillet ({}, plan) {
        try {
            const { data } = await Http.post('/createBillet', plan)

            return data
        } catch (e) {
            return false
        }
    },


    async createTransactionCard ({}, plan) {
        try {
            const { data } = await Http.post('/createTransactionCard', plan)

            return data
        } catch (e) {
            return false
        }
    },

    async createTransactionPix ({}, plan) {
        try {
            const { data } = await Http.post('/createTransactionPix', plan)

            return data
        } catch (e) {
            return false
        }
    },
    
    
    async getTransaction ({}, params) {
        try {
            const { data } = await Http.get('/getTransaction', {params})

            return data
        } catch (e) {
            return false
        }
    }



    
}
