import store from "../../index";

export default {
    getUser: state => state.user,
    getToken: state => state.token,
    getActiveStep: state => state.step,
    
    isLogged: state => {
        return !state.token || !state.user ? false : true
    }
}