import Http from '@/plugins/Http'

export default {

    async getSimulator ({}, params) {
        const { data } = await Http.get('/simulator', { params })
        return data
    },
    
    
    async insertSimulator ({}, simulator) {
        const { data } = await Http.post('/simulator', simulator)
        return data
    },

    async updateSimulator ({}, simulator) {
        const { data } = await Http.put('/simulator/' + simulator.id, simulator)
        return data
    },

    async showSimulator ({}, id) {
        try {
            const { data } = await Http.get('/simulator/' + id)
            return data
        } catch (e) {
            return false
        }
    },

    async showSimulatorConsultant ({}, params) {
        try {
            const { data } = await Http.get('/simulator/' + params.id, {params})
            return data
        } catch (e) {
            return false
        }
    },

    async showSimulatorComplete ({}, id) {
        try {
            const { data } = await Http.get('/simulator/' + id, { params: { complete: true } })
            return data
        } catch (e) {
            return false
        }
    },

    async insertDocuments ({}, {simulator_id, documents}) {
        const { data } = await Http.post(`/documents/simulator/${simulator_id}`, documents)
        return data
    },
}
