<template>
    <v-row>
        <template v-if="!installer.ratings || installer.ratings.length == 0">
            <v-col cols="12" class="text-center">
                <img src="/images/icon-star.svg">
                <p class="titleSem mt-5 mb-0">Sua empresa ainda não recebeu avaliações</p>
                <!--<p class="textSem mt-0">Estamos aqui para ajudar a encontrar a melhor empresa</p>-->
            </v-col>
        </template>
        
        <template v-else>
            <v-col cols="12" md="6" class="mx-auto mb-5">
                <v-row>
                    <v-col cols="12" md="6" class="text-center text-md-right">
                        <span class="text-h2 font-weight-regular">{{ getMedia(10) }}</span>
                        <v-rating readonly :value="getMedia(10)" background-color="orange lighten-3" color="orange" large></v-rating>
                        <span>Média entre 10 opiniões</span>
                    </v-col>

                    <v-col cols="12" md="6">
                        <div class="pl-3 d-flex align-center mt-2" v-for="(r, i) in ratings" :key="i">
                            <span class="caption mr-4 grey--text" style="min-width:60px">{{r.rate}} estrelas</span>
                            <v-progress-linear :value="r.total / countRateMax * 100" color="amber"></v-progress-linear>
                            <span class="caption ml-3 grey--text">{{ r.total }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="8" class="mx-auto mt-5">
                <h1>Comentários</h1>
                <div v-for="(c,i) in installer.ratings" :key="i" class="mt-5">
                    <span class="d-block caption font-weight-bold">{{c.client.user.username}}</span>
                    <span class="d-block caption">
                        {{c.order && c.order.budget && c.order.budget.simulator && c.order.budget.simulator.city ? c.order.budget.simulator.city.name : '' }}
                        - {{c.order && c.order.budget && c.order.budget.simulator && c.order.budget.simulator.state ? c.order.budget.simulator.state.uf : '' }}
                    </span>
                    <v-rating readonly v-model="c.rate" background-color="orange lighten-3" color="orange" large></v-rating>
                    <span class="caption" v-html="c.description"></span>
                </div>
            </v-col>
        </template>
    </v-row>
</template>

<script>
    export default {
        props: {
            installer: {
                type: Object
            }
        },
        data: () => ({
            progressValue: 4,
            rating: 4,
            ratings: [],
            comments:[
                { user:"Antônio", city:"São Paulo - SP", rate:4, comment: "Tudo foi feito de forma muito simples, com um atendimento excelente e um time super ponta firme! Foi rápido também. o projeto e começamos o processo de homologação e instalação. E já percebo a economia na hora" },
                { user:"Antônio", city:"São Paulo - SP", rate:1, comment: "Tudo foi feito de forma muito simples, com um atendimento excelente e um time super ponta firme! Foi rápido também. o projeto e começamos o processo de homologação e instalação. E já percebo a economia na hora" },
                { user:"Antônio", city:"São Paulo - SP", rate:3, comment: "Tudo foi feito de forma muito simples, com um atendimento excelente e um time super ponta firme! Foi rápido também. o projeto e começamos o processo de homologação e instalação. E já percebo a economia na hora" },
                { user:"Antônio", city:"São Paulo - SP", rate:4, comment: "Tudo foi feito de forma muito simples, com um atendimento excelente e um time super ponta firme! Foi rápido também. o projeto e começamos o processo de homologação e instalação. E já percebo a economia na hora" },
                { user:"Antônio", city:"São Paulo - SP", rate:2, comment: "Tudo foi feito de forma muito simples, com um atendimento excelente e um time super ponta firme! Foi rápido também. o projeto e começamos o processo de homologação e instalação. E já percebo a economia na hora" }
            ]
        }),
        
        mounted() {
            let arr = [
                {
                    rate: 1,
                    total: 0
                },
                {
                    rate: 2,
                    total: 0
                },
                {
                    rate: 3,
                    total: 0
                },
                {
                    rate: 4,
                    total: 0
                },
                {
                    rate: 5,
                    total: 0
                },

            ]


            for (let r of this.installer.ratings) {
               
                for (let a of arr) {
                    if (a.rate == r.rate) {
                        a.total++
                    }
                }
            }
            
            this.ratings = arr
            
            
            console.log('AAA', arr)
            
        },

        methods: {
            getMedia (total = 10) {
                let loop = total
                if (this.installer.ratings.length < total) {
                    loop = this.installer.ratings.length
                }
                
                let resp = 0

                for (let i = 0; i < loop; i++) {
                    resp += parseFloat(this.installer.ratings[i].rate)
                }

                return parseFloat(resp / loop).toFixed(1)
            },
            
            countRate (rate = 1) {
                let resp = 0
                
                for (let r of this.installer.ratings) {
                    if (r.rate == rate) {
                        resp++
                    }
                }

                return resp
            },

            
        },
        
        computed: {
            countRateMax () {
                let resp = 0
                
                for (let r of this.ratings) {
                   if (r.total > resp) {
                       resp = r.total
                   }
                }

                return resp
            }
        }
    }
</script>


<style scoped>
    .titleSem {
        font-family: "cerapro-regular";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.0025em;
        text-align: center;
        color: #6E6B7B;
    }
    
    .textSem {
        font-family: "cerapro-regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #6E6B7B;
    }
</style>