<template>
    <v-row>
        <v-col cols="12" v-if="isLoading">
            <div class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
        </v-col>
        
        <template v-else>


            <v-dialog v-model="dialogRating" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-card-text class="pl-0 pb-0 ma-0" style="height: 100vh">
                        <v-row style="height: 100%">
                            <v-col cols="12" md="3" style="background-color: #fd9; height: 100%" class="d-flex flex-column justify-center align-content-center align-center">
                                <v-avatar
                                    class="ma-3"
                                    size="192"
                                    tile
                                >
                                    <v-img :src="order.installer.url"></v-img>
                                </v-avatar>
                                <h1 class="text-center text-h6 mt-10 font-weight-bold">{{ order.installer.company_name }}</h1>
                                <span class="d-block text-center caption mb-10">{{ order.installer.city ? order.installer.city.name : '' }}, {{ order.installer.state ? order.installer.state.uf : '' }}</span>
                            </v-col>
                            
                            <v-col cols="12" md="9" class="pa-16">
                                <h2>Como foi sua experiência com esta empresa?</h2>
                                <p>Sua avaliação é importante para nós!</p>

                                <div class="text-left mt-16">
                                    <v-rating v-model="rate.rate">
                                        <template v-slot:item="props">
                                            
                                            <img 
                                                src="/images/icon-star-check.svg" 
                                                width="36"
                                                v-if="props.isFilled"
                                                large
                                                @click="props.click"
                                                class="mr-2"
                                                style="cursor: pointer"
                                            />

                                            <img
                                                src="/images/icon-star.svg"
                                                width="36"
                                                v-else
                                                large
                                                @click="props.click"
                                                class="mr-2"
                                                style="cursor: pointer"
                                            />
                                        </template>
                                    </v-rating>
                                </div>

                                <div style="width:100%;" id="contentMessagePost" class="mt-5">

                                    <vue-editor
                                        v-model="rate.description"
                                        placeholder="Conte sua experiência com a empresa"
                                    />
                                    
                                </div>

                                <v-btn class="mt-5" dark color="#FFA900" @click="onSendRate" :disabled="isLoadingRate" :loading="isLoadingRate">Avaliar</v-btn>
                                
                                
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            
            
            <v-col cols="12" md="11" class="mx-auto mt-10">
                <h1 class="text-h4 mt-5 font-weight-bold">Seu pedido</h1>
                <span class="d-block mb-10">N.{{ order.custom_id }}</span>
            </v-col>

            <v-col cols="12" md="11" class="mx-auto">

                <div class="badge-element mt-3 mb-5" style="display: table !important; flex: none !important;">
                    <v-btn
                        rounded
                        elevation="0"
                        v-for="(item, i) in tabs"
                        :key="i"
                        :class="{ 'no-uppercase':true, 'orange': activeTab === item, 'transparent': !(activeTab === item), 'white--text': activeTab === item, 'grey--text text--darken-1': !(activeTab === item)}"
                        @click="activeTab=item">
                        {{ item }}
                    </v-btn>
                </div>

                
                
                <v-card :class="{'elevation-0': true}">
                    <template v-if="activeTab == 'Status'">
                        <Status :order="order" />
                    </template>

                    <template v-if="activeTab == 'Resumo do pedido'">

                        <template v-if="order && order.installer && getUser.user_type == 3">
                            <div class="d-flex flex-no-wrap justify-space-between mt-10">
                                <h1 class="text-h5 font-weight-bold hideMobile">
                                    Empresa de energia solar
                                </h1>
                                
                                <v-btn v-if="order.finished && !order.rating" dark color="#FFA900" @click="dialogRating = true">Avaliar empresa</v-btn>
                            </div>


                            <v-row class="mt-3 mb-10">
                                <v-col cols="12">
                                    <v-card>
                                        
                                        <div class="d-flex flex-wrap">
                                            
                                            <div class="hideMobile">
                                                <v-avatar
                                                    class="ma-3"
                                                    size="192"
                                                    tile
                                                >
                                                    <v-img :src="order.installer.url"></v-img>
                                                </v-avatar>
                                            </div>
                                            
                                            <div style="margin-left: 50px" class="showMobile contentEmpresa mt-3">
                                                <v-avatar
                                                    size="73"
                                                    tile
                                                    class="mr-2"
                                                >
                                                    <v-img :src="order.installer.url"></v-img>
                                                </v-avatar>
                                                <div>
                                                    <p class="mb-0">Empresa de energia solar</p>
                                                    <h1 class="text-h5 mt-2 font-weight-bold">{{ order.installer.company_name }}</h1>
                                                </div>
                                            </div>
                                            <div style="width: 100%" class="showMobile mt-3 mb-3">
                                                <v-divider></v-divider>
                                            </div>
                                            
                                            
                                            
                                            <div style="margin-left: 50px">
                                                <v-row>
                                                    <v-col cols="12" md="12" class="hideMobile">
                                                        <h1 class="text-h5 mt-2 font-weight-bold">{{ order.installer.company_name }}</h1>
                                                    </v-col>

                                                    <v-col cols="12" md="6">
                                                        <p class="titleItem noMargin">CNPJ</p>
                                                        <p class="noMargin">{{ order.installer.cnpj }}</p>
                                                    </v-col>

                                                    <v-col cols="12" md="6">
                                                        <p class="titleItem noMargin">CONTATO</p>
                                                        <p class="noMargin">{{ order.installer.phone }}</p>
                                                    </v-col>

                                                    <v-col cols="12" md="6">
                                                        <p class="titleItem noMargin">ENDEREÇO</p>
                                                        <p class="noMargin">{{ order.installer.address }}</p>
                                                    </v-col>

                                                    <v-col cols="12" md="6">
                                                        <p class="titleItem noMargin">EMAIL</p>
                                                        <p class="noMargin">{{ order.installer.user ? order.installer.user.email : '-' }}</p>
                                                    </v-col>

                                                </v-row>
                                            </div>
                                        </div>
                                    </v-card>

                                </v-col>
                            </v-row>

                        </template>

                        <h1 class="text-h5 font-weight-bold">
                            Detalhes
                        </h1>
                        <OrderDetails :order="order" />
                    </template>
                </v-card>
            </v-col>
        </template>
        
    </v-row>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex'
    
    import OrderDetails from "@/Views/OrderDetails.vue"
    import Status from "./Status.vue"

    export default {
        metaInfo: {
            title: 'Status do pedido - Market Solar',
        },
        components:{
            OrderDetails, Status
        },
        data()
        {
            return {
                isLoading: true,
                isLoadingRate: false,
                tab:0,
                tabs:["Status", "Resumo do pedido"],
                activeTab: 'Status',
                order: null,
                dialogRating: false,
                rules: {
                    rateDescription:  len => v => (v || '').length <= len || `Você só pode digitar até 200 caracteres.`,
                },
                
                rate: {
                    description: '',
                    rate: 0
                }
            }
        },
        
        
        async mounted() {
            this.id = this.$route.params.id
            this.isLoading = true
            try {
                this.order = await this.showOrder( this.$route.params.id )
                console.log(this.order)
            } catch (error) {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao recuperar pedido'})
            }

            this.isLoading = false

            window.addEventListener('resize', () => (this.isMobile = window.innerWidth < 800))
        },
        
        
        methods: {
            ...mapActions(['showOrder', 'sendRate']),


            async onSendRate () {
                const rate = {...this.rate}
                
                if (rate.description.length > 200) {
                    return
                }
                
                this.isLoadingRate = true
                
                rate.order_id = this.order.id
                
                const r = await this.sendRate(rate)
                if (r) {
                    this.dialogRating = false
                    this.rate = {
                        description: '',
                        rate: 0
                    }
                    this.order.rating = r
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Avaliação inserida com sucesso'})
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao inserir avaliação'})
                }
                
                this.isLoadingRate = false
            }
        },
        
        computed: {
            ...mapGetters(['getUser'])
        }
    }
</script>


<style>
    #contentMessagePost .quillWrapper .ql-snow.ql-toolbar {
        display: none !important;
    }
    
    #contentMessagePost .ql-editor {
        max-height: 100px !important;
        min-height: 100px !important;
    }


    #contentMessagePost .quillWrapper {
        width: 100%;
        height: 100px;
    }

    #contentMessagePost .ql-container.ql-snow {
        border: 1px solid #EAE9EC!important;
        border-radius: 8px;
        background: #F4F4F6;
    }

</style>

<style scoped>
    
    .hideMobile {
        display: block;
    }
    
    .showMobile {
        display: none !important;
    }
    
    @media screen and (max-width: 960px) {
        
        .hideMobile {
            display: none;
        }
        
        .showMobile {
            display: flex !important;
        }
        
        .contentEmpresa {   
            width: 100%;
            display: flex;
            flex-direction: row;
        }
        
    }
</style>