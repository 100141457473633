<template>
    <main class="d-block mx-auto d-flex flex-column justify-center mt-5 wm-488">
        <template v-if="isLoadingInit">
            <v-col cols="12" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </template>

        <template v-else>
            <form v-show="step == 1" @keydown.enter="isValidPasswords && nextStep()">
                <h1 class="title">Olá, seja bem vindo!</h1>
                <p class="subTitle">
                    Vamos pedir alguns dados para criar a sua conta
                </p>

                <v-text-field
                    class="mt-4 mb-10"
                    label="Criar senha"
                    v-model="user.password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    persistent-hint
                    :rules="[
                        (value) =>
                            value.length > 7 ||
                            'A senha precisa conter pelo menos 8 caracteres',
                    ]"
                ></v-text-field>
                <v-text-field
                    class="mt-4"
                    label="Confirme sua senha"
                    v-model="user.repassword"
                    :type="showRePassword ? 'text' : 'password'"
                    :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showRePassword = !showRePassword"
                    persistent-hint
                    :rules="[
                        (value) =>
                            user.repassword == user.password ||
                            'A senha deve ser igual a senha criada',
                    ]"
                ></v-text-field>

                <template v-if="!$route.query.optin">
                    <div class="d-flex align-center">
                        <v-checkbox v-model="user.opt_in"></v-checkbox>
                        <span class="span-check">Aceito receber e-mails e mensagens da Market Solar.</span>
                    </div>

                    <div class="d-flex align-center" style="margin-top: -35px">
                        <v-checkbox v-model="terms"></v-checkbox
                        ><span class="span-check"
                    >Li e aceito os
                        <a
                            class="span-check"
                            href="https://marketsolar.com.br/termos-e-condicoes-de-uso/"
                            target="_blank"
                        >Termos e condições de uso.</a
                        ></span
                    >
                    </div>    
                </template>
                
                

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    :disabled="!isValidPasswords"
                    >Próximo</v-btn
                >
            </form>

            <form v-show="step == 2" @keydown.enter="isValidEnderecos && nextStep()">
                <h1 class="title">Informações da sua empresa</h1>
                <p class="subTitle">
                    Essas informações <strong>serão públicas</strong> para seu
                    cliente.
                </p>

                <v-text-field
                    label="Nome fantasia"
                    placeholder="Nome fantasia"
                    v-model="user.trading_name"
                    class="mt-4 mb-4"
                    hint="Utilize maiúsculas apenas nas primeiras letras"
                    persistent-hint
                ></v-text-field>

                <gmap-autocomplete
                    class="introInput"
                    :style="`${!selectedLocation && selectedLocation != null ? 'border-bottom-color: #ff5252;': 'margin-bottom: 50px; border-bottom-color: rgba(0, 0, 0, 0.42);'}`"
                    placeholder="Endereço"
                    @place_changed="setPlace"
                    v-on:blur="checkLocation()"
                    v-on:focus="selectedLocation = true"
                >
                    <template v-slot:input="slotProps">
                        <v-text-field
                            outlined
                            prepend-inner-icon="place"
                            placeholder="Endereço"
                            ref="input"
                            v-on:listeners="slotProps.listeners"
                            v-on:attrs="slotProps.attrs"
                            types="(address)"
                            country="br"
                        >
                        </v-text-field>
                    </template>
                </gmap-autocomplete>
                <small v-if="!selectedLocation && selectedLocation != null" style="color: #ff5252;font-size: 12px;">Para preencher corretamente este campo, clique em uma das opções que aparecem ao digitar o endereço. Tente outra vez</small>
                
                <v-text-field
                    label="Complemento"
                    placeholder="Complemento"
                    v-model="user.complement"
                    class="mb-8"
                    hint="Opcional"
                    persistent-hint
                ></v-text-field>

                <v-text-field
                    label="Telefone"
                    placeholder="Telefone"
                    v-model="user.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    class="mt-4 mb-4"
                ></v-text-field>

                <v-text-field
                    label="Site da empresa"
                    placeholder="Site da empresa"
                    v-model="user.site"
                    class="mt-4 mb-4"
                    hint="Opcional"
                    persistent-hint
                ></v-text-field>

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    :disabled="!isValidEnderecos"
                    >Próximo</v-btn
                >
            </form>

            <form v-show="step == 3" @keydown.enter="isValidCompanySize && nextStep()">
                <h1 class="title">Informações da sua empresa</h1>
                <p class="subTitle">Essas informações <b>serão privadas</b>.</p>

                <v-text-field
                    label="CNPJ"
                    placeholder="CNPJ"
                    v-model="user.cnpj"
                    class="mt-4 mb-4"
                    v-mask="'##.###.###/####-##'"
                ></v-text-field>

                <v-text-field
                    label="Razão social"
                    placeholder="Razão social"
                    v-model="user.company_name"
                    class="mt-4 mb-4"
                ></v-text-field>

                <v-text-field
                    label="Nome do responsável"
                    placeholder="Nome do responsável"
                    v-model="user.representative"
                    class="mt-4 mb-4"
                ></v-text-field>

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    :disabled="!isValidInfosPrivateds || isLoadingCnpj"
                    :loading="isLoadingCnpj"
                    >Próximo</v-btn
                >
            </form>

            <form v-show="step == 4" @keydown.enter="isValidCompanySize && nextStep()">
                <h1 class="title">
                    Quais portes de projeto sua <br />
                    empresa executa?
                </h1>

                <div class="mt-9" v-if="getCompanySize.length > 0">
                    <span class="d-block grey--text text--darken-4"
                        >Selecione todas as opções que forem compatíveis</span
                    >
                    <v-checkbox
                        v-for="(item, i) in getCompanySize"
                        v-model="user.company_size"
                        :value="item.id"
                        :key="i"
                        persistent-hint
                        :hint="item.description"
                        :label="item.size"
                    ></v-checkbox>
                </div>

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    :disabled="!isValidCompanySize"
                    >Próximo</v-btn
                >
            </form>

            <form v-show="step == 5" @keydown.enter="user.brands.length > 0 && nextStep()">
                <h1 class="title">Selecione as principais marcas com que sua empresa trabalha</h1>

                <v-autocomplete
                    class="ma-2 padrao"
                    :items="brands"
                    label="Marcas"
                    v-model="user.brands"
                    multiple
                    item-text="trading_name"
                    item-value="id"
                    chips
                    small-chips
                ></v-autocomplete>
                
                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    :disabled="user.brands.length == 0"
                    >Próximo</v-btn>

                <span class="d-block text-center" style="cursor: pointer" @click="nextStep(false)">Quero fazer depois</span>
            </form>

            <form v-show="step == 6" @keydown.enter="nextStep">
                <h1 class="title">
                    Quais são os tipos de clientes que sua <br />
                    empresa atende?
                </h1>

                <div class="mt-5" v-if="getConsumerType.length > 0">
                    <span class="d-block grey--text text--darken-3"
                        >Selecione todas as opções que forem compatíveis</span
                    >
                    <v-checkbox
                        v-for="(item, i) in getConsumerType"
                        :value="item.id"
                        v-model="user.consumer_type"
                        :key="i"
                        persistent-hint
                        :hint="item.description"
                        :label="item.type"
                    ></v-checkbox>
                </div>

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    >Próximo</v-btn
                >
            </form>

            <form v-show="step == 7" @keydown.enter="nextStep">
                <PlacesComponent
                    v-if="placesDialog"
                    @onClose="placesDialog = false"
                    @onSave="onSelectedRegion"
                    :dialog="placesDialog"
                    :initSelecteds="user.stateCity"
                />

                <h1 class="title mb-10">
                    Quais são as suas regiões atendidas?
                </h1>

                <div md="8">

                    <div class="d-flex align-content-center cardPlace" style="cursor: pointer" @click="placesDialog = true">
                        <v-icon :class="`mt-2 blue--text text--darken-2`">mdi-map-marker-outline</v-icon>
                        <div class="d-flex flex-column ml-3">
                            <span class="blue--text text--darken-2 font-weight-medium text-overline">REGIÕES ATENTIDAS</span>
                            <span class="blue--text text--darken-2 font-weight-light">Clique e selecione estados ou cidades</span>
                        </div>
                    </div>
                   
                    <pre v-if="selectedCity">{{
                        JSON.stringify(selectedCity, null, 2)
                    }}</pre>
                </div>

                <p
                    v-if="user.stateCity.length > 0"
                    class="regions-subtitle mt-8 mb-6"
                >
                    Marque e confirme os estados e as cidades que sua empresa
                    atende
                </p>

                <v-checkbox
                    v-for="(item, i) in user.stateCity"
                    :value="item"
                    v-model="user.stateCity"
                    :key="i"
                    persistent-hint
                    :label="item.name"
                    :value-comparator="comparator"
                ></v-checkbox>

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    >Próximo</v-btn
                >
            </form>

            <form v-show="step == 8" @keydown.enter="nextStep">
                <h1 class="title">
                    Escreva um texto de apresentação<br />
                    sobre sua empresa
                </h1>

                <p class="mt-16" style="color: #828282; font-size: 16px">
                    Sobre a empresa
                </p>
                <vue-editor id="vueeditor" v-model="user.description" />

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="nextStep"
                    >Próximo</v-btn
                >

                <span class="d-block text-center" style="cursor: pointer" @click="nextStep(false)">Quero fazer depois</span>
            </form>

            <form v-show="step == 9" @keydown.enter="user.photo && isLoadingInsert == false && onCreateAccount()">
                <h1 class="title text-center mb-4">
                    Adicione o logo da sua empresa
                </h1>
                <p class="text-center subTitle mb-5">
                    <b>Dica:</b> use o arquivo PNG, quadrado, com 256x256 pixels
                </p>

                <v-col cols="12">
                    <DropzoneComponent
                        v-if="!user.photo"
                        @onOver="onOver"
                        @onLeave="onLeave"
                        bgColor="transparent"
                        :noBorder="true"
                    >
                        <template v-slot:content>
                            <div class="upload__card pb-5">
                                <div
                                    class="text-center card__upload__header d-flex justify-center align-center"
                                >
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M30.6654 25.3333C30.6654 26.0406 30.3844 26.7189 29.8843 27.219C29.3842 27.719 28.7059 28 27.9987 28H3.9987C3.29145 28 2.61318 27.719 2.11308 27.219C1.61298 26.7189 1.33203 26.0406 1.33203 25.3333V10.6667C1.33203 9.95942 1.61298 9.28115 2.11308 8.78105C2.61318 8.28095 3.29145 8 3.9987 8H9.33203L11.9987 4H19.9987L22.6654 8H27.9987C28.7059 8 29.3842 8.28095 29.8843 8.78105C30.3844 9.28115 30.6654 9.95942 30.6654 10.6667V25.3333Z"
                                            stroke="#F2F2F2"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M16.0013 22.6667C18.9468 22.6667 21.3346 20.2789 21.3346 17.3333C21.3346 14.3878 18.9468 12 16.0013 12C13.0558 12 10.668 14.3878 10.668 17.3333C10.668 20.2789 13.0558 22.6667 16.0013 22.6667Z"
                                            stroke="#F2F2F2"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <p
                                    class="mt-5 mb-3 dropzone-custom-title text-center"
                                    style="font-size: 16px; color: #4f4f4f"
                                >
                                    Arraste a foto ou clique aqui para<br />
                                    fazer o upload do seu logo.
                                </p>
                            </div>
                        </template>
                    </DropzoneComponent>

                    <img
                        v-if="user.photo"
                        :src="user.photo"
                        :style="{ maxWidth: '100%' }"
                    />
                    <v-icon v-if="user.photo" @click="user.photo = ''"
                        >mdi-close</v-icon
                    >
                </v-col>

                <v-btn
                    large
                    block
                    elevation="0"
                    class="mt-5 orange white--text mb-5 no-uppercase"
                    @click="onCreateAccount"
                    :disabled="!user.photo || isLoadingInsert"
                    :loading="isLoadingInsert"
                    >Ok, cria minha conta</v-btn
                >
                <span v-if="!user.photo" class="d-block text-center" @click="onCreateAccount">Quero fazer depois</span>
            </form>
        </template>
    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PlacesComponent from "@/components/PlacesComponent/Index";
import DropzoneComponent from "@/components/DropzoneComponent/Index";

export default {
    components: {
        PlacesComponent,
        DropzoneComponent,
    },
    data: () => {
        return {
            placesDialog: false,
            isLoadingInit: true,
            selectedCity: null,
            isLoadingInsert: false,
            showRePassword: false,
            showPassword: false,
            terms: false,
            user: {
                complement: "",
                password: "",
                repassword: "",
                cnpj: "",
                trading_name: "",
                company_name: "",
                email: "",
                representative: "",
                address: "",
                phone: "",
                site: "",
                company_size: [],
                consumer_type: [],
                photo: "",
                city_name: "",
                state_name: "",
                description: "",
                stateCity: [],
                obras: [],
                photos: [],
                brands: [],
            },
            step: 1,
            place: null,
            selectedLocation: null,
            regions: {
                icon: {
                    name: "mdi-map-marker-outline",
                    color: "blue--text text--darken-2",
                    props: {},
                },
                title: "REGIÕES ATENTIDAS",
                subtitle: "Clique e selecione estados ou cidades",
                legend: "",
            },
            brands: [],
            isLoadingCnpj: false
        };
    },

    async mounted() {

        this.brands = await this.loadBrands()
        
        if (this.$route.query.optin) {
            this.terms = true
        }
        
        const email = this.$route.query.email;
        const phone = this.$route.query.phone;
        const type = this.$route.query.type;

        this.user.phone = phone;
        this.user.email = email;
        this.user.type = type;

        this.isLoadingInit = true;

        const r = await this.checkEmailRegister({ email, type });

        if (r) {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Esse email já está sendo usado",
            });
            this.$router.push("/createaccount");
        }

        this.isLoadingInit = false;

        await this.loadCompanySize();
        await this.loadConsumerType();

        this.brands = await this.loadBrands();
    },

    methods: {
        ...mapActions([
            "checkEmailRegister",
            "loadCompanySize",
            "loadConsumerType",
            "sendCreateAccount",
            "loadBrands",
            "checkCNPJ"
        ]),

        async nextStep( validate = true ) {
            
            if (this.step == 3) {
                this.isLoadingCnpj = true
                const r = await this.checkCNPJ({ cnpj: this.user.cnpj })
                
                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "CNPJ já utilizado",
                    })
                    this.isLoadingCnpj = false   
                    return
                }
            }
            this.isLoadingCnpj = false
            
            
            if( validate && this.step == 8 && !this.user.description )
            {
                return this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Escreva um texto de apresentação sobre sua empresa",
                });
            }
            
            if (this.$route.query.type == 5) {
                if (this.step == 3) {
                    this.step = 5
                } else if (this.step == 5) {
                    this.step = 7
                } else {
                    this.step++;
                }
            } else {
                this.step++;
            }
            this.$forceUpdate();
        },

        setPlace(place) {
            place.latitude = place.geometry.location.lat();
            place.longitude = place.geometry.location.lng();

            this.place = place;
        },
        checkLocation() {
            if(this.place == null){this.selectedLocation = false}
        },
        showHint() {
            return true; // /(?=.*[!@#$%^&*])/ig.test(this.user.password)
        },

        onSelectedRegion(event) {
            this.user.stateCity = event;
            // if (event.length > 0) {
            //     this.regions.legend = `${event.length} selecionado(os)`;
            // } else {
            //     this.regions.legend = "Nenhuma seleção";
            // }
        },
        comparator(a, b) {
            return a.customId == b.customId;
        },
        onOver() {},
        async onLeave(file) {
            this.user.photo = await this.toBase64(file);
        },

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        async sendInstallerWelcomeEmail(tradingName, email) {

            let token = localStorage.getItem('token');

            const form = new FormData();

            form.append('name', tradingName);
            form.append('email', email);

            const options = {
                method: "POST",
                body: form,
                headers: {
                    "Authorization": token
                },
            };

            let sendMail = await fetch('https://netapi1.marketsolar.com.br/messages/mail/template/welcomeInstallers', options);
        },

        async onCreateAccount() {
            this.isLoadingInsert = true;

            const user = { ...this.user };

            user.address = this.place.formatted_address;
            user.place = this.place;

            const r = await this.sendCreateAccount(user);


            if (r.status) {

                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cadastrado com sucesso",
                });

                if (this.$route.query.type == 5) {

                    this.$router.push({ path: "/distributor/profile" });

                } else {
                    let sendMail = await this.sendInstallerWelcomeEmail(r.data.installer.trading_name, r.data.user.email);

                    this.$router.push({ path: "/plans", query: { init: true } });

                }

            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: r.error,
                });
            }

            this.isLoadingInsert = false;
        },
    },

    computed: {
        ...mapGetters(["getCompanySize", "getConsumerType"]),

        isValidPasswords() {
            if (this.user.password.length > 7) {
                //&& this.user.password.match(/[0-9]+/) && this.user.password.match(/[a-z]+/)

                return (
                    this.terms &&
                    this.user.password &&
                    this.user.repassword &&
                    this.user.password == this.user.repassword
                );
            } else {
                return false;
            }
        },

        isValidEnderecos() {
            if (
                this.place &&
                this.user.trading_name &&
                // this.user.site &&
                this.user.phone
            ) {
                return true;
            } else {
                return false;
            }
        },

        isValidInfosPrivateds() {
            if (
                this.user.representative &&
                this.user.company_name &&
                this.user.cnpj
            ) {
                return true;
            } else {
                return false;
            }
        },

        isValidCompanySize() {
            if (this.user.company_size.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style>
.v-input--checkbox .v-messages__message {
    margin-left: 33px !important;
}
#vueditor {
    min-height: 300px !important;
}
</style>

<style scoped>
.span-check {
    font-size: 14px;
    color: #6e6b7b;
}

.wm-800 {
    width: 100%;
    max-width: 800px;
}
.wm-488 {
    width: 100%;
    max-width: 488px;
}

.introInput:focus {
    border-bottom: 2px solid #1976d2 !important;
}

.introInput,
.introInput:focus {
    flex: 1 1 auto;
    line-height: 20px;
    max-width: 100%;
    min-width: 0;
    width: 100%;

    border-collapse: collapse;
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    transition-duration: 0.15s;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-bottom: 38px;
    margin-top: 16px;

    padding: 8px 0 8px;
    height: 32px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    box-sizing: border-box;
    outline: none;
}

.imageBgIcon {
    display: flex;
    width: 100%;
    height: 171px;
    background: #828282;
    border-radius: 8px;
    color: #fff;
    justify-content: center;
}

.textCardObra {
    background-color: #e0e0e0 !important;
}

.upload__card {
    border-radius: 4px;
    overflow: hidden;
}
.card__upload__header {
    min-height: 256px;
    background-color: #828282;
}
.upload__card h3 {
    font-weight: 300;
    font-size: 18px;
    color: #828282;
}
.upload__card div {
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #828282;
    border-radius: 8px;
    background: #bfbec6;
}
.title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px !important;
    line-height: 35px;
    color: #312f37;
    margin-top: 40px;
}
.subTitle {
    margin-top: 18px;
    margin-bottom: 60px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 145%;
    color: #5e5873;
}
.regions-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #5e5873;
}

.cardPlace {
    color: #0A85FF;
    border-radius: 10px;
    border: 2px solid #0A85FF;
    padding: 30px 15px;
    margin-bottom: 30px;
}
</style>
