<template>
    <v-row>
        <svg v-show="!editing" class="edit-icon" @click="editing = true" width="21" height="17" viewBox="0 0 26 25" fill="none"> <path d="M1.5 18.75L0.96967 18.2197C0.829018 18.3603 0.75 18.5511 0.75 18.75H1.5ZM19.375 0.875L19.9053 0.34467C19.6124 0.0517767 19.1376 0.0517767 18.8447 0.34467L19.375 0.875ZM24.875 6.375L25.4053 6.90533C25.6982 6.61244 25.6982 6.13756 25.4053 5.84467L24.875 6.375ZM7 24.25V25C7.19891 25 7.38968 24.921 7.53033 24.7803L7 24.25ZM1.5 24.25H0.75C0.75 24.6642 1.08579 25 1.5 25L1.5 24.25ZM12.5 23.5C12.0858 23.5 11.75 23.8358 11.75 24.25C11.75 24.6642 12.0858 25 12.5 25V23.5ZM24.1875 25C24.6017 25 24.9375 24.6642 24.9375 24.25C24.9375 23.8358 24.6017 23.5 24.1875 23.5V25ZM2.03033 19.2803L19.9053 1.40533L18.8447 0.34467L0.96967 18.2197L2.03033 19.2803ZM18.8447 1.40533L24.3447 6.90533L25.4053 5.84467L19.9053 0.34467L18.8447 1.40533ZM24.3447 5.84467L6.46967 23.7197L7.53033 24.7803L25.4053 6.90533L24.3447 5.84467ZM7 23.5H1.5V25H7V23.5ZM2.25 24.25V18.75H0.75V24.25H2.25ZM14.7197 5.53033L20.2197 11.0303L21.2803 9.96967L15.7803 4.46967L14.7197 5.53033ZM12.5 25H24.1875V23.5H12.5V25Z" fill="#312F37"/> </svg>
        
        <v-col cols="12" md="5" offset-md="1" v-show="!editing" class="mt-10 ">
            <v-card height="160px" class="d-flex align-center elevation-1 pa-10 cardStyle">
                
                <img src="/images/icon-pointer.svg" class="orange--text text-h2 mr-3">
                
                <div class="caption">
                    <span class="cardTitle">ENDEREÇO</span>
                    <span class="d-block cardText">{{ distributor.address }} {{ distributor.complement ? `(${distributor.complement})` : null }}</span>
                    <!--<span class="d-block cardText">{{ distributor.city ? distributor.city.name : '' }} - {{ distributor.state ? distributor.state.name : '' }}</span>-->
                </div>
            </v-card>
        </v-col>

        <v-col cols="12" md="5" v-show="!editing" class="mt-10">
            <v-card height="160px" class="d-flex align-center elevation-1 pa-10 cardStyle">
                <img src="/images/icon-phone.svg" class="orange--text text-h2 mr-3">
                <div class="caption">
                    <span class="cardTitle">CONTATOS</span>
                    <span class="d-block cardText">{{ distributor.phone }}</span>
                    <span class="d-block cardText">{{ distributor.site }}</span>
                </div>
            </v-card>
        </v-col>

        <v-col cols="12" md="10" offset-md="1" v-show="!editing">
            <v-card class="pa-10 d-flex align-center cardStyle">
                
                <v-row>
                    <v-col cols="12" md="3">
                        <h1 class="text-h3 orange--text"><b>{{ distributor.total_cidades }}</b> <span class="textPlaces">cidades</span></h1>
                        <h1 class="text-h3 orange--text"><b>{{ distributor.total_estados }}</b> <span class="textPlaces">estados</span></h1>
                    </v-col>
                    <v-col cols="12" md="9">
                        <p class="cardTitle">Regiões atendidas</p>
                        <v-row>
                            <v-col cols="12" md="6" v-for="(p,i) in distributor.regions" :key="i" class="pt-0 pb-0">
                                <li class="textPlaces">
                                    {{p.city ? `${p.city.name}` : `${p.state.name}`}}
                                </li>
                            </v-col>
                        </v-row>
                        
                        
                    </v-col>
                </v-row>

                <div class="caption ml-5">
                   
                   
                </div>
            </v-card>
        </v-col>




        <v-col cols="12" v-show="editing">
            <div class="text-right">
                <v-btn @click="onUpdate" elevation="2" color="orange" class="white--text mb-5" :loading="isLoading" :disabled="isLoading">Salvar</v-btn>
            </div>
        </v-col>

        <v-col cols="12" md="6" v-show="editing" class="mt-10 ">
            <v-card class="pa-5 elevation-1">
                <span class="d-block font-weight-bold">ENDEREÇO</span>
                <div class="d-flex justify-space-between">
                    <v-text-field label="Endereço" v-model="distributor.address"></v-text-field>
                </div>
                <v-text-field label="Complemento" v-model="distributor.complement"></v-text-field>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" v-show="editing" class="mt-10 ">
            <v-card class="pa-5 elevation-1">
                <span class="d-block font-weight-bold">CONTATOS</span>
                <v-text-field label="Telefone" v-model="distributor.phone" v-mask="['(##) #####-####']"></v-text-field>
                <v-text-field label="Site" v-model="distributor.site"></v-text-field>
            </v-card>
        </v-col>

        <v-col cols="12" v-show="editing">
            <v-card class="pa-5 elevation-1">
                <p class="black--text text--darken-2 font-weight-medium text-overline mb-4 text-center">REGIÕES ATENTIDAS</p>
                <v-row>
                    <v-col offset-md="3" md="6">
                        <div class="d-flex align-content-center cardPlace" style="cursor: pointer" @click="showDialogPlace">
                            <v-icon :class="`mt-2 blue--text text--darken-2`">mdi-map-marker-outline</v-icon>
                            <div class="d-flex flex-column ml-3">
                                <span class="blue--text text--darken-2 font-weight-medium text-overline">REGIÕES ATENTIDAS</span>
                                <span class="blue--text text--darken-2 font-weight-light">Clique e selecione estados ou cidades</span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <p class="text-center"><v-chip disabled>{{ distributor.stateCity.length > 0 ? `${distributor.stateCity.length} selecionado(os)` : 'Nenhuma seleção' }}</v-chip></p>
            </v-card>
        </v-col>



        <PlacesComponent
            v-if="placesDialog"
            @onClose="placesDialog = false"
            @onSave="onSelectedRegion"
            :dialog="placesDialog"
            :initSelecteds="distributor.stateCity"
        />


    </v-row>
</template>


<script>

    import {mapActions} from "vuex";

    import PlacesComponent from "@/components/PlacesComponent/Index";


    export default {
        
        components: {
            PlacesComponent
        },
        
        props:{
            distributor: {
                type: Object
            }
        },
        data()
        {
            return {
                Editor:null,
                places:[],
                images:[],
                placesDialog: false,
                editing: false,
                isLoading: false
            }
        },
        methods:{
            ...mapActions(['updateDistributors', 'loadBrands']),
            onOver(){},
            onLeave( file )
            {
                console.log( file )
            },
            InitEditor( editor )
            {
                console.log("ok", editor.getContent() )
                this.Editor = editor
                this.Editor.setContent('Escreva aqui sobra a empresa')
            },

            
            showDialogPlace () {
                this.placesDialog = true
                console.log('AAA')
            },

            async onUpdate () {
                this.isLoading = true

                const distributor = {...this.distributor}
                

                const r = await this.updateDistributors(distributor)

                if (r) {
                    this.editing = false
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Dados alterados com sucesso'})

                    this.$emit('updateDistributor', true)

                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao alterar dados'})
                }

                this.isLoading = false
            },

            onSelectedRegion(event) {
                this.distributor.stateCity = event
            },
        }
    }
</script>

<style scoped>
    .edit-icon{
        position:absolute;
        top:30px;
        right:50px;
        cursor:pointer;
    }
    
    .cardPlace {
        color: #0A85FF;
        border-radius: 10px;
        border: 2px solid #0A85FF;
        padding: 30px 15px;
        margin-bottom: 30px;
    }
    
    
    
    .cardTitle {
        font-family: "cerapro-regular" !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 18px !important;
        letter-spacing: 0.06em !important;
        text-align: left !important;
        color: #312F37 !important;

    }
    
    .cardStyle {
        background: #FFFFFF !important;
        border: 1px solid #FAFAFA !important;
        box-sizing: border-box !important;
        box-shadow: 1px 2px 4px rgba(204, 212, 221, 0.4) !important;
        border-radius: 4px !important;
    }
    
    .cardText {
        font-family: "cerapro-regular" !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #6E6B7B !important;
    }
    
    .textPlaces {
        font-family: "cerapro-regular" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #6E6B7B !important;
    }
</style>