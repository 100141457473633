<template>
    <v-row class="conteudo">
        <v-dialog v-model="dialog" max-width="600px">
            <v-card v-if="distributor">
                <v-card-title>
                    <span class="text-h5">Logo da empresa</span>
                </v-card-title>

                <template v-if="!isLoadingImage">
                    <v-card-text>
                        <img :src="distributor.image" alt="Logo" class="d-block mx-auto mt-10" style="width: 140px"/>
                        <h1 class="text-center text-h6 mt-10 font-weight-bold">{{ distributor.company_name }}</h1>
                        <span class="d-block text-center caption mb-10">{{ distributor.city ? distributor.city.name : '' }}, {{ distributor.state ? distributor.state.uf : '' }}</span>
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <div style="cursor: pointer" @click="$refs['fileInput'].click()">
                            <v-icon class="d-block text-center">mdi-square-edit-outline</v-icon>
                            <div class="caption">Atualizar logo</div>
                            <input type="file" @change="onLeave" ref="fileInput" v-show="false">
                        </div>

                        <v-spacer></v-spacer>
                        <div>
                            <v-icon class="d-block text-center">mdi-trash-can-outline</v-icon>
                            <div class="caption">Deletar</div>
                        </div>
                    </v-card-actions>
                </template>
                <template v-else>
                    <v-card-text class="text-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-card-text>
                </template>
            </v-card>
        </v-dialog>

        <template v-if="isLoadingInit">
            <v-card-text class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-card-text>
        </template>
        
        <template v-else>
            <v-col cols="12" md="10" class="mx-auto" v-if="distributor">
                <img @click="dialog=true" :src="distributor.image" alt="Logo" class="d-block mx-auto mt-10" width="140px"/>

                <p class="text-center mt-10 brandName">{{ distributor.trading_name }}</p>
                <span class="d-block text-center mb-10 textCity">{{distributor.city ? distributor.city.name : ''}}, {{ distributor.state ? distributor.state.uf : '' }}</span>

                <v-tabs fixed-tabs v-model="active" centered background-color="#FAFAFA">
                    <v-tabs-slider color="orange"></v-tabs-slider>
                    <v-tab v-for="(n,i) in menuItens" :key="i">
                        <span :class="`tabTitle ${i == active ? 'tabTitleActive' : ''} no-uppercase`">{{n.label}}</span>
                    </v-tab>
                </v-tabs>

                <v-skeleton-loader
                    type="card-avatar, article, actions"
                    v-if="isLoading"
                    class="mt-10"
                ></v-skeleton-loader>
                <v-tabs-items v-else v-model="active" class="mt-10 mb-10">
                    <v-tab-item  class="mt-10 mb-10">
                        <About :distributor="distributor" @updateDistributor="load"/>
                    </v-tab-item>
                    <v-tab-item  class="mt-10 mb-10">
                        <Contact :distributor="distributor" @updateDistributor="load"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </template>
    </v-row>
</template>


<script> 
    import { mapActions } from 'vuex'
    import About from "./About/Index.vue"
    import Contact from "./Contact/Index.vue"

    export default {
        metaInfo: {
            title: 'Distribuidores - Market Solar',
        },
        components:{
            About,
            Contact
        },
        data()
        {
            return {
                active:0,
                dialog:false,
                distributor: null,
                isLoading: false,
                menuItens:[
                    {
                        label:"Sobre",
                        icon:"mdi-bell-outline",
                        href:"/about"
                    },
                    {
                        label:"Contato",
                        icon:"mdi-file-document-edit-outline",
                        href:"/contact"
                    }
                ],
                isLoadingImage: false,
                isLoadingInit: true
            }
        },
        
        async mounted() {
            this.isLoadingInit = true
            
            await this.loadDistributor()
            
            this.isLoadingInit = false
        },

        methods:{
            ...mapActions(['getProfiledistributor', 'updateDistributors']),
            
            async load () {
                this.isLoading = true


                await this.loadDistributor()

                this.isLoading = false
            },
            
            async loadDistributor () {
                let distributor = await this.getProfiledistributor()

                distributor.brands_ids = distributor.brands_distributor.map(b => {
                    return b.id
                })

              

                distributor.photos = distributor.images;
                distributor.obras_apagadas = []

                distributor.imagens_apagadas = [];


                distributor.stateCity = [];

                for (let stateCity of distributor.regions) {
                    let d = {};
                    if (stateCity.city_id) {
                        d = stateCity.city;
                        d.customName = `${d.name} (${d.state.uf})`;
                        d.isCity = true;
                        d.selected = true;
                        d.customId = `city-${d.id}`;
                    } else {
                        d = stateCity.state;
                        d.customName = `${d.name} (Estado)`;
                        d.isCity = false;
                        d.selected = true;
                        d.customId = `state-${d.id}`;
                    }
                    distributor.stateCity.push(d);
                }

                for (let i in distributor.images) {
                    distributor.images[i].nova_imagem = null;
                }



                this.distributor = distributor

            },


            async onLeave(e) {
                this.isLoadingImage = true
                this.distributor.image = await this.toBase64(e.target.files[0])
                const r = await this.updateDistributors(this.distributor)

                if (r) {
                    this.dialog = false
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Dados alterados com sucesso'})

                    this.$emit('updatedistributor', true)

                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao alterar dados'})
                }
                
                this.isLoadingImage = false
            },

            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },

            clearInput () {
                this.$refs['fileInput'].value=null
            },
        }
    }
</script>


<style scoped>

    .tabTitle {
        font-family: "cerapro-regular" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 25px !important;
        letter-spacing: 0em !important;
        text-align: center !important;


    }

    
    .tabTitleActive {
        font-family: "cerapro-regular" !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 30px !important;
        letter-spacing: 0.0025em !important;
        text-align: center;
        color: #312F37 !important
    }
    
    
    .brandName {
        font-family: "cerapro-regular" !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 30px !important;
        letter-spacing: 0.0025em !important;
        text-align: center !important;
    }
    
    .textCity {
        font-family: "cerapro-regular" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #6E6B7B;
    }


    .conteudo {
        background-color: #fff;
    }
</style>