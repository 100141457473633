<template>
    <div>
        <h1 class="text-center mt-10">Escolha o seu plano</h1>
        <div class="d-flex flex-wrap mt-10 mb-10 justify-center">
            <v-card
                :class="{
                    'elevation-0 pa-5 pt-1 mr-2 pb-10 mt-3 card-view': true,
                    active: i === 3,
                }"
                max-width="275"
                v-for="(p, i) in plans"
                :key="i"
            >
                <div class="pa-5 mr-2 pb-10 mt-3">
                    <h1 class="d-block text-h6 mb-3">{{ p.name }}</h1>
                    <span class="d-block text-h5 font-weight-bold"
                        >R$ {{ __formatMoney(p.price) }}</span
                    >
                    <small
                        :class="{
                            'd-block mb-5 caption': true,
                            'white--text': !!!p.legend || p.legend === '-',
                        }"
                        >{{ p.legend || "-" }}</small
                    >

                    <span
                        :class="{
                            'mt-10 d-block caption font-weight-medium': true,
                            'white--text': !!!p.type || p.type === '-',
                        }"
                        >{{ p.type || "-" }}</span
                    >
                    
                    <span v-if="parseInt(p.price) > 0">Pagamento único</span>
                    
                    <span
                        :class="{
                            'd-block caption': true,
                            'white--text': !!!p.details || p.details === '-',
                        }"
                        >{{ p.details || "-" }}</span
                    >

                    <hr class="mt-5 mb-5" />

                    <span
                        class="d-block caption grey--text text--darken-2"
                        v-for="(item, ii) in p.items"
                        :key="ii"
                    >
                        <svg
                            width="4"
                            height="4"
                            viewBox="0 0 4 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="2" cy="2" r="2" fill="#FFA900" />
                        </svg>
                        {{ item }}</span
                    >
                    <v-spacer class="mt-10"></v-spacer>

                    <v-card-actions class="justify-center">
                        <v-btn
                            style="
                                position: absolute;
                                bottom: 20px;
                                font-size: 16px;
                            "
                            @click="setPlan(i)"
                            color="#002A53"
                            class="no-uppercase white--text mt-5 pl-5 pr-5"
                            rounded
                            bottom
                            >Quero este plano</v-btn
                        >
                    </v-card-actions>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import { formatMoney } from "../utils/Helpers";

export default {
    name: "PlansCards",
    props: {
        plans: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        __formatMoney(value) {
            return formatMoney(value);
        },
        setPlan(i) {
            if (i == 0) {
                this.$router.push("/notifications");
            } else {
                this.$emit("setPlan", i);
            }
        },
    },
};
</script>

<style scoped>
.active::before {
    content:'';
    width:100%;
    height: 9px;
    position:absolute;
    left:0;
    top:0;
    border-radius: 6px 6px 0px 0px;
    background-color:  #ffa900;
}
.v-card {
    box-shadow: 2px 2px 10px rgba(204, 212, 221, 0.4) !important;
}
.card-view {
    width: 275px;
    height: 502px;
    border-radius: 6px !important;
}
</style>
