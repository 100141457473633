<template>
    <v-row>
        <v-col cols="12" class="mt-10">
            <h1 class="text-h5 font-weight-bold mb-10">Finalizar pagamento</h1>
            <span class="d-flex align-center mb-5 caption">
                Com o sistema Pagar.me
                <img class="ml-2" src="@/assets/pagarme.png" alt="pagarme.png" />
            </span>

            <v-card class="pa-4 mt-5">
                <h2 class="text-h6">Dados do cartão</h2>

                <div class="d-flex justify-space-between">
                    <v-text-field label="Nome no cartão" v-model="card.card_holder_name"></v-text-field>
                    <v-text-field class="ml-3" label="CPF" hint="Apenas números" v-model="card.cpf" persistent-hint v-mask="['###.###.###-##']"></v-text-field>
                </div>

                <div style="position:relative">
                    
                    <template v-if="card_type == 'Mastercard'">
                        <svg class="flag" width="44" height="20" viewBox="0 0 44 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M25.8002 3.7998H18.2002V16.1998H25.8002V3.7998Z" fill="#FF5F00"/> <path d="M18.9998 10.0003C18.9998 8.80031 19.2998 7.60031 19.7998 6.60031C20.2998 5.50031 21.0998 4.60031 21.9998 3.90031C20.7998 3.00031 19.3998 2.40031 17.9998 2.30031C16.4998 2.00031 14.9998 2.30031 13.6998 2.90031C12.3998 3.50031 11.2998 4.50031 10.4998 5.80031C9.6998 7.10031 9.2998 8.50031 9.2998 10.0003C9.2998 11.5003 9.6998 12.9003 10.4998 14.2003C11.2998 15.5003 12.3998 16.5003 13.6998 17.1003C14.9998 17.7003 16.4998 18.0003 17.9998 17.8003C19.4998 17.6003 20.8998 17.1003 21.9998 16.2003C21.0998 15.5003 20.2998 14.5003 19.7998 13.5003C19.2998 12.4003 18.9998 11.2003 18.9998 10.0003Z" fill="#EB001B"/> <path d="M34.7 9.99991C34.7 11.4999 34.3 12.8999 33.5 14.1999C32.7 15.4999 31.6 16.4999 30.3 17.0999C29 17.6999 27.5 17.9999 26 17.7999C24.5 17.5999 23.1 17.0999 22 16.1999C22.9 15.4999 23.7 14.4999 24.2 13.4999C24.7 12.3999 25 11.2999 25 10.0999C25 8.89991 24.7 7.69991 24.2 6.69991C23.7 5.59991 22.9 4.69991 22 3.99991C23.2 3.09991 24.6 2.49991 26 2.39991C27.5 1.99991 29 2.29991 30.3 2.89991C31.6 3.49991 32.7 4.49991 33.5 5.79991C34.3 7.09991 34.7 8.49991 34.7 9.99991Z" fill="#F79E1B"/> </svg>
                    </template>
                    
                    
                    <template v-else-if="card_type == 'Visa'">
                        
                        <svg class="flag" height="30px" width="44px" style="enable-background:new 0 0 512 512;" viewBox="0 0 512 512"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="形状_1_3_" style="enable-background:new    ;"><g id="形状_1"><g><path d="M211.328,184.445l-23.465,144.208h37.542l23.468-144.208     H211.328z M156.276,184.445l-35.794,99.185l-4.234-21.358l0.003,0.007l-0.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223     c-5.601-4.476-11.247-8.296-16.705-11.559l32.531,124.943h39.116l59.733-144.208H156.276z M302.797,224.48     c0-16.304,36.563-14.209,52.629-5.356l5.357-30.972c0,0-16.534-6.288-33.768-6.288c-18.632,0-62.875,8.148-62.875,47.739     c0,37.26,51.928,37.723,51.928,57.285c0,19.562-46.574,16.066-61.944,3.726l-5.586,32.373c0,0,16.763,8.148,42.382,8.148     c25.616,0,64.272-13.271,64.272-49.37C355.192,244.272,302.797,240.78,302.797,224.48z M455.997,184.445h-30.185     c-13.938,0-17.332,10.747-17.332,10.747l-55.988,133.461h39.131l7.828-21.419h47.728l4.403,21.419h34.472L455.997,184.445z      M410.27,277.641l19.728-53.966l11.098,53.966H410.27z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#005BAC;"/></g></g></g><g id="形状_1_2_" style="enable-background:new    ;"><g id="形状_1_1_"><g><path d="M104.132,198.022c0,0-1.554-13.015-18.144-13.015H25.715     l-0.706,2.446c0,0,28.972,5.906,56.767,28.033c26.562,21.148,35.227,47.51,35.227,47.51L104.132,198.022z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#F6AC1D;"/></g></g></g></svg>
                    </template>
                    
                    
                    <v-text-field @change="GetCardType" label="Número do cartão" v-mask="['#### #### #### ####']" v-model="card.card_number"></v-text-field>
                </div>

                <div class="d-flex justify-space-between align-center">
                    <div>
                        <span class="d-block font-weight-medium">Data de vencimento do cartão</span>
                        <div class="d-flex">
                            <v-select v-model="card.month" :items="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']" item-text="label" item-value="id" label="Mês"></v-select>
                            <v-text-field v-model="card.year" class="ml-3" label="Ano" v-mask="['##']"></v-text-field>
                        </div>
                    </div>

                    <div>
                        <span class="d-block font-weight-bold">&nbsp;</span>
                        <v-text-field label="Cód. de segurança" v-mask="['###']" v-model="card.card_cvv"></v-text-field>
                    </div>
                </div>
                
                
                <h2 class="text-h6 mt-5">Endereço de cobrança</h2>

                <div class="d-flex justify-space-between">
                    <v-text-field label="CEP" v-model="card.address.zipcode" v-mask="['##.###-###']" @change="checkCep"></v-text-field>
                </div>

                <div class="d-flex justify-space-between">
                    <v-text-field label="Endereço" v-model="card.address.street"></v-text-field>
                    <v-text-field  class="ml-3" label="Número" v-model="card.address.street_number" v-mask="['#########']"></v-text-field>
                </div>

                <div class="d-flex justify-space-between">
                    <v-text-field label="Bairro" v-model="card.address.neighborhood"></v-text-field>
                    <v-text-field class="ml-3" label="Complemento" v-model="card.address.complementary"></v-text-field>
                </div>
                
            </v-card>

            <div class="text-right mt-5">
                <v-btn 
                    class="indigo white--text" 
                    @click="onPayment"
                    :loading="isLoading"
                    :disabled="isLoading || !isValid"
                >Finalizar</v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {mapActions} from "vuex";
    import loadcep from '../utils/LoadCep'

    import {
        isValid,
        isExpirationDateValid,
        isSecurityCodeValid,
        getCreditCardNameByNumber
    } from 'creditcard.js'
    
    export default {
        name: 'PaymentView',
        props:{
            selected:{
                type:Number,
                default:0
            },
            
            plan: {
                default: null
            }
        },
        data()
        {
            return {
                isLoading: false,
                card: {
                    card_number: '',
                    card_expiration_date: '',
                    card_cvv: '',
                    card_holder_name: '',
                    cpf: '',
                    month: '',
                    year: '',
                    
                    address: {
                        street: '',
                        street_number: '',
                        complementary: '',
                        neighborhood: '',
                        zipcode: ''
                    }
                },
                
                card_type: null
            }
        },
        
        
        methods: {
            ...mapActions(['createTransactionCard']),
            async onPayment () {
                this.isLoading = true
                const card = {...this.card}
                card.card_expiration_date = `${this.card.month}${this.card.year}`
                const r = await this.createTransactionCard({plan_id: this.$route.query.plan_id, card: card})
                
                if (r) {
                    this.$emit('success')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao processar pagamento'})
                }
                this.isLoading = false
            },

            async checkCep (evt) {
                let cep = evt.replace(/\D/g, '');

                //Verifica se campo cep possui valor informado.
                if (cep != "") {

                    //Expressão regular para validar o CEP.
                    let validacep = /^[0-9]{8}$/;

                    //Valida o formato do CEP.
                    if (validacep.test(cep)) {
                        try {
                            let dados = await loadcep(cep)
                            this.card.address.street = dados.logradouro
                            this.card.address.neighborhood = dados.bairro
                        } catch (e) {
                            
                        }
                    }
                }
            },


            GetCardType(number)
            {
                this.card_type = getCreditCardNameByNumber(number)
            }
        },
        
        
        computed: {
            isValid()
            {
                if (
                    this.card.card_number 
                    && this.card.card_number.length >= 19 
                    && this.card.card_cvv 
                    && this.card.card_holder_name 
                    && this.card.cpf 
                    && this.card.month 
                    && this.card.year 
                    && this.card.card_cvv.length > 2
                    
                    && this.card.address.zipcode
                    && this.card.address.street_number
                    && this.card.address.neighborhood
                    && this.card.address.street
                ) {
                    return true
                } else {
                    return false
                }
            },

        }
    }
</script>

<style scoped>
    .flag{
        position: absolute;
        right: 0;
        bottom: 30px;
    }
</style>