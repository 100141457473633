<template>
    <v-row>
        <template v-if="billet">
            <v-col cols="12" md="9" class="mt-10 ml-lg-5">
                <h1 class="text-h5 font-weight-bold mb-10">Finalizar pagamento</h1>

                <v-card class="pa-4 mt-5">
                    <h2 class="text-h6 mb-10">Boleto bancário</h2>

                    <span class="caption d-block">Valor total</span>
                    <span class="text-h6 mb-10">R$ {{ __formatMoney(billet.amount / 100) }}</span>

                    <span class="d-block caption mt-10 font-weight-medium">Pague o boleto pelo internet banking ou faça o download do boleto</span>

                    <span class="grey--text d-block caption mt-10 font-weight-medium">Pague o boleto pelo internet banking ou faça o download do boleto</span>
                    <div class="d-flex justify-space-between mt-4">
                        <span class="grey--text">{{ billet.boleto_barcode }}</span>
                        <span class="indigo--text">Copiar</span>
                    </div>
                    <v-divider class="mb-10"></v-divider>

                    <!--<div class='text-center mb-10'>
                        <v-btn class="indigo white--text">Baixar boleto <v-icon>mdi-download</v-icon> </v-btn>
                    </div>-->
                </v-card>

                <div class="text-right mt-8 mb-10">
                    <v-btn text class="indigo--text" @click="$router.push('/')">Ir para minha página </v-btn>
                </div>
            </v-col>
        </template>
       
    </v-row>
</template>

<script>
    import {formatMoney} from "../utils/Helpers";

    export default {
        name: 'PaymentView',
        props:{
            selected:{
                type:Number,
                default:0
            },

            billet: {
                default: null
            }
        },
        data()
        {
            return {

            }
        },
        
        methods: {
            __formatMoney (value) {
                return formatMoney(value)
            }
        }
    }
</script>

<style scoped>
    .flag{
        position: absolute;
        right: 0;
        bottom: 30px;
    }
</style>