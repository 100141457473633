<template>
    <div>

        <v-dialog transition="dialog-bottom-transition" max-width="450" v-model="dialogAddObra">
            <template>
                <v-card>
                    <v-card-title class="text-h5">
                        <span v-if="editing !== null">Editar obra</span>
                        <span v-else>Adicionar obra</span>
                    </v-card-title>

                    <v-card-text>
                        <DropzoneComponent @onLeave="onLeaveObra" bgColor='transparent' :noBorder="true">

                            <template v-slot:content>
                                <v-card class="mx-auto textCardObra">
                                    
                                    <img src="/images/no-image.svg" v-if="!obra.photo" style="width: 100%">

                                    <v-img v-else height="171" :src="obra.photo"></v-img>

                                    <v-card-text>
                                        <h3 class="mt-5 mb-3 dropzone-custom-title font-weight-light grey--text text--darken-1">Arraste a foto aqui ou clique para fazer o upload da foto da obra</h3>
                                        <div class="caption text-center mt-5"><i><strong>Dica:</strong> use um arquivo PNG, <br/> quadrado, com 256x256 pixels</i></div>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </DropzoneComponent>
                    </v-card-text>


                    <v-card-text>
                        <v-col cols="12" sm="12" md="12">
                            <v-autocomplete
                                v-model="obra.city"
                                :items="cities"
                                :loading="isLoadingSearch"
                                :search-input.sync="searchCities"
                                no-filter
                                color="grey"
                                hide-no-data
                                hide-selected
                                item-text="customName"
                                item-value="id"
                                label="Busque por cidade"
                                placeholder="Busque por cidade"
                                append-icon="mdi-magnify"
                                return-object
                                item-disabled="disable"
                                outlined
                                @keydown="onSearchCities"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Nome do empreendimento"
                                placeholder="Nome do empreendimento"
                                outlined
                                v-model="obra.enterprise_name"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Geração mensal (em kWh)"
                                placeholder="Geração mensal (em kWh)"
                                outlined
                                v-model="obra.kwh_month"
                                :hint="'Apenas números'"
                                persistent-hint
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <vuetify-money v-model="obra.value_month" v-bind="moneyMask" persistent-hint />
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn v-if="editing !== null" elevation="2" large block class="white--text  elevation-0 no-uppercase" color="#002A53" @click="onAtualiza">Atualizar</v-btn>
                            <v-btn v-else elevation="2" large block class="white--text grey darken-3 elevation-0 no-uppercase" @click="onAddObra">Adicionar</v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn large block text  @click="dialogAddObra=false">Cancelar</v-btn>
                        </v-col>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <v-skeleton-loader
            type="card-avatar, article, actions"
            v-if="isLoadingObra"
            class="mt-10"
        ></v-skeleton-loader>
        
        <v-row v-else>
            
            <v-col cols="12" md="10" offset-md="1">
                <v-row class="contentGrid">
                    <v-col cols="10" md="4">
                        <CardComponent style="height: 100%" :noBorder="true">
                            <template v-slot:content>
                                <div class="grey--text text--darken-2 subtitle-1 mt-5 mb-5 pt-5 pb-5" @click="editing=null;showAddObra()">
                                    <svg class="d-block mx-auto" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M32 58.6667C46.7276 58.6667 58.6667 46.7276 58.6667 32C58.6667 17.2724 46.7276 5.33337 32 5.33337C17.2724 5.33337 5.33333 17.2724 5.33333 32C5.33333 46.7276 17.2724 58.6667 32 58.6667Z" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M32 21.3334V42.6667" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21.3333 32H42.6667" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                    <span class="d-block text-center text-h5 mt-5 mb-5 font-weight-light">Clique aqui para<br> cadastrar uma nova<br> obra instalada</span>
                                </div>
                            </template>
                        </CardComponent>
                    </v-col>
                    <v-col cols="12" md="4" v-for="(ob, index) in installer.obras" :key="index">
                        <CardComponent :image='ob.photo ? ob.photo : "/images/no-image.svg"' style="height: 100%" :imageHeight="'192px'">
                            <template v-slot:content>
                                <span class="grey--text text--darken-2 subtitle-1 d-block">{{ ob.city ? ob.city.customName : '-'}}</span>
                                <span class="black--text text--darken-2 subtitle-1 d-block font-weight-bold">{{ ob.enterprise_name }}</span>
                                <span class="grey--text text--darken-2 subtitle-1 d-block"><b>{{ ob.kwh_month }}</b> kWh de geração mensal</span>
                                <span class="grey--text text--darken-2 subtitle-1 d-block"><b>R$ {{ ob.value_month }}</b> de economia mensal</span>
                            </template>

                            <template v-slot:actionsfloat>
                                <v-btn class="white" depressed elevation="2" icon @click="editing=index;obra=installer.obras[index];dialogAddObra=true">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>

                                <v-btn class="white ml-3" depressed elevation="2" icon @click="removeObra(index)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template>
                        </CardComponent>
                    </v-col>
                </v-row>
            </v-col>
            
        </v-row>
        
       

        <v-dialog v-model="confirmationDialog" transition="dialog-top-transition" max-width="300" persistent>
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <p class="text-center mb-4" style="line-height: 1; font-size: 18px;">Tem certeza que deseja excluir essa obra?</p>
                    <v-btn elevation="2" large block text class="white--text grey darken-3 mt-5 elevation-0 mx-auto mt-3" @click="onDelete">Sim, excluir</v-btn>
                    <v-btn large block text class="mt-3" @click="confirmationDialog = false">Cancelar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
       
    </div>
</template>

<script>
    import DropzoneComponent from "@/components/DropzoneComponent/Index"
    import CardComponent from "@/components/CardComponent/Index"

    import { mapActions } from 'vuex'
    
    
    export default {
        components:{
            DropzoneComponent,
            CardComponent
        },
        data()
        {
            return {
                moneyMask:{
                    value: "00.00",
                    label: "Economia mensal (em R$)",
                    placeholder: "Economia mensal (em R$)",
                    readonly: false,
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    valueWhenIsEmpty: "",
                    options: {
                        locale: "pt-BR",
                        prefix: "R$",
                        suffix: "",
                        length: 11,
                        precision: 2
                    },
                    properties: {
                        hint: "Apenas números"
                    }
                },
                images:[],
                dialogAddObra: false,
                obra: {
                    photo: null,
                    city: null,
                    kwh_month: 0,
                    value_month: 0,
                    enterprise_name: null,
                    isLoadingSearch: false,
                    cities: [],
                    nova_imagem: null
                },
                searchCities: null,
                cities: [],
                editing:null,
                isLoadingSearch: false,
                deleting: null,
                confirmationDialog: false,
                isLoadingObra: false
            }
                
        },
        props:{
            installer: {
                type: Object
            }
        },
        methods:{
            
            ...mapActions(['getCities', 'updateObras']),
            
            onOver(){},

            
            
            async onUpdateObras () {
                this.isLoadingObra = true
                const installer = {...this.installer}

                
                
                const r = await this.updateObras(installer)

                this.$emit('updateInstaller', true)
                
                this.isLoadingObra = false
            },

            showAddObra() {
                this.cleanObra()
                this.dialogAddObra = true
            },

            removeObra (index) {
                this.confirmationDialog = true
                this.deleting = index
            },


            onDelete () {
              if (this.deleting >= 0) {
                  this.confirmationDialog = false
                  this.installer.obras_apagadas.push(this.installer.obras[this.deleting])
                  this.onUpdateObras()
                  
              } 
            },
            
            cleanObra() {
                this.obra = {
                    photo: null,
                    city_id: null,
                    kwh_month: 0,
                    value_month: 0,
                    enterprise_name: null
                }
            },
            
            
            onAddObra () {
                this.installer.obras.push(this.obra)
                this.cleanObra()

                this.dialogAddObra = false
                this.onUpdateObras()
            },

            onAtualiza () {
                
                this.cleanObra()

                this.dialogAddObra = false
                this.onUpdateObras()
            },

            async onSearchCities() {
                const query = this.searchCities
                this.isLoadingSearch = true
                if (query != "" && query) {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        const resp = await this.getCities(query)

                        this.cities = resp.data.filter(c => {
                            if (c.state) {
                                c.customName = `${c.name} (${c.state.uf})`
                            } else {
                                c.customName = c.name
                            }
                            return c
                        })

                        this.isLoadingSearch = false

                    }, 800)
                } else {
                    this.isLoadingSearch = false
                    clearTimeout(this.timerSearch);
                }
            },

            async onLeave(file) {
                this.installer.photo = await this.toBase64(file)
            },
            async onLeaveObra(file) {
                this.obra.photo = await this.toBase64(file)
                this.obra.nova_imagem = await this.toBase64(file)
            },
            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            },
        },
        
    }
</script>


<style scoped>
    
    .contentGrid {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
    }
</style>