<template>
    <v-app id="inspire">
        <AppBarTop
            :authRoutes="authRoutes"
            :mainMenu="mainMenu"
            :menuItens="menuItens"
            :drawer="drawer"
            @onDrawer="drawer = !drawer"
            @onLogout="handleLogout"
        />

        <v-navigation-drawer
            v-model="drawer"
            right
            fixed
            temporary
            class="pl-5 pr-5 pt-5"
        >
            <v-list dense nav>
                <v-list-item-title v-for="menu in mainMenu" :key="menu.title">
                    <v-list-item-content class="mt-5">
                        <v-list-item-title
                            class="font-weight-medium menu-item-app text-center black--text"
                            @click="goTo(menu.path)"
                            >{{ menu.title }}</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item-title>

                <v-btn
                    class="orange d-block mx-auto white--text elevation-0 mt-4"
                    large
                >
                    Simule agora
                    <v-icon>mdi-arrow-right</v-icon>
                </v-btn>

                <v-divider class="mt-8 mb-8"></v-divider>

                <span class="mobile-user-name d-block text-center">{{
                    getUser && getUser.name
                }}</span>
                <span class="mobile-user-email d-block text-center mt-2">{{
                    getUser && getUser.email
                }}</span>

                <v-btn
                    class="d-block mx-auto blue--text elevation-0 mt-5 mb-5 no-uppercase"
                    outlined
                    large
                    @click="$router.push('/company/profile')"
                    v-if="isLogged && getUser && getUser.user_type == 4"
                >
                    Minha página
                </v-btn>

                <span
                    @click="$router.push('/notifications')"
                    v-if="getUser && getUser.user_type != 5"
                    class="mobile-user-link d-block text-center mt-3"
                    >Notificações</span
                >

                <span
                    @click="$router.push('/profile')"
                    v-if="getUser && getUser.user_type == 4"
                    class="mobile-user-link d-block text-center mt-3"
                    >Configurações</span
                >


                <span
                    @click="$router.push('/client/profile')"
                    v-if="getUser && getUser.user_type == 3"
                    class="mobile-user-link d-block text-center mt-3"
                >Configurações</span>
                
                <a
                    @click="handleLogout"
                    class="d-block text-center text-decoration-underline mt-3"
                    >Sair</a
                >
            </v-list>
        </v-navigation-drawer>

        <v-dialog
            v-model="loading.status"
            transition="dialog-top-transition"
            max-width="600"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <h1 class="text-center mb-4">{{ loading.title }}</h1>
                        <span class="d-block text-center mt-4 mb-5">{{
                            loading.subtitle
                        }}</span>
                        <v-progress-linear
                            color="pink"
                            class="mt-5"
                            value="15"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.visible"
            :bottom="'bottom'"
            :right="'right'"
            :timeout="snackbar.timeout"
        >
            {{ snackbar.message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <Footer
            v-if="
                !authRoutes.includes($route.name) ||
                $route.name == 'result-simulator'
            "
        />
    </v-app>
</template>

<script>
import Footer from "@/Views/Footer.vue";
import AppBarTop from "@/Views/AppBarTop";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        Footer,
        AppBarTop,
    },
    data: () => ({
        mainMenu: [
            {
                title: "Empresas Solares",
                path: "https://marketsolar.com.br/empresas-solares/",
            },
            {
                title: "Energia Solar",
                path: "https://marketsolar.com.br/energia-solar/",
            },
            { title: "Notícias", path: "https://marketsolar.com.br/noticias/" },
            {
                title: "Quem somos",
                path: "https://marketsolar.com.br/quem-somos/",
            },
            { title: "Contato", path: "https://marketsolar.com.br/contato/" },
        ],
        menuItens: [
            {
                label: "Notificações",
                icon: "notifications.svg",
                href: "/notifications",
            },
            {
                label: "Simulações",
                icon: "simulations.svg",
                href: "/simulations",
                profiles: [3],
            },
            {
                label: "Orçamentos",
                icon: "budget.svg",
                href: "/budgets",
            },
            {
                label: "Pedidos",
                icon: "orders.svg",
                href: "/orders",
            },
            {
                label: "Perfil",
                icon: "profile.svg",
                href: "/company/profile",
                profiles: [4],
            },
            {
                label: "Perfil",
                icon: "profile.svg",
                href: "/distributor/profile",
                profiles: [5],
            },
            /*{
                    label:"Distribuidores",
                    icon:"distribuidor.svg",
                    href:"/distributors",
                    profiles: [4]
                }*/
        ],
        drawer: null,
        menu_list: [{ title: "Home", icon: "mdi-view-dashboard", href: "/" }],
        authRoutes: [
            "login",
            "reset",
            "createaccount",
            "simulator",
            "company",
            "plans",
            "result-simulator",
            "register-steps",
            "reset-password",
        ],
        snackbar: {
            visible: false,
            timeout: 3000,
            message: "",
            color: "light-blue darken-4",
        },
        loading: {
            title: "",
            subtitle: "",
            status: false,
        },
        simulating: false,
    }),
    mounted() {
        this.$eventHub.on(
            "snackBar",
            (config) =>
                (this.snackbar = { ...this.snackbar, ...config, visible: true })
        );
        this.$eventHub.on("loading", (loading) => (this.loading = loading));
    },
    methods: {
        ...mapActions(["logout"]),
        handleLogout() {
            this.logout();
            this.$router.push("/login");
        },
        checkProfile(menu) {
            if (menu.profiles) {
                if (
                    this.getUser &&
                    menu.profiles.includes(this.getUser.user_type)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        goToBlank(url) {
            window.open(url);
        },

        goTo(url) {
            window.location.href = url;
        },
    },
    computed: {
        ...mapGetters(["isLogged", "getUser", "getActiveStep"]),
    },
};
</script>

<style>
.mouse-pointer {
    cursor: pointer !important;
}

@font-face {
    font-family: "cerapro-regular";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: url("./assets/fonts/cera-pro/woff/CeraPro-Regular.woff") format("woff");
}

@font-face {
    font-family: "cerapro-medium";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: url("./assets/fonts/cera-pro/woff/CeraPro-Medium.woff") format("woff");
}

.v-application {
    font-family: "cerapro-medium" !important;
}
.truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
}

.font-menu {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 10px;
}

.badge-element {
    padding: 4px 8px 6px 8px;
    height: 43px;
    background: rgba(255, 169, 0, 0.15);
    border-radius: 100px;
    align-self: flex-start;
    width: auto !important;
}
.no-uppercase {
    text-transform: none !important;
}

.mobile-user-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #6e6b7b;
}
.mobile-user-email {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 145%;
    color: #6e6b7b;
}
.mobile-user-link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 145%;
    color: #0a85ff;
}
.textNoData {
    font-family: "cerapro-regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #6e6b7b;
}

.subTitles-simulator {
    font-family: "cerapro-regular" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #312f37;
}

.titles-simulator {
    font-family: "cerapro-regular" !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(49, 47, 55, 1);
    text-shadow: 0.1px 0px #000;
}

.menu-item-app {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #002a53;
}
.faixa {
    background: #002a53;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 57px;
    font-size: 16px;
    z-index: 2;
}

</style>
