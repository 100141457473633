import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons/iconfont/material-icons.css'

export const options = {
    icons: {
        iconfont: 'mdi',
    }
}

Vue.use(Vuetify)

export default new Vuetify(options)
