import Vue from 'vue'
import App from '@/App.vue'
import EventHub from 'vue-event-hub'
import VueTheMask from 'vue-the-mask'
import vuetify from '@/plugins/vuetify'
import router from '@/plugins/vuerouter'
import store from '@/plugins/store'
import Vue2Editor from "vue2-editor";
// import vueditorConfig from '@/plugins/vueditor'
import "./plugins/vuetify-money.js"
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialSharing from 'vue-social-sharing'


import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
Vue.use(Vue2Editor);
Vue.use(EventHub)
Vue.use(VueTheMask)
Vue.use(VueSocialSharing);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    },
    installComponents: true
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
