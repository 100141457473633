<template>
    <v-row no-gutters class="d-flex justify-center pa-5">
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <v-col md="6" v-else>
            <v-card elevation="0">
                <v-card-title>Redefinir senha</v-card-title>
                <v-card-text>
                    Redefina sua senha preenchendo o seu email e sua nova senha
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="E-mail"
                                v-model="user.email"
                                @keydown.enter="
                                    isLoadingReset == false &&
                                        isValidPasswords &&
                                        onSendResetPassword()
                                "
                                :rules='rules.emailRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Senha"
                                type="password"
                                v-model="user.password"
                                :type="showPassword ? 'text' : 'password'"
                                :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="showPassword = !showPassword"
                                persistent-hint
                                hint="A senha deve conter pelo menos 8 caracteres"
                                @keydown.enter="
                                    isLoadingReset == false &&
                                        isValidPasswords &&
                                        onSendResetPassword()
                                "
                                :rules='rules.passwordRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Repita a senha"
                                type="password"
                                v-model="user.password_confirmation"
                                :type="showRePassword ? 'text' : 'password'"
                                :append-icon="
                                    showRePassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="showRePassword = !showRePassword"
                                persistent-hint
                                hint="A senha deve conter pelo menos 8 caracteres"
                                @keydown.enter="
                                    isLoadingReset == false &&
                                        isValidPasswords &&
                                        onSendResetPassword()
                                "
                                :rules='rules.repasswordRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-btn
                                large
                                block
                                class="mt-5 orange white--text no-uppercase"
                                @click="onSendResetPassword"
                                :loading="isLoadingReset"
                                :disabled="isLoadingReset || !isValidPasswords"
                                >Criar nova senha</v-btn
                            >
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/login')"
                                >Voltar</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
    metaInfo: {
        title: "Recuperar senha - Market Solar",
    },
    name: "LoginPage",
    data: () => {
        return {
            rules: [],
            showPassword: false,
            tabs: null,
            showRePassword: false,
            user: {
                email: "",
                password: "",
                password_confirmation: "",
                token: "",
            },
            isLoading: true,
            isLoadingReset: false,
        };
    },
    async mounted() {
        this.isLoading = true;
        const r = await this.checkTokenReset(this.$route.params.token);
        if (!r) {
            this.$router.push("/");
        }
        this.user.token = this.$route.params.token;
        this.isLoading = false;
    },
    methods: {
        ...mapActions(["checkTokenReset", "restorePassword"]),

        async onSendResetPassword() {
            this.isLoadingReset = true;
            const user = Object.assign(this.user);

            if (this.user.password.length <= 7) {
                this.isLoadingReset = false;
                return this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "A senha precisa conter pelo menos 8 caracteres",
                });
            }

            const r = await this.restorePassword(user);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Senha atualizada com sucesso",
                });
                this.$router.push("/login");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao resetar senha",
                });
            }
            this.isLoadingReset = false;
        },

        validate() {
            this.rules = {
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /.+@.+/.test(v) || "Insira um e-mail válido"
                ],
                "passwordRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 8 || "Sua senha deve ter ao menos 8 caracteres"
                ],
                "repasswordRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => this.user.password == this.user.password_confirmation || "As senhas digitadas não são iguais, você precisa repetir a mesma senha nos dois campos. Certifique-se de que está digitando corretamente sua senha."
                ]
            }
        },

        clearRules(){
            this.rules = []
        }
    },

    computed: {
        isValidPasswords() {
            if (this.user.password.length > 7) {
                //&& this.user.password.match(/[0-9]+/) && this.user.password.match(/[a-z]+/)
                if (
                    this.user.password &&
                    this.user.password_confirmation &&
                    this.user.password == this.user.password_confirmation
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
};
</script>
