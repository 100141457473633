<template>
    <div>
        <v-dialog
            v-model="printing"
            persistent
            width="300"
            overlay-color="#000"
            overlay-opacity="0.95"
            >
            <v-card
                color="white"
                dark
            >
                <v-card-text class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    class="mt-10 mb-5"
                ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
        <div v-if="!isLoading" class="float-banner hide">
            <h1>Valor do investimento</h1>
            <span>R$ {{ __formatMoney(simulator.budget_total) }}</span>
            <v-btn
                large
                class="d-block white--text elevation-0 mt-3 py-2 px-3"
                style="background: #002a53; border-radius: 4px"
                @click="talkTo"
            >
                <span class="white--text subtitle-1"
                    >Falar com consultor
                    <v-icon class="white--text">mdi-arrow-right</v-icon></span
                ></v-btn
            >
        </div>

        <div v-if="isLoading" class="text-center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <div v-else>
            <template v-if="simulator">
                <v-row class="mt-10 ml-4">
                    <v-col class="text-right mt-5 pr-10 col-12">
                        <div class="float-right">
                            <v-btn class="btn_download" elevation="0" @click="showBtnShare = true">
                                <v-icon color="#0066CC">mdi-share-variant</v-icon>
                            </v-btn>
                            <v-btn class="btn_download" elevation="0" @click="printSimulation">
                                <v-icon color="#0066CC">mdi-download</v-icon>
                            </v-btn>
                        </div>
                        <template v-if="this.showBtnShare">
                            <div class="float-right div_share">
                                <ShareNetwork
                                    v-for="net in networks"
                                    :key="net.network"
                                    :network="net.network"
                                    :url="sharing.url"
                                    :title="sharing.title"
                                    :description="sharing.title"
                                    class="btn_share"
                                    :style="{backgroundColor: net.background}"
                                    elevation="0"
                                >
                                    <v-icon :style="{color: net.color}">{{net.icon}}</v-icon>
                                </ShareNetwork>

                                <v-btn class="btn_share" elevation="0" @click="copyUrl" color="#FFFFFF">
                                    <v-icon color="#004D99">mdi-link</v-icon>
                                </v-btn>
                                <v-btn outlined class="btn_share" elevation="0" @click="showBtnShare = false" color="#004D99" style="margin-left: 8px;margin-right: 0px;">
                                    <v-icon color="#002A53">mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-col>
                </v-row>
                <div ref="content" id="content">
                    <v-row class="ml-4 pr-10">
                        <v-col cols="12" class="mb-10">
                            <h1 class="text-center mb-5 mt-10">
                                Resultado da simulação
                            </h1>

                            <v-col cols="12">
                                <div
                                    class="d-flex justify-center"
                                    style="height: 130px; margin-bottom: 100px"
                                    v-if="!resizing"
                                >
                                    <SliderBar
                                        @onLabel="handleLabel"
                                        @onValue="handleValue"
                                        @onRealValue="handleRealValue"
                                        :width="width"
                                        :init-value="simulator.more_energy"
                                        :disabled="
                                            (simulator.budget ? true : false) ||
                                            isLoadingUpdate
                                        "
                                    />
                                </div>
                            </v-col>

                            <!-- <p
                                style="color: #8a8797; font-size: 24px"
                                class="d-block font-weight-medium titleAumentoEnergia text-center"
                            >
                                Você planeja aumentar seu consumo de energia?
                            </p>
                            <p
                                style="
                                    max-width: 744px;
                                    font-size: 16px;
                                    color: #6e6b7b;
                                    font-weight: normal;
                                "
                                class="text-justify mx-auto mt-1 textAumentoEnergia"
                            >
                                Pense no futuro! Talvez você use mais o ar
                                condicionado, compre novos aparelhos ou vai carregar
                                seu carro elétrico. Como projetos solares têm
                                garantia de até 25 anos, recomendamos que você
                                invista em um projeto com maior capacidade de
                                geração. Arraste a barra para simular outros casos.
                            </p>
                            <v-col
                                style="width: 100%; max-width: 600px"
                                class="mx-auto mt-5 progress-bar"
                            >
                                <div>
                                    <v-slider
                                        color="orange"
                                        dense
                                        track-color="grey"
                                        v-model="moreEnergy"
                                        max="100"
                                        min="0"
                                        :disabled="
                                            (simulator.budget ? true : false) ||
                                            isLoadingUpdate
                                        "
                                        @change="changeMoreEnergy"
                                        :step="10"
                                    >
                                    </v-slider>

                                    <div
                                        class="d-flex justify-space-between grey--text"
                                        style="margin-top: -16px"
                                    >
                                        <span>0%</span>
                                        <span style="position: relative"
                                            ><span class="float-badge"
                                                >Recomendado</span
                                            >+20%</span
                                        >
                                        <span>+40%</span>
                                        <span>+60%</span>
                                        <span>+80%</span>
                                        <span>+100%</span>
                                    </div>
                                </div>
                            </v-col> -->
                        </v-col>

                        <v-col
                            cols="12"
                            lg="6"
                            class="border--right mb-5 custom-mt"
                        >
                            <InvestimentView
                                :investment="simulator.budget_total"
                                :payback="simulator.return_on_investment / 12"
                            />
                        </v-col>

                        <v-col cols="12" lg="6" class="pl-lg-5 mb-5 mt-md-10">
                            <MonthlySavingsView
                                :total_economy="simulator.monthly_economy * 12 * 25"
                                :first_year="simulator.monthly_economy * 12"
                                :economy_first_year="[]"
                                :monthly_economy="simulator.monthly_economy"
                                :isLoadingUpdate="isLoadingUpdate"
                            />
                        </v-col>
                    </v-row>

                    <v-row cols="12" style="background: #e1e9f0" class="pb-5 pl-4">
                        <v-col cols="12" lg="6" class="mt-5">
                            <EnergyProductionView
                                :annual_production="
                                    simulator.monthly_generated_energy * 12
                                "
                                :potency="simulator.monthly_generated_energy"
                            />
                        </v-col>

                        <v-col cols="12" lg="5">
                            <SolarPanelsView
                                :panels_standard="simulator.panels_standard"
                                :panels_high_performance="
                                    simulator.panels_high_performance
                                "
                            />
                        </v-col>
                    </v-row>

                    <v-row cols="12" style="background: #e1e9f0" class="pl-4">
                        <v-col cols="12">
                            <ConsumptionView
                                :water_saved="simulator.water_scarcity"
                                :co2="simulator.co2"
                            />
                        </v-col>

                        <v-col cols="12">
                            <TreesView :planted_trees="simulator.planted_trees" />
                        </v-col>

                        <v-col cols="12">
                            <EquipmentsView />
                        </v-col>

                        <v-col cols="12">
                            <DurabilityView />
                        </v-col>
                    </v-row>
                </div>
                
                <v-row
                    cols="12"
                    style="background: #ffa90066"
                    class="pb-5 pt-5 pl-4"
                    v-if="!getUser.user_type == 3 && !simulator.budget"
                >
                    <v-col cols="12">
                        <InterestedView />
                    </v-col>
                </v-row>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import InvestimentView from "../../Views/Investment.vue";
import MonthlySavingsView from "../../Views/MonthlySavings.vue";
import EnergyProductionView from "../../Views/EnergyProduction.vue";
import SolarPanelsView from "../../Views/SolarPanels.vue";
import InterestedView from "../../Views/Interested.vue";
import ConsumptionView from "../../Views/Consumption.vue";
import TreesView from "../../Views/Trees.vue";
import EquipmentsView from "../../Views/Equipments.vue";
import DurabilityView from "../../Views/Durability.vue";
import StepsSimulator from "../../Views/StepsSimulator.vue";
import SliderBar from "../../components/SliderBar/Index.vue";
import { formatMoney } from "../../utils/Helpers";
import jsPDF from 'jspdf';
import domtoimage from "dom-to-image";
import VueSocialSharing from 'vue-social-sharing'

let debounce = null;

export default {
    metaInfo: {
        title: "Resultado da simulação - Market Solar",
    },
    name: "Result",
    components: {
        InvestimentView,
        MonthlySavingsView,
        EnergyProductionView,
        SolarPanelsView,
        InterestedView,
        ConsumptionView,
        TreesView,
        EquipmentsView,
        DurabilityView,
        StepsSimulator,
        SliderBar,
    },
    data() {
        return {
            isLoading: true,
            moreEnergy: 0,
            simulator: null,
            first_year: 0,
            width: 300,
            isLoadingUpdate: false,
            resizing: false,
            showBtnShare: false,
            urlShare: Object.keys(this.$route.query).length > 0 ? window.location.href + '&shared=true' : window.location.href + '?shared=true',
            printing: false,
            sharing: {
                url: Object.keys(this.$route.query).length > 0 ? window.location.href + '&shared=true' : window.location.href + '?shared=true',
                title: '',
                description: ''
            },
            networks: [
                { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', background: '#219653', color: '#FFFFFF' },
                { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', background: '#0066CC', color: '#FFFFFF' },
                { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', background: '#0066CC', color: '#FFFFFF' },
                { network: 'email', name: 'Email', icon: 'mdi-email-outline', background: '#0A85FF', color: '#FFFFFF' }
            ]
        };
    },
    async mounted() {
        this.handleResize();

        this.isLoading = true;
        if (this.$route.params.id && this.$route.params.id > 0) {
            const simulator = await this.showSimulatorComplete(
                this.$route.params.id
            );

            if (simulator) {
                if (simulator.consumption_month >= 20000) {
                    this.$router.push({
                        path: "/talktoconsultant",
                        query: { simulator: this.$route.params.id },
                    });
                }
                this.moreEnergy = simulator.more_energy;
                this.simulator = simulator;

                if (!simulator.budget) {
                    document.querySelector(".faixa").classList.remove("d-none");
                }
            }
        }
        this.isLoading = false;

        this.setStepSimulation(3);

        document.addEventListener("scroll", this.handleScrollView);
        window.addEventListener("resize", this.handleResize);
    },
    destroyed() {
        document.removeEventListener("scroll", this.handleScrollView);
        window.removeEventListener("resize", this.handleResize);
        document.querySelector(".faixa").classList.add("d-none");
    },
    methods: {
        ...mapActions([
            "showSimulatorComplete",
            "updateSimulator",
            "setStepSimulation",
        ]),
        handleResize() {
            this.resizing = true;
            this.width = window.innerWidth > 650 ? 648 : 300;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
                this.resizing = false;
            }, 500);
        },
        __formatMoney(value) {
            return formatMoney(value);
        },
        talkTo() {
            this.$router.push({
                path: "/talktoconsultant",
                query: { simulator: this.$route.params.id },
            });
        },
        handleScrollView() {
            document
                .querySelector(".float-banner")
                .classList[
                    this.isElementInViewport(
                        document.querySelector("#monthlysavings")
                    )
                        ? "remove"
                        : "add"
                ]("hide");
        },
        isElementInViewport(el) {
            const rect = el.getBoundingClientRect();

            return (
                window.innerWidth <= 500 &&
                ((rect.top <= 0 && rect.bottom >= 0) ||
                    (rect.bottom >=
                        (window.innerHeight ||
                            document.documentElement.clientHeight) &&
                        rect.top <=
                            (window.innerHeight ||
                                document.documentElement.clientHeight)) ||
                    (rect.top >= 0 &&
                        rect.bottom <=
                            (window.innerHeight ||
                                document.documentElement.clientHeight)))
            );
        },
        printSimulation(){
            console.log("print aqui");
            this.printing = true;
            
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                document.getElementsByClassName('visible')[0].style.top = '5%';
                document.getElementsByClassName('chart')[0].style.marginBottom = '200px';
            }else{
                document.getElementsByClassName('visible')[0].style.top = '8%';
            }

            domtoimage.toPng(this.$refs.content).then(function(dataUrl) {
                var img = new Image();
                img.src = dataUrl;

                const doc = new jsPDF({
                    orientation: "portrait",
                    unit: "px",
                    format: [window.innerWidth, document.getElementById('content').clientHeight]
                });
                
                const w = doc.internal.pageSize.getWidth();
                const h = doc.internal.pageSize.getHeight();

                doc.addImage(img, "JPEG", 0, 0, w, h);

                const date = new Date();
                const filename =
                    "simulation_" +
                    ("0" + date.getDate()).slice(-2) + "_" +
                    ("0" + (date.getMonth() + 1)).slice(-2) + "_" +
                    date.getFullYear() + "_" +
                    ("0" + date.getHours()).slice(-2) + "_" +
                    ("0" + date.getMinutes()).slice(-2) + "_" +
                    ("0" + date.getSeconds()).slice(-2) + "_" +
                    ".pdf";

                doc.save(filename);
            }).catch(function(error) {
                console.error("oops, something went wrong!", error);
            });

            setTimeout(()=>{
                this.printing = false;
                document.getElementsByClassName('visible')[0].style.top = '';
                document.getElementsByClassName('chart')[0].style.marginBottom = '';
            }, 3000)
        },
        copyUrl(){
            let inputTest = document.createElement("input");
            inputTest.value = Object.keys(this.$route.query).length > 0 ? window.location.href + '&shared=true' : window.location.href + '?shared=true',

            document.body.appendChild(inputTest);
            
            inputTest.select();

            document.execCommand('copy');

            document.body.removeChild(inputTest);
        },
        async handleValue(v) {
            //console.log('EPA', +v);
        },

        handleRealValue (v) {
            this.changeMoreEnergy(v)
        },
        
        async handleLabel(v) {
            //console.log(v);
        },
        async changeMoreEnergy(val) {
            this.isLoadingUpdate = true;

            this.simulator.more_energy = val;
            this.simulator.recalculate = true;
            const r = await this.updateSimulator(this.simulator);
            
            if (r) {
                this.simulator = r;
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Simulação alterada com sucesso",
                });
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao alterar simulação",
                });
            }

            this.isLoadingUpdate = false;
        },


        shareSimulation () {
            if (navigator.share) {
                if (navigator.share) {
                    navigator.share({
                        title: 'Simulação da Simulação',
                        text: '',
                        url: window.location.href,
                    })
                        .then(() => console.log('Successful share'))
                        .catch((error) => {
                            console.log(error)
                            this.$eventHub.$emit("snackBar", {
                                color: "error",
                                message: "Seu navegador não possui função de compartilhamento",
                            });
                        });
                }
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Seu navegador não possui função de compartilhamento",
                });
            }
            
        }
    },
    computed: {
        ...mapGetters(["isLogged", "getUser", "getActiveStep"]),
    },
};
</script>

<style>
@media screen and (min-width: 1260px) {
    .border--right {
        border-right: 1px solid #d9d7e067;
    }
}

.v-slider--horizontal .v-slider__track-container {
    height: 9px !important;
}
.v-slider__track-background.grey {
    border-radius: 100px !important;
    background: #eae9ec !important;
}
.v-slider__track-fill.orange {
    border-radius: 100px !important;
    background-color: #ffa900 !important;
    opacity: 0.4;
}
.v-slider__thumb {
    width: 23px !important;
    height: 23px !important;
}
.float-badge {
    position: absolute;
    left: -23px;
    bottom: -30px;
    background: #eae9ec;
    border-radius: 100px;
    padding: 2px 6px;
    color: #8a8797;
    font-size: 12px;
}

.v-slider__thumb:before {
    opacity: 0 !important;
}

.showMobile {
    display: none;
}

.titleAumentoEnergia {
    text-align: center;
}

.progress-bar {
    position: absolute;
    left: 0px;
}

.custom-mt {
    margin-top: 100px;
}
.float-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffdd99;
    height: 190px;
    z-index: 5;
    padding: 30px 50px;
    transition: opacity 400ms;
}
.float-banner h1 {
    font-weight: 500;
    font-size: 20px;
    color: #312f37;
}
.float-banner span {
    color: #312f37;
    font-weight: bold;
    font-size: 28px;
}
.hide {
    opacity: 0;
    pointer-events: none;
}
.btn_download{
    width: 52px !important;
    min-width: 52px !important;
    height: 52px !important;
    background: #FFFFFF !important;
    padding: 14px !important;
}
.div_share{
    display: flex;
    background-color: #EAE9EC;
    padding: 7px;
    border-radius: 100px;
    margin-right: 32px;
}
.btn_share{
    display: inline-grid;
    min-width: 38px !important;
    height: 38px !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
    border-radius: 100px !important;
    margin-right: 8px;
    padding: 0px !important;
}

@media screen and (min-width: 600px) {
    .progress-bar {
        position: initial;
    }
    .custom-mb {
        margin-top: 0px;
    }
}

@media screen and (max-width: 1263px) {
    .showMobile {
        display: block !important;
    }
}

@media screen and (max-width: 960px) {
    .textAumentoEnergia {
        display: none !important;
    }

    .titleAumentoEnergia {
        text-align: left !important;
    }
}
</style>
