<template>
    <main
        class="d-block mx-auto d-flex flex-column justify-center mt-5 wm-488 pa-5"
    >
        <h1>Entrar</h1>

        <span class="mt-5 mb-2">Selecione seu perfil</span>

        <div
            class="badge-element mt-3 mb-5 d-flex justify-space-between"
            style="width: 100% !important"
        >
            <v-btn
                rounded
                elevation="0"
                v-for="(type, i) in userType"
                :key="i"
                :class="{
                    'no-uppercase': true,
                    orange: active.name === type.name,
                    transparent: !(active.name === type.name),
                    'white--text': active.name === type.name,
                    'grey--text text--darken-1': !(active.name === type.name),
                }"
                @click="active = type"
            >
                {{ type.name }}
            </v-btn>
        </div>

        <v-text-field
            class="mt-4 mb-4"
            label="E-mail"
            v-model="user.email"
            @keydown.enter="onLogin"
        ></v-text-field>

        <div style="position: relative">
            <v-text-field
                :append-icon="
                    showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                :type="showPassword ? 'text' : 'password'"
                name="input-10-2"
                label="Senha"
                @click:append="showPassword = !showPassword"
                v-model="user.password"
                class="mb-3"
                @keydown.enter="onLogin"
            ></v-text-field>
            <span
                @click="$router.push('/reset')"
                class="blue--text text--darken-4"
                style="position: absolute; bottom: 0px; right: 0px"
                >Recuperar senha</span
            >
        </div>

        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            @click="onLogin"
            large
            block
            elevation="0"
            class="mt-5 orange white--text mb-15 no-uppercase"
            >Entrar</v-btn
        >

        <hr class="grey--text text-lighten-4" />
        <span
            class="grey--text mx-auto pl-2 pr-2 text-center"
            style="width: 200px; margin-top: -15px; background: #fafafa"
            >Não tem uma conta?</span
        >

        <v-btn
            @click="goCreateAccount"
            large
            block
            elevation="0"
            class="mt-15 blue darken-4 white--text mb-5 no-uppercase"
            >Criar Conta</v-btn
        >
    </main>
</template>

<script>
import { mapActions } from "vuex";

export default {
    metaInfo: {
        title: "Entrar - Market Solar",
    },
    name: "CreateAccount",
    data() {
        return {
            showPassword: false,
            user: {
                email: "",
                password: "",
            },
            isLoading: false,
            active: { name: "Cliente final", id: 3 },
            userType: [
                { name: "Cliente final", id: 3 },
                { name: "Empresa solar", id: 4 },
                { name: "Distribuidor", id: 5 },
            ],
        };
    },
    methods: {
        ...mapActions(["login"]),
        async onLogin() {
            this.isLoading = true;

            const user = { ...this.user };

            if (
                this.$route.query.simulator &&
                this.$route.query.simulator > 0
            ) {
                user.simulator_id = this.$route.query.simulator;
            }

            user.type = this.active.id;

            if (await this.login(user)) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Login efetuado com sucesso",
                });
                if (
                    this.$route.query.simulator &&
                    this.$route.query.simulator > 0
                ) {
                    this.$router.push(
                        "/result-simulator/" + this.$route.query.simulator
                    );
                } else {
                    if (user.type == 5) {
                        this.$router.push({ path: "/distributor/profile" });
                    } else {
                        this.$router.push("/budgets");
                    }
                }
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Usuário ou senha inválido",
                });
            }

            this.isLoading = false;
        },

        goCreateAccount() {
            if (
                this.$route.query.simulator &&
                this.$route.query.simulator > 0
            ) {
                this.$router.push({
                    path: "/createaccount",
                    query: { simulator: this.$route.query.simulator },
                });
            } else {
                this.$router.push("/createaccount");
            }
        },
    },
};
</script>

<style scoped>
.wm-488 {
    width: 100%;
    max-width: 488px;
}

.badge-element {
    padding: 2px 4px 3px 4px;
    height: 43px;
    width: 100%;
    background: rgba(255, 169, 0, 0.15);
    border-radius: 100px;
    align-self: flex-start;
}
.badge-element button {
    font-size: 10px;
}

@media screen and (min-width: 450px) {
    .badge-element {
        padding: 4px 8px 6px 8px;
    }
    .badge-element button {
        font-size: 14px;
    }
}

.no-uppercase {
    text-transform: none;
}
</style>
