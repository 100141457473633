<template>
    <v-row class="conteudo">
        
        <v-dialog v-model="dialog" max-width="600px">
            <v-card v-if="installer">
                <v-card-title>
                    <span class="text-h5">Logo da empresa</span>
                </v-card-title>

                <template v-if="!isLoadingImage">
                    <v-card-text>
                        <img :src="installer.image" alt="Logo" class="d-block mx-auto mt-10" style="width: 140px"/>
                        <h1 class="text-center text-h6 mt-10 font-weight-bold">{{ installer.company_name }}</h1>
                        <span class="d-block text-center caption mb-10">{{ installer.city ? installer.city.name : '' }}, {{ installer.state ? installer.state.uf : '' }}</span>
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <div style="cursor: pointer" @click="$refs['fileInput'].click()">
                            <v-icon class="d-block text-center">mdi-square-edit-outline</v-icon>
                            <div class="caption">Atualizar logo</div>
                            <input type="file" @change="onLeave" ref="fileInput" v-show="false">
                        </div>

                        <v-spacer></v-spacer>
                        <div>
                            <v-icon class="d-block text-center">mdi-trash-can-outline</v-icon>
                            <div class="caption">Deletar</div>
                        </div>
                    </v-card-actions>
                </template>
                <template v-else>
                    <v-card-text class="text-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-card-text>
                </template>
            </v-card>
        </v-dialog>

        <template v-if="isLoadingInit">
            <v-card-text class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-card-text>
        </template>
        
        <template v-else>
            <template v-if="installer && installer.constructions.length == 0 && showModal">
                <v-col cols="12" md="2" offset-md="1" class="mt-10">
                    <div class="modal_perfil">
                        <h1 class="modal_text">Complete seu perfil</h1>
                        <span>Cadastre as <u>obras realizadas</u> por sua empresa e ganhe mais visibilidade no nosso site.</span>

                        <div class="div_buttons">
                            <v-btn 
                                class="btn_cad"
                                elevation="0"
                                @click="cadObra()"
                            >
                                <span id="span_btn">Cadastrar</span>
                                <v-icon dense color="white">mdi-arrow-right</v-icon>
                            </v-btn>

                            <a id="span_close" @click="showModal=false;">Fazer depois</a>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="" v-if="installer">
                    <img @click="dialog=true" :src="installer.image" alt="Logo" class="d-block mx-auto mt-10" width="240px;"/>

                    <p class="text-center mt-4 mb-0 brandName">{{ installer.trading_name }}</p>
                    <span class="d-block text-center mt-0 mb-10 textCity">{{installer.city ? installer.city.name : ''}}, {{ installer.state ? installer.state.uf : '' }}</span>

                </v-col>
            </template>

            <template v-else>
                <v-col cols="12" md="10" class="mx-auto" v-if="installer">
                    <img @click="dialog=true" :src="installer.image" alt="Logo" class="d-block mx-auto mt-10" width="240px;"/>

                    <p class="text-center mt-4 mb-0 brandName">{{ installer.trading_name }}</p>
                    <span class="d-block text-center mt-0 mb-10 textCity">{{installer.city ? installer.city.name : ''}}, {{ installer.state ? installer.state.uf : '' }}</span>

                </v-col>
            </template>
            <v-tabs fixed-tabs v-model="active" centered background-color="#FAFAFA">
                <v-tabs-slider color="orange"></v-tabs-slider>
                <v-tab v-for="(n,i) in menuItens" :key="i">
                    <span :class="`tabTitle ${i == active ? 'tabTitleActive' : ''} no-uppercase`">{{n.label}}</span>
                </v-tab>
            </v-tabs>
            
            
            
            <v-col>

                <v-skeleton-loader
                    type="card-avatar, article, actions"
                    v-if="isLoading"
                    class="mt-10"
                ></v-skeleton-loader>
                <v-tabs-items v-else v-model="active" class="mt-10 mb-10">
                    <v-tab-item  class="mt-10 mb-10">
                        <About :installer="installer" @updateInstaller="load"/>
                    </v-tab-item>
                    <v-tab-item  class="mt-10 mb-10">
                        <Workers :installer="installer" @updateInstaller="load" ref="ComponentWorkers"/>
                    </v-tab-item>
                    <v-tab-item  class="mt-10 mb-10">
                        <Contact :installer="installer" @updateInstaller="load"/>
                    </v-tab-item>
                    <v-tab-item  class="mt-10 mb-10">
                        <Avaliations :installer="installer" @updateInstaller="load"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </template>
    </v-row>
</template>


<script> 
    import { mapActions } from 'vuex'
    import About from "./About/Index.vue"
    import Contact from "./Contact/Index.vue"
    import Workers from "./Workers/Index.vue"
    import Avaliations from "./Avaliations/Index.vue"

    export default {
        metaInfo: {
            title: 'Minha empresa - Market Solar',
        },
        components:{
            About,
            Contact,
            Workers,
            Avaliations,
        },
        data()
        {
            return {
                active:0,
                dialog:false,
                installer: null,
                isLoading: false,
                showModal: true,
                menuItens:[
                    {
                        label:"Sobre",
                        icon:"mdi-bell-outline",
                        href:"/about"
                    },
                    {
                        label:"Obras realizadas",
                        icon:"mdi-account-outline",
                        href:"/workers-performed"
                    },
                    {
                        label:"Contato",
                        icon:"mdi-file-document-edit-outline",
                        href:"/contact"
                    },
                    {
                        label:"Avaliações",
                        icon:"mdi-text-box-outline",
                        href:"/avaliations"
                    }
                ],
                isLoadingImage: false,
                isLoadingInit: true
            }
        },
        
        async mounted() {
            this.isLoadingInit = true
            
            await this.loadInstaller()
            
            this.isLoadingInit = false
        },

        methods:{
            ...mapActions(['getProfileInstaller', 'updateInstallers']),
            
            async load () {
                this.isLoading = true


                await this.loadInstaller()

                this.isLoading = false
            },
            
            async loadInstaller () {
                let installer = await this.getProfileInstaller()

                installer.brands_ids = installer.brands_installer.map(b => {
                    return b.id
                })

                installer.obras = installer.constructions.map(o => {
                    o.nova_imagem = null


                    if (o.city) {
                        o.city.customName = `${o.city.name}  (${o.state.uf})`
                    }

                    return o
                })

                installer.photos = installer.images;
                installer.obras_apagadas = []

                installer.imagens_apagadas = [];


                installer.stateCity = [];

                for (let stateCity of installer.regions) {
                    let d = {};
                    if (stateCity.city_id) {
                        d = stateCity.city;
                        d.customName = `${d.name} (${d.state.uf})`;
                        d.isCity = true;
                        d.selected = true;
                        d.customId = `city-${d.id}`;
                    } else {
                        d = stateCity.state;
                        d.customName = `${d.name} (Estado)`;
                        d.isCity = false;
                        d.selected = true;
                        d.customId = `state-${d.id}`;
                    }
                    installer.stateCity.push(d);
                }

                for (let i in installer.images) {
                    installer.images[i].nova_imagem = null;
                }



                this.installer = installer

            },


            async onLeave(e) {
                this.isLoadingImage = true
                this.installer.image = await this.toBase64(e.target.files[0])
                const r = await this.updateInstallers(this.installer)

                if (r) {
                    this.dialog = false
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Dados alterados com sucesso'})

                    this.$emit('updateInstaller', true)

                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao alterar dados'})
                }
                
                this.isLoadingImage = false
            },

            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },

            cadObra(){
                this.active = 1;
                this.showModal = false;

                //this.$refs['ComponentWorkers'].onAddObra();
            },

            clearInput () {
                this.$refs['fileInput'].value=null
            },
        }
    }
</script>


<style scoped>

    .tabTitle {
        font-family: "cerapro-regular" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 25px !important;
        letter-spacing: 0em !important;
        text-align: center !important;


    }


    .tabTitleActive {
        font-family: "cerapro-regular" !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 30px !important;
        letter-spacing: 0.0025em !important;
        text-align: center;
        color: #312F37 !important
    }


    .brandName {
        font-family: "cerapro-regular" !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 30px !important;
        letter-spacing: 0.0025em !important;
        text-align: center !important;
    }

    .textCity {
        font-family: "cerapro-regular" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #6E6B7B;
    }

    .conteudo {
        background-color: #fff;
    }

    .modal_perfil{
        width: 305px;
        height: 240px;
        background: #0066CC;
        border-radius: 4px;
        z-index: 999;
        padding: 20px;
        color: #fff;
    }

    .modal_perfil>h1{
        font-family: "cerapro-regular";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }

    .modal_perfil>span{
        font-family: "cerapro-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23.2px;
    }

    .div_buttons{
        display: flex;
        align-items: center;
        flex-direction: row;
        align-content: center;
        flex-wrap: nowrap;
        margin-top: 50px;
    }

    .btn_cad{
        width: 116px;
        height: 44px !important;
        background-color: #FFA900 !important;
        border-radius: 4px;
        color: #fff;
        padding: 12px 16px !important;
    }

    #span_btn{
        font-family: "cerapro-regular";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
    }

    #span_close{
        font-family: "cerapro-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #FFFFFF;
        margin-left: 40px;
    }
</style>