<template>
    <v-container class="px-10" fluid>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div style="width: 100%; height: 100%" v-else>

            <template v-if="items.length > 0">
                <h2 class="mb-10 mt-10" style="font-size: 40px;">Pedidos</h2>
    
                <v-card 
                    class="mt-8 elevation-1 hovercard events-none" 
                    v-for="(item, indexItem) in items" 
                    :key="indexItem"
                    @click="$router.push('/orderdetails/' + item.id)"
                    style="border-left:3px solid #0066CC"
                >
                <v-simple-table class="cardDesktop">
                    <template v-slot:default>
                        <tbody>
                            <tr class="hovercard">
                                <td>
                                    <p>Status</p>
                                    <p class="blue--text text--darken-4" :style="`max-width:100px; ${item.finished ? 'color: #219653 !important' : ''}`">{{ item.finished ? "Pedido finalizado" : item.step ? item.step.title : '-' }}</p>
                                    
                                    <div class="contentLoadStep">
                                        <div
                                            class="loadStep"
                                            v-for="step in steps"
                                            :style="`backgroundColor: ${item.finished ? '#219653' : item.step.order > step.order ? '#219653' : item.step.order == step.order ? '#0066CC' : '#9F9DAA'}`"
                                        ></div>
                                    </div>
                                </td>

                                


                                <td v-if="getUser.user_type == 3">
                                    <p>Empresa solar</p>
                                    
                                    <p>
                                        
                                        <v-avatar 
                                            color="blue darken-4" 
                                            size="32"
                                            v-if="item.installer.url"
                                        >
                                            <img
                                                :src="item.installer.url"
                                            >
                                        </v-avatar>

                                        <v-avatar
                                            color="blue darken-4"
                                            size="32"
                                            v-else
                                        >
                                            <span class="white--text caption">{{ getIniciais(item.installer.company_name) }}</span>
                                        </v-avatar>
                                        
                                        {{ item.installer.company_name }}
                                    </p>
                                </td>

                                <td v-if="getUser.user_type == 4">
                                    <p>Cliente</p>
                                    <p>
                                        <v-avatar color="blue darken-4" size="32"><span class="white--text caption">{{ item.budget && item.budget.client ? getIniciais(item.budget.client.name) : '' }}</span></v-avatar>
                                        {{ item.budget && item.budget.client ? item.budget.client.name : '' }}
                                    </p>
                                </td>

                                
                                <td>
                                    <p>Consultor</p>
                                    <p>
                                        <v-avatar color="blue darken-4" size="32"><span class="white--text caption">{{ getIniciais(item.budget.consultant.name) }}</span></v-avatar>
                                        {{ item.budget.consultant.name }}
                                    </p>
                                </td>
                                
                                <td>
                                    <p>Endereço</p>
                                    <p class="truncate-text">{{ item.budget.simulator.address }}</p>
                                </td>

                                <td>
                                    <p>Capacidade de geração</p>
                                    <p>{{ item.budget_installer.kwh }} kWh</p>
                                </td>

                                <td>
                                    <p>Orçamento</p>
                                    <p>R$ {{ _formatMoney(item.budget_installer.total) }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>


                <div class="cardMobile pa-3">
                    <p class="blue--text text--darken-4" :style="`${item.finished ? 'color: #219653 !important' : ''}`">{{ item.finished ? "Pedido finalizado" : item.step ? item.step.title : '-' }}</p>
                    <div class="contentLoadStep">
                        <div
                            class="loadStep"
                            v-for="step in steps"
                            :style="`backgroundColor: ${item.finished ? '#219653' : item.step.order > step.order ? '#219653' : item.step.order == step.order ? '#0066CC' : '#9F9DAA'}`"
                        ></div>
                    </div>


                    <template v-if="getUser.user_type == 4">
                        <p class="mb-0 mt-5" style="color: #8A8797;">Cliente</p>
                        <p>
                            <v-avatar color="blue darken-4" size="32"><span class="white--text caption">{{ item.budget && item.budget.client ? getIniciais(item.budget.client.name) : '' }}</span></v-avatar>
                            {{ item.budget && item.budget.client ? item.budget.client.name : '' }}
                        </p>
                    </template>


                    <template v-if="getUser.user_type == 3">
                        <p class="mb-0 mt-5" style="color: #8A8797;">Empresa solar</p>

                        <p>
                            <v-avatar
                                color="blue darken-4"
                                size="32"
                                v-if="item.installer.url"
                            >
                                <img
                                    :src="item.installer.url"
                                >
                            </v-avatar>

                            <v-avatar
                                color="blue darken-4"
                                size="32"
                                v-else
                            >
                                <span class="white--text caption">{{ getIniciais(item.installer.company_name) }}</span>
                            </v-avatar>

                            {{ item.installer.company_name }}
                        </p>
                    </template>



                    <p class="mb-0 mt-5" style="color: #8A8797;">Endereço</p>
                    <p class="truncate-text">{{ item.budget.simulator.address }}</p>


                    <p class="mb-0 mt-5" style="color: #8A8797;">Capacidade de geração</p>
                    <p>{{ item.budget_installer.kwh }} kWh</p>


                    <p class="mb-0 mt-5" style="color: #8A8797;">valor estimado</p>
                    <p>R$ {{ _formatMoney(item.budget_installer.total) }}</p>
                    
                    
                </div>
            </v-card>
                
            </template>

            <div v-else class="d-flex justify-center align-center align-content-center flex-column" style="width: 100%; height: 100%">
                <img src="/images/inbox-2.svg">
                <p class="text-center mt-3 textNoData">Ops, não há <b>Pedidos</b> <br> para apresentar aqui...</p>
            </div>
        </div>

        <v-row class="mt-5" v-if="totalPage > 1">
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import dayjs from 'dayjs'
    import { getResidenceType, formatMoney } from './../../utils/Helpers'

    export default {
        metaInfo: {
            title: 'Meus Pedidos - Market Solar',
        },
        data()
        {
            return {
                dialog:false,
                isLoading: false,
                items: [],
                page: 1,
                totalPage: 1,
                steps: []
            }
        },
        mounted() 
        {
            this.load()
        },
        methods: {
            ...mapActions(['listOrders']),
            async load (page = 1) 
            {
                this.isLoading = true
                const filter = {}
                filter.page = page
                filter.installer_selected = true
                const resp = await this.listOrders(filter)
                if (resp) {
                    this.steps = resp.steps
                    this.items = resp.data
                    this.totalPage = resp.last_page
                }

                this.isLoading = false;
            },
            _dayJs (data) 
            {
                return dayjs(data)
            },
            _getResidenceType (id) 
            {
                return getResidenceType(id)
            },
            _formatMoney (value) 
            {
                return formatMoney(value)
            },

            getIniciais (nameString) {
                if(!nameString){
                    return ""
                }
                try {
                    const fullName = nameString.split(' ');
                    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                    return initials.toUpperCase();
                } catch (e) {
                    return nameString.substring(0,2).toUpperCase()
                }
            },
        },

        computed: {
            ...mapGetters(['getUser'])
        }
    }
</script>

<style scoped>
    .hovercard:hover {
        background: #F5F9FE !important;
    }
    .hovercard td{
        padding:20px !important;
    }
    
    main{
        background-color: #fafafa !important;
    }
    .contentLoadStep {
        display: flex;
    }
    
    .loadStep {
        background-color: #9F9DAA; 
        margin-right: 5px; 
        height: 5px !important; 
        width: 12px !important;
        border-radius: 3px;
    }



    .cardMobile {
        display: none !important;
    }

    .cardDesktop {
        display: block;
    }

    @media screen and (max-width: 960px) {

        .cardMobile {
            display: block !important;
        }

        .cardDesktop {
            display: none !important;
        }
    }
</style>
