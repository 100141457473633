<template>
    <v-row class="mt-2">
        <v-col cols="12" class="pt-0 mt-0 mx-auto content-status-order"> 
            <v-card class="pb-5 mb-10">
                <v-tabs id="tabs_status" background-color="#F4F4F6" icons-and-text height="117px" :hide-slider="true" class="pt-0 mb-10 hideMobile" v-model="tabModel">
                    <v-tab 
                        v-for="(step,i) in order.steps" 
                        :key="i" 
                        :disabled="checkDisabled(step.order, order.step_id)"
                        @click="changeStep(step)"
                        :ref="`step${i}`"
                        width="128px"
                    >

                        <template v-if="checkDisabled(step.order, order.step_id)">
                            <span class="no-uppercase" :style="`color: #6E6B7B`">{{step.title}}</span>

                             <div 
                                :style="`
                                    width: 39px;
                                    height: 39px;
                                    background-color: #6E6B7B;
                                    -webkit-mask: url(/images/status_order/${step.icon}) no-repeat center;
                                    mask: url(/images/status_order/${step.icon}) no-repeat center;
                                `"
                            ></div>

                        </template>
                        
                         <template v-else>
                            <span class="no-uppercase" :style="`color: ${ step.id == order.step_id ? '#0066cc' : '#219653' }`">{{step.title}}</span>



                             <div 
                                :style="`
                                    width: 39px;
                                    height: 39px;
                                    background-color: ${ step.id == order.step_id ? '#0066cc' : '#219653'  }  ;
                                    -webkit-mask: url(/images/status_order/${step.icon}) no-repeat center;
                                    mask: url(/images/status_order/${step.icon}) no-repeat center;
                                `"
                            ></div>
                        </template>
                        
                       
                        
                    </v-tab>
                </v-tabs>
                <v-card class="pl-10 ml-5 pr-10 elevation-0">
                    <div class="justify-space-between rounded-0 mb-10 hideMobile" :style="`border-bottom:2px solid ${ stepSelected.id == order.step_id ? '#0066cc' : '#219653' }; padding-bottom: 15px; display: flex`">
                        <h1 class="text-h6 text--darken-4" :style="`color: ${ stepSelected.id == order.step_id ? '#0066cc' : '#219653' };`">{{ stepSelected ? stepSelected.title : '-' }}</h1>
                        <div>
                            <span>
                                <img src="/images/icon-chat.svg" width="15px"> 
                                <span style="font-weight: 500; font-size: 14px; font-family: Roboto !important; margin-left: 5px">{{ stepSelected ? stepSelected.posts.length : 0 }}</span> 
                            </span>
                            <span class="ml-3">
                                <img src="/images/icon-clip.svg" width="15px"> 
                                <span style="font-weight: 500; font-size: 14px; font-family: Roboto !important; margin-left: 5px">{{ stepSelected ? countFiles(stepSelected.posts) : 0 }}</span>
                            
                            </span>
                        </div>
                    </div>

                    <v-select
                        :items="order.steps"
                        item-value="id"
                        item-text="title"
                        v-model="stepSelected"
                        @change="changeStep"
                        return-object
                        
                        class="showMobile"
                    ></v-select>
                    
                    

                    <div id="contentPosts" ref="contentPosts" class="content-scroll" v-if="stepSelected">
                        <v-list>
                            <template v-for="(item, index) in stepSelected.posts">


                                <v-list-item
                                    :key="index"
                                    class="itemlist"
                                    style="align-items: initial !important;"
                                    v-if="item.deleted_at"
                                >


                                    <v-list-item-avatar class="alignSelf" color="blue darken-4" v-if="item.user && item.user.type == 3 || item.user.type == 1">
                                        <span class="white--text text-h5">
                                            {{ item.user ? getIniciais(item.user.username) : '-' }}
                                        </span>
                                    </v-list-item-avatar>


                                    <v-list-item-avatar class="alignSelf" v-else>
                                        <v-img :src="getImage(item.user)"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="itemlisttitle">{{ getTypeUser(item.user.type) }}</v-list-item-title>
                                        <p class="itemlistname">{{ item.user ? item.user.username : '-' }}</p>


                                        <v-list-item-subtitle style="margin-top: 10px; color: rgba(189, 189, 189, 1); justify-content: flex-end; display: flex; width: 100%">
                                            Mensagem excluída em {{ __dayjs(item.deleted_at).format('DD/MM/YYYY HH:mm') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    
                                </v-list-item>
                                
                                
                                
                                <v-list-item
                                    :key="index"
                                    class="itemlist"
                                    style="align-items: initial !important;"
                                    v-else
                                >
                                    
                                    
                                    <v-list-item-avatar class="alignSelf" color="blue darken-4" v-if="item.user && item.user.type == 3 || item.user.type == 1">
                                        <span class="white--text text-h5">
                                            {{ item.user ? getIniciais(item.user.username) : '-' }}
                                        </span>
                                    </v-list-item-avatar>
                                        
                                    
                                    <v-list-item-avatar class="alignSelf" v-else>
                                        <v-img :src="getImage(item.user)"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="itemlisttitle">{{ getTypeUser(item.user.type) }}</v-list-item-title>
                                        <p class="itemlistname">{{ item.user ? item.user.username : '-' }}</p>
                                        
                                        <p class="listitemdescription" v-html="item.description"></p>
                                        
                                        <p v-if="item.file">
                                            <v-chip
                                                class="ma-2 chipfile"
                                                outlined
                                                @click="onShowFileOrderPosts(item)"
                                                :disabled="isLoadingFile"
                                            >
                                                <v-icon left>
                                                    mdi-tray-arrow-down
                                                </v-icon>
                                                {{ item.file.split('/')[2] }}
                                            </v-chip>
                                        </p>
                                        
                                        <v-list-item-subtitle style="margin-top: 10px; color: rgba(189, 189, 189, 1);" v-if="item.created_at == item.deleted_at">
                                            {{ __dayjs(item.created_at).format('DD/MM/YYYY HH:mm') }}
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle style="margin-top: 10px; color: rgba(189, 189, 189, 1);" v-else>
                                            Mensagem editada em {{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                        </v-list>
                    </div>

                    <div class="d-flex justify-space-between rounded-0 mb-10 mt-5" v-if="!order.finished">
                        <div style="width:100%;" id="contentMessagePost">


                            <vue-editor
                                v-model="messagePost"
                                placeholder="Digite aqui sua mensagem"
                            />
                            
                            <template v-if="fileSelected">
                                <v-chip
                                    class="ma-2 chipfile"
                                    outlined
                                >
                                    {{ fileSelected }}
                                </v-chip>
                                <v-btn
                                    fab
                                    small
                                    outlined
                                    color="#e0e0e0"
                                    @click="clearInput"
                                    icon
                                >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template>
                           
                        </div>
                        
                        
                        <input type="file" ref="fileInput" @change="onLeave" v-show="false">
                        <div style="width:150px">
                            <v-btn 
                                @click="$refs['fileInput'].click()" 
                                icon
                                :loading="isLoadingPost"
                                :disabled="isLoadingPost"
                                small
                                width="52px"
                                height="52px"
                                style="margin-left: 10px; background-color: rgba(194, 224, 255, 0.3); color: #002A53;"
                            ><v-icon>mdi-attachment</v-icon>
                            </v-btn>
                            
                            <v-btn 
                                @click="onSendPost"
                                icon
                                :loading="isLoadingPost"
                                :disabled="isLoadingPost || (!this.fileSelected && !this.messagePost)"
                                width="52px"
                                height="52px"
                                style="margin-left: 10px; background-color: #0066CC; color: #fff !important;"

                            ><v-icon>mdi-send</v-icon> </v-btn>
                        </div>
                    </div>
                </v-card>
                
                <v-card-text class="contentStepControl" v-if="getUser.user_type == 4 && !order.finished">
                    <template v-if="!order.is_after_sales">
                        <v-btn
                            class="mb-3 no-uppercase"
                            text
                            @click="onSendPreviousStep"
                            :disabled="isLoadingStep || !isNotFirstStep(order.step_id)"
                            :loading="isLoadingStep"
                        >
                            <v-icon class="iconStepControl">mdi-arrow-left</v-icon> Retroceder uma etapa
                        </v-btn>

                        <v-btn
                            v-if="order.last_step"
                            height="50"
                            class="mb-3 no-uppercase textWhite"
                            color="#219653"
                            rounded
                            @click="onSendFinishOrder"
                            :disabled="isLoadingStep"
                            :loading="isLoadingStep"
                        >
                            Finalizar pedido
                        </v-btn>

                        <v-btn
                            height="50"
                            class="mb-3 no-uppercase textWhite"
                            color="#219653"
                            rounded
                            @click="onSendNextStep"
                            :disabled="isLoadingStep"
                            :loading="isLoadingStep"
                            v-else
                        >Avançar para próxima etapa <v-icon class="iconStepControl">mdi-arrow-right</v-icon></v-btn>

                        <p id="titleControl" class="mb-3 no-uppercase">Tudo certo com esta etapa?</p>
                    </template>
                    
                
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>



<script>
    
    import { mapActions, mapGetters } from 'vuex'
    
    import dayjs from "dayjs";

    export default {
        props: {
            order: {
                default: null
            }
        },
        data(){
            return {
                stepSelected: null,
                tabModel: null,
                messagePost: null,
                filePost: null,
                isLoadingPost: false,
                isLoadingFile: false,
                fileSelected: null,
                isLoadingStep: false
            }
        },
        
        mounted() {
           
            if (this.order.step_id) {
                this.stepSelected = this.getStepById(this.order.step_id)
                this.tabModel = parseInt(this.getStepIndexById(this.order.step_id))

            }

            this.scrollBottomChat()
        },


        methods: {
            ...mapActions(['insertPostStep', 'showFileOrderPosts', 'nextStepOrder', 'previousStepOrder', 'sendFinishOrder']),
            scrollBottomChat () {
                const el = this.$refs.contentPosts

                if (el) {
                    setTimeout(() => {
                        el.scrollTop = el.scrollHeight;
                    }, 100)
                }
            },

            isNotFirstStep (step_id) {

                let firstArray = null

                for (let step of this.order.steps) {

                    if (firstArray) {
                        if (firstArray.ordem > step.ordem) {
                            firstArray = step
                        }
                    } else {
                        firstArray = step
                    }
                }


                if (firstArray && firstArray.id != step_id) {
                    return true
                }

                return false
            },
            

            changeStep (step) {
                this.stepSelected = step
                this.tabModel = parseInt(this.getStepIndexById(step.id))
                this.scrollBottomChat()
            },
            
            clearInput () {
                this.$refs['fileInput'].value=null
                this.fileSelected = null
            },
            
            async onSendPost () {
                
                
                this.isLoadingPost = true
                const post = {
                    description: this.messagePost,
                    step_id: this.stepSelected.id,
                    order_id: this.order.id,
                    file: this.filePost
                }

                const r = await this.insertPostStep(post)
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Post inserido com sucesso'})
                    this.messagePost = null
                    this.order.steps[this.getStepIndexById(this.stepSelected.id)].posts.push(r)
                    this.filePost = null
                    this.clearInput()
                    this.scrollBottomChat()

                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao inserir post'})
                }

                this.isLoadingPost = false
            },
            
            checkDisabled (ordem, step_id) {
                let orderAtual = null
                for (let step of this.order.steps) {
                    if (step_id == step.id) {
                        orderAtual = step.order
                    }
                }

                if (ordem > orderAtual) {
                    return true
                }
                return false
            },
            
            getStepById (step_id) {
                for (let step of this.order.steps) {
                    if (step_id == step.id) {
                        return step
                    }
                }
                return null
            },

            getStepIndexById (step_id) {
                for (let index in this.order.steps) {
                    if (step_id == this.order.steps[index].id) {
                        return index
                    }
                }
                return null
            },

            __dayjs (data) {
                return dayjs(data)
            },

            getTypeUser (type) {
                if (type == 1) {
                    return 'Administrador'
                } else if (type == 2) {
                    return 'Consultor'
                } else if (type == 3) {
                    return 'Cliente'
                } else if (type == 4) {
                    return 'Empresa solar'
                } else {
                    return '-'
                }
            },
            
            getIniciais (nameString) {
                if(!nameString){
                    return ""
                }
                try {
                    const fullName = nameString.split(' ');
                    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                    return initials.toUpperCase();
                } catch (e) {
                    return nameString.substring(0,2).toUpperCase()
                }
            },
            
            getImage (user) {
                if (user.installer) {
                    return user.installer.url
                } else if(user.consultant) {
                    return user.consultant.url
                }
            },

            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            },

            async onLeave( e )
            {
                this.fileSelected = e.target.files[0].name
                this.filePost = await this.toBase64(e.target.files[0])
            },

            countFiles (posts) {
                let cont = 0
                
                for (let post of posts) {
                    if (post.file) cont++
                }
                
                return cont
            },


            downloadBase64File(contentType, base64Data, fileName) {
                const linkSource = `data:${contentType};base64,${base64Data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            },

            async onShowFileOrderPosts (item) {
                
                
                this.isLoadingFile = true
                const r = await this.showFileOrderPosts(item.id);
                if (r) {
                    this.downloadBase64File('', r.file, r.name)
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao baixar arquivo'})
                }
                this.isLoadingFile = false
            },


            async onSendPreviousStep () {
                this.isLoadingStep = true
                const r = await this.previousStepOrder(this.order.id)
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Pedido atualizado com sucesso'})
                    this.order.step_id = r.step_id
                    this.order.last_step = r.last_step
                    
                    this.changeStep(this.order.steps[this.getStepIndexById(r.step_id)])
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao atualizar pedido'})
                }

                this.isLoadingStep = false
            },

            async onSendFinishOrder () {
                this.isLoadingStep = true
                const r = await this.sendFinishOrder(this.order.id)
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Pedido atualizado com sucesso'})
                    this.order.step_id = r.step_id
                    this.order.finished = r.finished

                    this.changeStep(this.order.steps[this.getStepIndexById(r.step_id)])
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao atualizar pedido'})
                }

                this.isLoadingStep = false
            },

            async onSendNextStep () {
                this.isLoadingStep = true
                const r = await this.nextStepOrder(this.order.id)
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Pedido atualizado com sucesso'})
                    this.order.step_id = r.step_id
                    this.order.last_step = r.last_step
                    this.order.is_after_sales = r.is_after_sales
                    this.order.after_sales_days = r.after_sales_days
                    
                    
                    this.changeStep(this.order.steps[this.getStepIndexById(r.step_id)])
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao atualizar pedido'})
                }

                this.isLoadingStep = false
            },
        },
        
        computed: {
            ...mapGetters(['getUser'])
        }
    }
</script>


<style>

    .content-status-order .v-tabs--icons-and-text>.v-tabs-bar .v-tab {
        width: 128px;
    }


    .content-status-order .v-slide-group__prev {
        display: none !important;
    }

    #contentMessagePost .quillWrapper .ql-snow.ql-toolbar {
        display: none !important;
    }


    #contentMessagePost .quillWrapper {
        width: 100%;
        height: 100px;
    }

    #contentMessagePost .ql-container.ql-snow {
        border: 0px !important;
    }

    #tabs_status > .v-tabs-bar > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tab--active{
        background-color: #FFF !important;
    }
</style>

<style scoped>
    
    .content-scroll{
        overflow-x: auto;
        max-height: 600px;
        padding-right: 20px;
    }



    .content-scroll::-webkit-scrollbar {
        width: 8px;
    }

    .content-scroll::-webkit-scrollbar-track {
        
    }

    .content-scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(244, 244, 246, 1)
    }
    
    
    .itemlisttitle {
        font-family: 'cerapro-medium' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.06em;
        text-align: left;
        color: rgba(110, 107, 123, 1);
    }
    
    
    .itemlistname {
        font-family:  'cerapro-regular' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(110, 107, 123, 1);    
    }
    
    .listitemdescription {
        font-family:  'cerapro-regular' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 20px;
    }
    
    
    .chipfile {
        color: #2F80ED;
        border-color: #2F80ED;
        border-style: dotted;
        cursor: pointer;
        margin-left: 0px !important;
    }
    .alignSelf {
        align-self: initial !important;
    }


    .hideMobile {
        display: block;
    }

    .showMobile {
        display: none !important;
    }

    .contentStepControl {
        display: flex;
        justify-content: space-between;
    }
    
    .contentStepControl #titleControl {
        display: none;
    }
    
    .textWhite {
        color: #FFF !important;
    }

    @media screen and (max-width: 960px) {

        .hideMobile {
            display: none !important;
        }

        .showMobile {
            display: flex !important;
        }


        .contentStepControl {
            flex-direction: column-reverse;
        }
        
        .contentStepControl .iconStepControl {
            display: none;
        }

        .contentStepControl #titleControl {
            display: block;
            text-align: center !important;
            font-family: "cerapro-regular";
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.0025em;
            color: #312F37;
        }

    }
</style>