import Http from '@/plugins/Http'

export default {

    async getBudget ({}, params = {}) {
        const { data } = await Http.get(`/budget`, { params })
        return data
    },
    
    async insertBudget ({}, body) {
        const { data } = await Http.post(`/budget`, body)
        return data
    },

    async showBudgetInstaller ({}, id) {
        const { data } = await Http.get(`/budget-installer/` + id)
        return data
    },
}
