<template>
    <div>
        <h1 class="d-block text-center font-weight-regular mt-4 text-h5 font-weight-regular">Se interessou? A Market Solar te ajuda!</h1>
        <v-btn
            elevation="2" 
            large 
            rounded 
            class="d-block mx-auto mt-5 indigo darken-4 white--text pl-10 pr-10 mb-4"
            @click="talkTo"
        >Falar com consultor</v-btn>
    </div>
</template>


<script>
    export default {
        name: 'InterestedView',
        methods:{
            talkTo()
            {
                this.$router.push({ path: '/talktoconsultant', query: { simulator: this.$route.params.id }})
            }
        }
    }
</script>