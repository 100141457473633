<template>
    <v-card :width="width" class="elevation-1 grey lighten-5 position-relative" :style="{border: noBorder ? 'none' : '1px dashed #d3d3d3'}">
        <v-img v-if="image" :src="image" :style="`height: ${imageHeight}`"></v-img>

        <slot name="image"></slot>
        
        <v-card-text>
            <slot name="content"></slot>
        </v-card-text>

        <v-card-actions class="card--actions">
            <slot name="actionsfloat"></slot>
        </v-card-actions>
    </v-card>
</template>


<script>
    export default {
        name:'CardComponent',
        props: {
            image:{
                type:String,
                default()
                {
                    return ''
                }
            },
            width:{
                type:String,
                default: null
            },

            imageHeight:{
                type:String,
                default: null
            },
            noBorder:{
                type:Boolean,
                default: false
            },
        },
    }
</script>

<style>
    .card--actions{
        position: absolute;
        bottom: -24px;
        right: 22px;
    }
</style>