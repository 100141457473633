<template>
    <main
        class="d-block mx-auto d-flex flex-column justify-center mt-5 wm-488 pa-5"
    >
        <h1>Recuperar senha</h1>

        <span class="mt-5 mb-2">Selecione seu perfil</span>

        <div
            class="badge-element mt-3 mb-5 d-flex justify-space-between"
            style="width: 100% !important"
        >
            <v-btn
                rounded
                elevation="0"
                v-for="(type, i) in userType"
                :key="i"
                :class="{
                    'no-uppercase': true,
                    orange: active.name === type.name,
                    transparent: !(active.name === type.name),
                    'white--text': active.name === type.name,
                    'grey--text text--darken-1': !(active.name === type.name),
                }"
                @click="active = type"
            >
                {{ type.name }}
            </v-btn>
        </div>

        <v-text-field
            class="mt-4 mb-4"
            label="E-mail"
            v-model="user.email"
            @keydown.enter="onSend"
        ></v-text-field>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            @click="onSend"
            large
            block
            elevation="0"
            class="mt-5 orange white--text mb-5"
            >Enviar</v-btn
        >
    </main>
</template>

<script>
import { mapActions } from "vuex";

export default {
    metaInfo: {
        title: "Recuperar senha - Market Solar",
    },
    name: "ResetPassword",
    data() {
        return {
            showPassword: false,
            user: {
                email: "",
            },
            isLoading: false,
            active: { name: "Cliente final", id: 3 },
            userType: [
                { name: "Cliente final", id: 3 },
                { name: "Empresa solar", id: 4 },
                { name: "Distribuidor", id: 5 },
            ],
        };
    },
    methods: {
        ...mapActions(["sendRestorePassword"]),
        async onSend() {
            this.isLoading = true;

            if (
                await this.sendRestorePassword({
                    ...this.user,
                    type: this.active.id,
                })
            ) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Link enviado com sucesso",
                });
                this.$router.push("/login");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao enviar link",
                });
            }

            this.isLoading = false;
        },
    },
};
</script>

<style scoped>
.wm-488 {
    width: 100%;
    max-width: 488px;
}

.badge-element {
    padding: 4px 8px 6px 8px;
    height: 43px;
    width: 100%;
    background: rgba(255, 169, 0, 0.15);
    border-radius: 100px;
    align-self: flex-start;
}
.no-uppercase {
    text-transform: none;
}
</style>
