import Http from "@/plugins/Http";

export default {
    async listOrders({}, params = {}) {
        const { data } = await Http.get(`/orders`, { params });
        return data;
    },
    async showOrder({}, id) {
        const { data } = await Http.get(`/orders/${id}`);
        return data;
    },


    async listOrderBudgetInstalers({}, id) {
        const { data } = await Http.get(`/orders/${id}/budget-installers`);
        return data;
    },
    
    async showFileBudgetInstaller ({}, id) {
        try {
            const { data } = await Http.get(`/orders/${id}/budget-installers/showFile`);
            return data;
        } catch (e) {
            return false
        }
       
    },
    
    
    async insertBudgetInstaller({}, budget) {
        try {
            
            
            const { data } = await Http({
                method: "post",
                url: `/budget-installer`,
                headers:{ 'Content-Type': 'multipart/form-data' },
                data: budget
            })
            
            return data
        } catch (e) {
            console.log('AAA', e)
            return false
        }
       
    },


    async updateBudgetInstaller({}, { budget, id }) {
        try {


            const { data } = await Http({
                method: "post",
                url: `/budget-installer/` + id,
                headers:{ 'Content-Type': 'multipart/form-data' },
                data: budget
            })

            return data
        } catch (e) {
            console.log('AAA', e)
            return false
        }

    },

    async getBudgetInstaller({}, id) {
        const { data } = await Http.get(`/budget-installer/${id}`);
        return data;
    },
    
    
    async selectInstallerBudget ({}, id) {
        try {
            await Http.post(`/select-budget-installer/${id}`);
            return true
        } catch (e) {
            return false
        }
        
    },

    async insertPostStep({}, post) {
        try {
            const { data } = await Http.post(`/orders-post`, post);
            return data;
        } catch (e) {
            return false
        }
    },

    async sendRejectBudget ({}, post) {
        try {
            const { data } = await Http.post(`/budget-installer/rejected`, post);
            return data;
        } catch (e) {
            return false
        }
       
    },
    
    
    async showFileOrderPosts ({}, id) {
        try {
            const { data } = await Http.get(`/orders-post/${id}/showFile`);
            return data;
        } catch (e) {
            return false
        }
    },

    async nextStepOrder({}, order_id) {
        try {
            const { data } = await Http.post(`/orders-next-step/${order_id}`);
            return data;
        } catch (e) {
            return false
        }
    },

    async previousStepOrder({}, order_id) {
        try {
            const { data } = await Http.post(`/orders-previous-step/${order_id}`);
            return data;
        } catch (e) {
            return false
        }
    },
    
    
    async sendFinishOrder ({}, order_id) {
        try {
            const { data } = await Http.post(`/orders-finish/${order_id}`);
            return data;
        } catch (e) {
            return false
        }
    },


    async sendRate ({}, order) {
        try {
            const { data } = await Http.post(`/orders-rate`, order);
            return data;
        } catch (e) {
            return false
        }
    }

}
