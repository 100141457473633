<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-app id="inspire" class="grey lighten-2">
            <v-navigation-drawer app color="#F2F2F2" right class="pa-5 v-navigation-drawer__component" width="320" permanent>
                <h1 class="font-weight-regular grey--text text--darken-2 mt-4 d-flex h6 mb-5">Regiões atendidas <v-chip class="ml-2 caption">{{count}}</v-chip></h1>
                
                
                
                
                
                <v-checkbox 
                    v-for="(item,i) in selecteds" 
                    :key="i" 
                    :label="`${item.customName}`" 
                    class="font-weight-regular grey--text text--darken-2 ma-0 pa-0"
                    v-model="item.selected"
                ></v-checkbox>
                
                
                <div class="panel__actions">
                    <v-btn large class="white--text grey darken-3 elevation-0" @click="onSave">Salvar</v-btn>
                    <v-btn large class="grey--text darken-3 ml-2 elevation-0" @click="$emit('onClose')">Cancelar</v-btn>
                </div>
                
                
                
                
            </v-navigation-drawer>

            <v-content style="background-color: #e0e0e0 !important">
                <v-row flat class="grey lighten-2">
                    <v-col cols="12">
                        <v-toolbar flat color="grey lighten-2">
                            <v-toolbar-title>
                                <v-btn icon @click="$emit('onClose')"><v-icon>mdi-arrow-left</v-icon></v-btn>
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-col>

                    <v-col cols="9" class="pl-5">
                        <v-autocomplete
                            v-model="model"
                            class="ml-5"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="search"
                            no-filter
                            color="grey"
                            hide-no-data
                            hide-selected
                            item-text="customName"
                            item-value="id"
                            label=""
                            placeholder="Informe o endereço de busca"
                            return-object
                            @change="onSelect"
                            item-disabled="disable"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-content>
        </v-app>
    </v-dialog>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name:'PlacesComponent',
        props:{
            dialog:{
                type: Boolean,
                default: false
            },
            initSelecteds: {
                type: Array,
                default: () => []
            }
        },
        data(){
            return {
                descriptionLimit: 60,
                entries: [],
                isLoading: false,
                model: null,
                search: null,
                citiesOrState: [],
                items: [],
                timerSearch: null,
                selecteds: [],
                finalSelecteds: []
            }
        },
        computed: {
            count () {
                let total = 0;
                
                for (let item of this.selecteds) {
                    if (item.selected) {
                        if (item.isCity) {
                            total++
                        } else {
                            if (item.total_cities) {
                                total += item.total_cities
                            }
                        }
                    }
                }
                
                
                return total
            }
        },
        watch: {
            async search (query) 
            {
                this.isLoading = true
                if (query != "" && query) {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        const resp = await this.cityOrState( query )
                        //this.count     = count
                        const items = []

                        if (resp.states && resp.states.length > 0) {
                            resp.states.map(state => {
                                state.customName = `${state.name} (Estado)`
                                state.isCity = false
                                state.selected = true
                                state.customId = `state-${state.id}`
                            })
                            for (let state of resp.states) {
                                let exists = false
                                for (let index in this.selecteds) {
                                    if (this.selecteds[index].customId == state.customId) {
                                        state.disable = true
                                        exists = true
                                        break
                                    }
                                }
                                if (!exists) state.disable = false
                                
                                
                                items.push(state)
                            }
                        }
                        
                        
                        if (resp.cities && resp.cities.length > 0) {
                            resp.cities.map(city => {
                                city.customName = `${city.name} (${city.state.uf})`
                                city.isCity = true
                                city.selected = true
                                city.customId = `city-${city.id}`
                            })
                            for (let city of resp.cities) {

                                let exists = false
                                for (let index in this.selecteds) {
                                    if (this.selecteds[index].customId == city.customId) {
                                        city.disable = true
                                        exists = true
                                        break
                                    } else if (!this.selecteds[index].isCity) {
                                        if (city.state.id == this.selecteds[index].id) {
                                            city.disable = true
                                            exists = true
                                        }
                                    }
                                }
                                if (!exists) city.disable = false
                                
                                
                                items.push(city)
                            }
                        }
                        this.items   = items
                        this.isLoading = false
                    }, 1000);
                } else {
                    this.isLoading = false
                    clearTimeout(this.timerSearch);
                }
            }
        },
        
        mounted() {
          if (this.initSelecteds.length > 0) {
              this.selecteds = this.initSelecteds
          }  
        },

        methods:{
            ...mapActions(['cityOrState']),

            onSelect (event) {
                
                if (!event.isCity) {
                    let filter = this.selecteds.filter(s => {
                        if (s.isCity) {
                            if (parseInt(s.state.id) == parseInt(event.id)) {
                                
                            } else {
                                return s
                            }
                        } else {
                            return s
                        }
                    })
                    
                    filter.push(event)
                    this.selecteds = filter
                } else {
                    this.selecteds.push(event)
                }
                
                this.$nextTick(() => {
                    this.model = null
                    this.search = ''
                    this.items = []
                });
            },
            onSave () {
                const resp = this.selecteds.filter(s => {
                    if (s.selected) return s
                })
                this.$emit('onSave', resp)
                this.$emit('onClose')
            }
        }
    }
</script>

<style scoped>
    .panel__actions{
        position:absolute;
        bottom:20px;
    }
</style>