import Http from '@/plugins/Http'

export default {
    async getProfiledistributor ({}) {
        try {
            const {data} = await Http.get('/profile-distributor')
            
            return data
        } catch (e) {
            return false            
        }
    },


    async updateDistributors ({}, distributor) {
        try {
            await Http.put('/distributor/' + distributor.id, distributor)
            return true
        } catch (e) {
            return false
        }
    },
}
