<template>
    <v-container class="fill-height px-10" fluid>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div style="width: 100%; height: 100%" v-else>
            <template v-if="items.length > 0">
                <h2 class="mb-10 mt-10" style="font-size: 40px;">Simulações</h2>

                <v-card class="mt-8 elevation-1 hovercard" v-for="(item, indexItem) in items" :key="indexItem" @click="$router.push('/result-simulator/' + item.id + '?old=true')">
                    <v-simple-table class="cardDesktop">
                        <template v-slot:default>
                            <tbody>
                            <tr class="hovercard">
                                <td>
                                    <p>Data</p>
                                    <p>{{ _dayJs(item.created_at).format('DD/MM/YYYY') }}</p>
                                </td>

                                <td>
                                    <p>Instalação</p>
                                    <p>{{ _getResidenceType(item.residenceType) }}</p>
                                </td>

                                <td>
                                    <p>Cidade</p>
                                    <p>{{ item.city.name }}, {{ item.city.state.uf }}</p>
                                </td>

                                <td>
                                    <p>Capacidade de geração</p>
                                    <p class="truncate-text">{{ item.monthly_generated_energy }} kWh</p>
                                </td>

                                <td>
                                    <p>Valor estimado</p>
                                    <p>R$ {{ _formatMoney(item.budget_total) }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    
                    
                    <div class="cardMobile pa-3">
                      
                        <p class="mb-0" style="color: #8A8797;">Data</p>
                        <p>{{ _dayJs(item.created_at).format('DD/MM/YYYY') }}</p>

                 
                        <p class="mb-0 mt-5" style="color: #8A8797;">Instalação</p>
                        <p>{{ _getResidenceType(item.residenceType) }}</p>
                  
                        <p class="mb-0 mt-5" style="color: #8A8797;">Cidade</p>
                        <p>{{ item.city.name }}, {{ item.city.state.uf }}</p>

                  
                        <p class="mb-0 mt-5" style="color: #8A8797;">Capacidade de geração</p>
                        <p>{{ item.monthly_generated_energy }} kWh</p>

                   
                        <p class="mb-0 mt-5" style="color: #8A8797;">Valor estimado</p>
                        <p>R$ {{ _formatMoney(item.budget_total) }}</p>
                    </div>
                </v-card>
            </template>
            
            <div v-else class="d-flex justify-center align-center align-content-center flex-column" style="width: 100%; height: 100%">
                <img src="/images/inbox-2.svg">
                <p class="text-center mt-3 textNoData">Ops, não há <b>Simulações</b> <br> para apresentar aqui...</p>
            </div>
        </div>

        <v-row class="mt-5" v-if="totalPage > 1">
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapActions } from 'vuex'
    
    import dayjs from 'dayjs'
    
    import { getResidenceType, formatMoney } from './../../utils/Helpers'
    
    
    export default {
        metaInfo: {
            title: 'Minhas simulações - Market Solar',
        },
        data()
        {
            return {
                dialog:false,
                isLoading: false,
                items: [],
                page: 1,
                totalPage: 1,
            }
        },
        
        mounted() {
            this.load()
        },

        methods: {
            ...mapActions(['getSimulator']),

            async load (page = 1) {
                this.isLoading = true
                const filter = {}
                filter.page = page
                const resp = await this.getSimulator(filter)
                if (resp) {
                    this.items = resp.data
                    this.totalPage = resp.last_page
                }
                
                this.isLoading = false;
            },
            
            _dayJs (data) {
                console.log( data )
                return dayjs(data)
            },
            
            _getResidenceType (id) {
                return getResidenceType(id)
            },
            
            _formatMoney (value) {
                return formatMoney(value)
            }
        }
    }
</script>


<style scoped>
    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
        border: none !important;
    }
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
        border: none !important;
    }
    main{
        background-color: #fafafa !important;
    }

    .cardMobile {
        display: none;
    }
    .hovercard:hover {
        background: #F5F9FE !important;
    }
    .cardDesktop {
        display: block;
    }

    @media screen and (max-width: 960px) {
        
        .cardMobile {
            display: block !important;
        }

        .cardDesktop {
            display: none !important;
        }
    }

    .hovercard td{
        width:200px;
        padding:20px !important;
    }
</style>