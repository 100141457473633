export default {
    'SET_USER'(state, user)
    {
        state.user = user
        localStorage.setItem("user", JSON.stringify(user) )
    },
    'SET_TOKEN'(state, jwt)
    {
        state.token = jwt
        localStorage.setItem("token", jwt)
    },

    'CLEAR_USER'(state, user)
    {
        state.user = user
    },

    'CLEAR_TOKEN'(state, jwt)
    {
        state.token = jwt
    },
    
    'SET_STEP_SIMULATION'(state, step)
    {
        state.step = step
    },

}